<template>
  <v-dialog v-model="dialog" :fullscreen="$vuetify.breakpoint.mobile" max-width="600px" persistent>
    <v-card v-if="!resetPassword">
      <v-card-title>
        <span class="text-h5">Login</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="emailAddress"
                hint="Email Address"
                label="Email Address"
                required
              />
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="password" hint="Password" label="Password" type="password" />
            </v-col>
            <v-col cols="12">
              <v-spacer />
              <v-checkbox v-model="rememberMe" label="Stay Logged In" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="toggleResetPassword"> Reset Password </v-btn>
        <v-btn color="primary" @click="login" :loading="loading"> Login </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-card-title>
        <span class="text-h5">Password Reset</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="emailAddress"
                hint="Email Address"
                label="Email Address"
                required
              />
              <v-card-text v-if="resetMessage">
                {{ resetMessage }}
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="toggleResetPassword"> Cancel </v-btn>
        <v-btn color="primary" @click="reset"> Reset Password </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import services from '@/@libs/services';
  import config from '../../../config';

  export default {
    name: 'Login',
    data() {
      return {
        loading: false,
        dialog: true,
        emailAddress: config.emailAddress || '',
        password: config.password || '',
        rememberMe: config.rememberMe || false,
        resetPassword: false,
        resetMessage: null,
        redirect: '/',
      };
    },
    methods: {
      async login() {
        try {
          this.loading = true;

          const response = await services.userInfoService.login(
            this.emailAddress,
            this.password,
            this.rememberMe,
          );

          if (this.rememberMe) localStorage.setItem('refreshToken', response.refreshToken);

          await this.$store.dispatch('login', { userInfo: response });
          await this.$router.push({ path: this.redirect });
        } finally {
          this.loading = false;
        }
      },
      async reset() {
        await services.userInfoService.resetPassword(this.emailAddress);

        this.resetMessage = 'An email has been sent to the above email address with instructions';
      },
      toggleResetPassword() {
        this.resetPassword = !this.resetPassword;
        this.resetMessage = null;
      },
    },
    async created() {
      const refreshToken = localStorage.getItem('refreshToken');
      const selectedGroup = localStorage.getItem('selectedGroup');

      this.redirect = this.$route.query.redirect;

      if (!refreshToken || !selectedGroup) {
        this.redirect = '/';
        return;
      }

      try {
        this.loading = true;
        const response = await services.userInfoService.loginWithRefreshToken(refreshToken, true);
        localStorage.setItem('refreshToken', response.refreshToken);

        await this.$store.dispatch('login', { userInfo: response });
        await this.$router.push({ path: this.redirect });
      } finally {
        this.loading = false;
      }
    },
  };
</script>

<style scoped></style>
