<template>
  <v-container fluid>
    <v-row>
      <v-col v-for="{ id, title, content } in bulletins" :key="id" :cols="12" :md="4">
        <v-card>
          <v-card-title>{{ title }}</v-card-title>
          <v-card-text v-html="content" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import _orderBy from 'lodash/orderBy';
  import services from '@/@libs/services';

  export default {
    name: 'Bulletins.vue',
    data() {
      return {
        bulletins: [],
      };
    },
    computed: {
      groupId() {
        return this.$route.params.groupId;
      },
    },
    methods: {
      async loadData() {
        const bulletins = await services.bulletinBoardService.getBulletinBoards(
          this.groupId,
          false,
        );

        this.bulletins = _orderBy(bulletins, ['created'], 'desc');
      },
    },
    async created() {
      await this.loadData();
    },
  };
</script>

<style scoped></style>
