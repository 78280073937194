<template>
  <div>
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ name }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ emailAddress }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list>
      <v-list-group
        v-for="menuGroup in filterMenuItems(groupedMenuItems)"
        :key="menuGroup.label"
        :aria-expanded="true"
        :prepend-icon="menuGroup.icon"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-content class="test">
            <v-list-item-title>{{ menuGroup.label }}</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="({ label, icon, path, permission }, i) in menuGroup.children"
          :key="i"
          :disabled="!hasPermission(permission)"
          :exact="true"
          :to="path"
        >
          <v-list-item-title>{{ label }}</v-list-item-title>

          <v-list-item-icon v-if="icon">
            <v-icon v-text="icon"></v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-group>
    </v-list>

    <v-list>
      <v-list-item-group :value="true">
        <v-list-item
          v-for="menuItem in filterMenuItems(individualMenuItems)"
          :key="menuItem.label"
          :disabled="!hasPermission(menuItem.permission, menuItem.requiresRootPermission)"
          :exact="true"
          :to="menuItem.path"
        >
          <v-list-item-icon v-if="menuItem.icon">
            <v-icon v-text="menuItem.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ menuItem.label }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
  export default {
    name: 'Navigation',
    computed: {
      menuItems() {
        const {
          selectedGroup: { id: groupId },
        } = this.$store.state;

        if (!groupId) return [];

        return [
          {
            label: 'Groups',
            icon: 'mdi-store',
            children: [
              {
                label: 'Group Selection',
                path: '/groups/select?disableRedirect=true',
                icon: 'mdi-store-check',
              },
              {
                label: 'Create Group',
                path: '/groups/create-group',
                permission: 'CreateGroup',
                requiresRootPermission: true,
                icon: 'mdi-store-plus',
              },
            ],
          },
          {
            label: 'Bulletins',
            icon: 'mdi-bulletin-board',
            children: [
              {
                label: 'View',
                path: `/${groupId}/bulletins`,
                icon: 'mdi-comment-eye',
                permission: 'ViewBulletinBoard',
              },
              {
                label: 'Manage',
                path: `/${groupId}/bulletins/manage-bulletins`,
                icon: 'mdi-comment-plus',
                permission: 'CreateBulletinBoardEntry',
              },
            ],
          },
          {
            label: 'Work Orders',
            icon: 'mdi-file-multiple',
            children: [
              {
                label: 'Create',
                icon: 'mdi-file-plus',
                path: `/${groupId}/work-orders/create-work-order`,
                permission: 'CreateWorkOrder',
              },
              {
                label: 'View Active',
                icon: 'mdi-table-eye',
                path: `/${groupId}/work-orders`,
                permission: 'ViewWorkOrders',
              },
              {
                label: 'View Archived',
                icon: 'mdi-archive',
                path: `/${groupId}/work-orders/archived`,
                permission: 'ViewArchivedWorkOrders',
              },
              {
                label: 'Map',
                icon: 'mdi-google-maps',
                path: `/${groupId}/work-orders/map`,
                permission: 'MapWorkOrders',
              },
              {
                label: 'Search',
                icon: 'mdi-file-search',
                path: `/${groupId}/work-orders/search`,
                permission: 'SearchWorkOrders',
              },
            ],
          },
          {
            label: 'Settings',
            icon: 'mdi-cog',
            children: [
              {
                label: 'Areas',
                path: `/${groupId}/settings/areas`,
                icon: 'mdi-map',
                permission: 'ManageAreas',
              },
              {
                label: 'Avochato',
                path: `/${groupId}/settings/avochato`,
                icon: 'mdi-egg',
                permission: 'EditGroupSettings',
              },
              {
                label: 'Automation',
                path: `/${groupId}/work-orders/automation`,
                icon: 'mdi-robot',
                permission: 'ManageWorkOrderAutomations',
              },
              {
                label: 'Companies',
                path: `/${groupId}/settings/companies`,
                icon: 'mdi-domain',
                permission: 'ManageCompanies',
              },
              {
                label: 'Google',
                path: `/${groupId}/settings/google`,
                icon: 'mdi-google',
                permission: 'EditGroupSettings',
              },
              {
                label: 'Group',
                path: `/${groupId}/settings/group-details`,
                icon: 'mdi-group',
                permission: 'EditGroupDetails',
              },
              {
                label: 'Omega',
                path: `/${groupId}/settings/omega`,
                icon: 'mdi-omega',
                permission: 'EditGroupSettings',
              },
              {
                label: 'Open Weather',
                path: `/${groupId}/settings/open-weather`,
                icon: 'mdi-cloud-braces',
                permission: 'EditGroupSettings',
              },
              // {
              //   label: 'Photos',
              //   path: `/${groupId}/settings/photos`,
              //   icon: 'mdi-camera',
              //   permission: 'EditGroupSettings',
              // },
              // {
              //   label: 'Slack',
              //   path: `/${groupId}/settings/slack`,
              //   icon: 'mdi-slack',
              //   permission: 'EditGroupSettings',
              // },
              {
                label: 'Smtp',
                path: `/${groupId}/settings/smtp`,
                icon: 'mdi-email',
                permission: 'EditGroupSettings',
              },
              {
                label: 'Tags',
                path: `/${groupId}/settings/tags`,
                icon: 'mdi-tag',
                permission: 'ManageTags',
              },
              {
                label: 'Templates',
                path: `/${groupId}/settings/templates`,
                icon: 'mdi-file-document-edit',
                permission: 'EditGroupSettings',
              },
              {
                label: 'Users',
                path: `/${groupId}/settings/users`,
                icon: 'mdi-account-multiple',
                permission: 'ManageUsers',
              },
            ],
          },
          {
            label: 'Logs',
            icon: 'mdi-post',
            children: [
              {
                label: 'Performance',
                path: `/${groupId}/logs/performance`,
                icon: 'mdi-poll',
                permission: 'ViewPerformanceLogs',
                requiresRootPermission: true,
              },
              {
                label: 'Exception',
                path: `/${groupId}/logs/exceptions`,
                icon: 'mdi-bug',
                permission: 'ViewExceptionLogs',
                requiresRootPermission: true,
              },
              {
                label: 'Hangfire',
                path: `/${groupId}/logs/hangfire`,
                icon: 'mdi-semantic-web',
                permission: 'ViewHangfireDashboard',
                requiresRootPermission: true,
              },
            ],
          },
          {
            label: 'Development',
            icon: 'mdi-developer-board',
            permission: 'DevelopmentMenu',
            children: [
              {
                label: 'New Request',
                path: '/new-request',
                icon: 'mdi-notebook-edit',
              },
              {
                label: 'In Development',
                path: '/work-in-progress',
                icon: 'mdi-file-code',
              },
              {
                label: 'Finished Work',
                path: '/finished-work',
                icon: 'mdi-cloud-check',
              },
              {
                label: 'Report a Bug',
                path: '/bug-report',
                icon: 'mdi-bug',
              },
              {
                label: 'Known Bugs',
                path: '/bugs',
                icon: 'mdi-shield-bug',
              },
            ],
          },
          {
            label: 'Profile',
            path: '/profile',
            icon: 'mdi-account-edit',
          },
          {
            label: 'Logout',
            path: '/logout',
            icon: 'mdi-exit-to-app',
          },
        ];
      },
      groupedMenuItems() {
        return this.menuItems.filter((a) => a.label && a.children && a.children.length > 0);
      },
      individualMenuItems() {
        return this.menuItems.filter((a) => a.label && (!a.children || a.children.length === 0));
      },
      name() {
        return this.$store.state.userInfo.name;
      },
      emailAddress() {
        return this.$store.state.userInfo.emailAddress;
      },
    },
    methods: {
      hasPermission(permission, requiresRootPermission) {
        const { permissions, groupPermissions } = this.$store.getters;

        const { groupId } = this.$route.params;

        if (permission === undefined) return true;

        return (
          permissions.some((a) => a === 'All')
          || groupPermissions(groupId).some((a) => a === 'All' && !requiresRootPermission)
          || permissions.some((a) => a === permission)
          || groupPermissions(groupId).some((a) => a === permission && !requiresRootPermission)
        );
      },
      filterMenuItems(menuItems) {
        const result = [];

        menuItems.forEach((a) => {
          if (this.hasPermission(a.permission, a.requiresRootPermission)) {
            let children = [];
            let hadChildren = false;

            if (Array.isArray(a.children) && a.children.length > 0) {
              hadChildren = true;
              children = this.filterMenuItems(a.children);
            }

            if (children.length > 0 || !hadChildren) {
              result.push({
                ...a,
                children,
              });
            }
          }
        });

        return result;
      },
    },
  };
</script>

<style lang="scss">
.v-list-group__header {
  height: 0;
}
</style>
