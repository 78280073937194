<template>
  <div>
    <v-text-field
      :label="label"
      :value="formattedValue"
      append-icon="mdi-calendar"
      readonly
      :disabled="disabled"
      @click="showDialog"
      :clearable="true"
      @click:clear="() => emitDate(null)"
    ></v-text-field>
    <v-dialog
      v-model="dialog"
      persistent
      :fullscreen="this.$vuetify.breakpoint.mobile"
      :max-width="1024"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Select an available appointment date</span>
        </v-card-title>
        <v-card-subtitle>Note available appointments for any given day</v-card-subtitle>
        <v-card-actions>
          <v-btn @click="offSetDate(-5)" text>&lt;</v-btn>
          <v-spacer />
          <v-btn @click="offSetDate(5)" text>&gt;</v-btn>
        </v-card-actions>
        <v-card-text style="margin-top: 40px">
          <v-row justify="space-between">
            <v-col v-for="(availableDate, index) in availableDates" :key="index" :cols="2">
              <center>
                <div>{{ availableDate.dayName }} - {{ availableDate.date }}</div>
                <span style="font-weight: bold">
                  {{ availableDate.capacity - availableDate.appointments }} of
                  {{ availableDate.capacity }} available
                </span>
                <v-btn
                  text
                  color="primary"
                  @click="emitDate(availableDate.date)"
                  style="margin-top: 40px"
                  :disabled="availableDate.capacity - availableDate.appointments <= 0"
                >
                  Choose
                </v-btn>
              </center>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import services from '@/@libs/services';
  import moment from 'moment';

  export default {
    name: 'WorkOrderSchedulePicker',
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        default: 'Select a date',
      },
      value: {
        default: moment().add('day', 2),
      },
    },
    data() {
      return {
        dialog: false,
        availableDates: [],
        startDate: moment(),
        endDate: moment().add('day', 4),
      };
    },
    computed: {
      groupId() {
        return this.$route.params.groupId;
      },
      workOrderId() {
        return this.$route.params.workOrderId;
      },
      formattedValue() {
        if (!this.value) return '';

        return moment(this.value).format('MM/DD/YYYY');
      },
    },
    watch: {
      startDate: {
        async handler(newValue) {
          this.endDate = moment(newValue).add('day', 4);
          await this.loadData();
        },
      },
    },
    methods: {
      emitDate(date) {
        const result = date ? moment(date).toDate() : date;

        this.$emit('selectValue', result);
        this.$emit('blur');
        this.dialog = false;
      },
      offSetDate(offsetValue) {
        this.startDate = moment(this.startDate).add('day', offsetValue);
      },
      async showDialog() {
        await this.loadData();
        this.dialog = true;
      },
      async loadData() {
        try {
          const availableDates = await services.workOrderService.getAvailableAppointments(
            this.groupId,
            this.workOrderId,
            this.startDate.format('yyyy-MM-DD'),
            this.endDate.format('yyyy-MM-DD'),
          );

          this.availableDates = availableDates;
        } catch {
          // throw away
        }
      },
    },
  };
</script>

<style scoped></style>
