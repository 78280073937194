<template>
  <v-row>
    <v-col
      v-for="field in fields"
      :key="field.value"
      :cols="12"
      :lg="field.colsLg || field.cols || 12"
      :md="field.colsMd || field.cols || 12"
    >
      <v-text-field
        v-if="field.type === 'text'"
        :label="field.label"
        :readonly="readonly || field.readonly"
        :disabled="field.disabled"
        :value="getValue(field.value)"
        @blur="$emit('blur')"
        @focus="focus[field.value] = true"
        @focusout="focus[field.value] = false"
        @input="(newValue) => setValue(field.value, newValue)"
        :append-icon="field.icon"
        :prepend-icon="field.prependIcon"
        :type="field.textType || 'text'"
      />
      <v-textarea
        v-if="field.type === 'textarea'"
        :label="field.label"
        :readonly="readonly || field.readonly"
        :disabled="field.disabled"
        :value="getValue(field.value)"
        @blur="$emit('blur')"
        @focus="focus[field.value] = true"
        @focusout="focus[field.value] = false"
        @input="(newValue) => setValue(field.value, newValue)"
        :append-icon="field.icon"
        :prepend-icon="field.prependIcon"
      />
      <v-autocomplete
        v-if="field.type === 'auto-complete'"
        :label="field.label"
        :readonly="readonly || field.readonly"
        :disabled="field.disabled"
        :value="getValue(field.value)"
        @blur="$emit('blur')"
        @focus="focus[field.value] = true"
        @focusout="focus[field.value] = false"
        @input="(newValue) => setValue(field.value, newValue)"
        :append-icon="field.icon"
        :prepend-icon="field.prependIcon"
        :multiple="field.multiple"
        :items="field.items"
        :chips="field.chips"
        hide-selected
        deletable-chips
      />
      <v-text-field
        v-if="field.type === 'number'"
        :label="field.label"
        :readonly="readonly || field.readonly"
        :disabled="field.disabled"
        :value="getValue(field.value)"
        @blur="$emit('blur')"
        @focus="focus[field.value] = true"
        @focusout="focus[field.value] = false"
        @input="(newValue) => setValue(field.value, newValue)"
        type="number"
        :append-icon="field.icon"
        :prepend-icon="field.prependIcon"
      />
      <MaskedTextField
        v-if="field.type === 'masked-text'"
        :label="field.label"
        :mask="field.mask"
        :readonly="readonly || field.readonly"
        :disabled="field.disabled"
        :value="getValue(field.value)"
        @input="(newValue) => setValue(field.value, newValue)"
        @blur="$emit('blur')"
        :append-icon="field.icon"
        :prepend-icon="field.prependIcon"
      />
      <AutoCompleteAddressField
        v-if="field.type === 'auto-complete-address'"
        :label="field.label"
        :readonly="readonly || field.readonly"
        :disabled="field.disabled"
        :value="getValue(field.value)"
        @input="(newValue) => setValue(field.value, newValue)"
        @blur="$emit('blur')"
        :append-icon="field.icon"
        :prepend-icon="field.prependIcon"
        :url="field.url"
        :place-selected="field.placeSelected"
        :key="field.key"
      />
      <v-menu
        v-if="field.type === 'date'"
        ref="menu"
        v-model="menus[field.value]"
        :close-on-content-click="false"
        :disabled="readonly || field.readonly || field.disabled"
        min-width="auto"
        offset-y
        transition="scale-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :label="field.label"
            :value="formatDate(getValue(field.value))"
            append-icon="mdi-calendar"
            readonly
            :disabled="field.disabled"
            v-bind="attrs"
            v-on="on"
            :clearable="field.clearable"
            @click:clear="
              () => {
                setValue(field.value, null);
                $emit('blur');
              }
            "
          ></v-text-field>
        </template>
        <v-date-picker
          :value="getDateValue(field.value)"
          scrollable
          @input="
            (newValue) => {
              setValue(field.value, newValue);
              $emit('blur');
            }
          "
          :disabled="field.disabled"
        >
          <v-spacer></v-spacer>
          <v-btn text @click="menus[field.value] = false"> Cancel </v-btn>
        </v-date-picker>
      </v-menu>
      <v-menu
        v-if="field.type === 'time'"
        ref="menu"
        v-model="menus[field.value]"
        :close-on-content-click="false"
        :disabled="readonly || field.readonly || field.disabled"
        min-width="auto"
        offset-y
        transition="scale-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :label="field.label"
            :value="getTimeValue(field.value, true)"
            append-icon="mdi-clock-outline"
            readonly
            :disabled="field.disabled"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
          :value="getTimeValue(field.value)"
          scrollable
          @change="
            (newValue) => {
              setValue(field.value, newValue);
              $emit('blur');
            }
          "
          :disabled="field.disabled"
        ></v-time-picker>
      </v-menu>
      <v-select
        v-if="field.type === 'select'"
        :disabled="readonly || field.readonly || field.disabled"
        :items="field.items"
        :label="field.label"
        :value="getValue(field.value)"
        @input="
          (newValue) => {
            setValue(field.value, newValue ? newValue.value || newValue : null);
            $emit('blur');
          }
        "
        @click:clear.prevent="
          () => {
            setValue(field.value, null);
          }
        "
        :multiple="field.multiple"
        :clearable="field.clearable"
      />
      <v-combobox
        v-if="field.type === 'combo-box'"
        :disabled="readonly || field.readonly || field.disabled"
        :items="field.items"
        :label="field.label"
        :value="getValue(field.value)"
        :item-text="field.itemText"
        :item-value="field.itemValue"
        @input="
          (newValue) => {
            setValue(field.value, newValue ? newValue.value || newValue : null);
            $emit('blur');
          }
        "
        @click:clear.prevent="
          () => {
            setValue(field.value, null);
            $emit('blur');
          }
        "
        :multiple="field.multiple"
        :clearable="field.clearable"
      />
      <v-checkbox
        v-if="field.type === 'check'"
        :append-icon="field.icon"
        :disabled="readonly || field.readonly || field.disabled"
        :label="field.label"
        :prepend-icon="field.prependIcon"
        :input-value="getValue(field.value)"
        style="white-space: nowrap"
        @change="(newValue) => setValue(field.value, newValue, true)"
      />
      <div v-if="field.type === 'color'">
        <v-subheader>{{ field.label }}</v-subheader>
        <v-color-picker
          :append-icon="field.icon"
          :disabled="readonly || field.readonly || field.disabled"
          :prepend-icon="field.prependIcon"
          :value="getValue(field.value)"
          show-swatches
          @blur="$emit('blur')"
          @input="(newValue) => setValue(field.value, newValue.hex || newValue)"
        />
      </div>
      <WorkOrderSchedulePicker
        v-if="field.type === 'schedule'"
        :disabled="readonly || field.readonly || field.disabled"
        :label="field.label"
        :value="getValue(field.value)"
        @selectValue="(newValue) => setValue(field.value, newValue)"
        @blur="$emit('blur')"
      />
    </v-col>
  </v-row>
</template>

<script>
  import moment from 'moment-timezone';
  import _get from 'lodash/get';
  import _set from 'lodash/set';
  import MaskedTextField from '@/App/@Components/MaskedTextField';
  import AutoCompleteAddressField from '@/App/@Components/AutoCompleteAddressField/';
  import WorkOrderSchedulePicker from '@/App/@Components/WorkOrderSchedulePicker/';

  export default {
    name: 'ReusableForm',
    components: { WorkOrderSchedulePicker, AutoCompleteAddressField, MaskedTextField },
    props: {
      fields: {
        type: [Array, Object],
        required: true,
      },
      item: {
        type: Object,
        required: true,
      },
      readonly: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        dialog: true,
        menus: {},
        focus: {},
      };
    },
    computed: {
      getValue() {
        return (propName) => _get(this.item, propName);
      },
    },
    methods: {
      setValue(propName, newValue, shouldEmitBlur = false) {
        _set(this.item, propName, newValue);
        if (this.menus[propName]) this.menus[propName] = false;

        if (shouldEmitBlur) this.$emit('blur');
      },
      getDateValue(propName) {
        const value = this.getValue(propName);

        if (!value) {
          return moment().format('yyyy-MM-DD');
        }

        const date = moment(value);

        return date.format('yyyy-MM-DD');
      },
      getTimeValue(propName, includeAmPm = false) {
        const value = this.getValue(propName);

        const format = includeAmPm ? 'hh:mm A' : 'HH:mm';

        if (!value) {
          return moment().format(format);
        }

        // eslint-disable-next-line no-restricted-globals
        if (isNaN(new Date(value))) return value;

        return moment(value).format(format);
      },
      formatDate(value) {
        if (!value) return null;

        return moment(value).format('MM/DD/yyyy');
      },
    },
  };
</script>

<style lang="scss" scoped></style>
