<template>
  <v-app>
    <component :is="layout">
      <router-view />
      <v-snackbar
        v-model="showSnackbar"
        :color="color"
        :timeout="10000"
        multi-line
        right
        shaped
        top
      >
        <div class="text-center">
          {{ message }}
        </div>
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="showSnackbar = false"> X </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar
        v-model="showUpdateDialog"
        color="green"
        multi-line
        bottom
        shaped
        left
        :timeout="-1"
      >
        <div class="text-center">An update is available, please refresh</div>
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="update">
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </component>
  </v-app>
</template>

<script>
  import eventBus from '@/@libs/eventBus';
  import services from '@/@libs/services';

  export default {
    name: 'App',
    data() {
      return {
        showUpdateDialog: false,
        showSnackbar: false,
        message: null,
        color: 'red',
      };
    },
    computed: {
      layout() {
        return this.$route.meta.layout || 'default-layout';
      },
    },
    methods: {
      update() {
        window.location.reload();
      },
      showUpdate() {
        this.showUpdateDialog = true;
      },
      async loadData() {
        await services.enumService.getEnums();
      },
      showException(payload) {
        const {
          error: { msg },
        } = payload;
        this.message = msg;
        this.color = 'red';
        this.showSnackbar = true;
      },
    },
    async created() {
      await this.loadData();

      eventBus.on({
        name: eventBus.eventTypes.http.authException,
        callback: (payload) => this.showException(payload),
      });

      eventBus.on({
        name: eventBus.eventTypes.app.error,
        callback: (payload) => this.showException(payload),
      });

      eventBus.on({
        name: eventBus.eventTypes.app.updateAvailable,
        callback: () => this.showUpdate(),
      });
    },
  };
</script>
