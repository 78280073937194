import Vue from 'vue';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
import moment from 'moment';
import VueSignature from 'vue-signature-pad';

import App from './App';
import router from './@libs/router';
import store from './@libs/store';

import vuetify from './plugins/vuetify';

import './@@layouts';
import 'tiptap-vuetify/dist/main.css';

import './registerServiceWorker';

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi',
});

Vue.use(VueSignature);

Vue.config.productionTip = false;

moment.updateLocale('en', {
  calendar: {
    lastDay: '[Yesterday at] ha',
    sameDay: '[Today at] ha',
    lastWeek: '[Last] dddd L',
    sameElse: 'L',
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
