import baseService from '@/@libs/services/baseService';
import httpClient from '@/@libs/httpClient';

export default {
  ...baseService,
  async getAutomations(groupId, showDeleted) {
    const response = await httpClient.get(this.apiEndpoints.workOrderAutomations.getAutomations, {
      replace: {
        groupId,
        showDeleted,
      },
    });

    return response;
  },
  async addAutomation(groupId, workOrderAutomation) {
    const response = await httpClient.post(this.apiEndpoints.workOrderAutomations.addAutomation, {
      replace: {
        groupId,
      },
      body: workOrderAutomation,
    });

    return response;
  },
  async deleteAutomation(groupId, automationId) {
    const response = await httpClient.delete(
      this.apiEndpoints.workOrderAutomations.deleteAutomation,
      {
        replace: {
          groupId,
          automationId,
        },
      },
    );

    return response;
  },
  async restoreAutomation(groupId, automationId) {
    const response = await httpClient.post(
      this.apiEndpoints.workOrderAutomations.restoreAutomation,
      {
        replace: {
          groupId,
          automationId,
        },
      },
    );

    return response;
  },
  async updateAutomation(groupId, automation) {
    const { id: automationId } = automation;
    const response = await httpClient.put(this.apiEndpoints.workOrderAutomations.updateAutomation, {
      replace: {
        groupId,
        automationId,
      },
      body: automation,
    });

    return response;
  },
};
