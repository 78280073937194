<template>
  <v-autocomplete
    v-model="selectedValue"
    :label="label"
    :readonly="readonly"
    :items="items"
    :search-input.sync="search"
    :append-icon="appendIcon"
    :prepend-icon="prependIcon"
    :loading="loading"
    :no-filter="true"
    return-object
    hide-details
    item-value="placeId"
    item-text="description"
    @blur="$emit('blur')"
    @change="setValue"
    :disabled="disabled"
  >
    <template v-slot:item="{ item }" @click.prevent="setValue(item)">
      <v-list-item-icon>
        <v-icon v-if="item.placeId">mdi-map-marker</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="item.description || item"></v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon>map-marker</v-icon>
      </v-list-item-action>
    </template>
  </v-autocomplete>
</template>

<script>
  import httpClient from '@/@libs/httpClient';
  import _debounce from 'lodash/debounce';

  export default {
    name: 'AutoCompleteAddressField',
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        required: true,
      },
      readonly: {
        type: Boolean,
      },
      value: {
        type: String,
      },
      prependIcon: {
        type: String,
      },
      appendIcon: {
        type: String,
      },
      url: {
        type: String,
        required: true,
      },
      placeSelected: {
        type: Function,
      },
    },
    data() {
      return {
        selectedValue: this.value,
        items: [this.value],
        search: '',
        loading: false,
      };
    },
    watch: {
      search: _debounce(
        // eslint-disable-next-line func-names
        async function (newValue) {
          if (this.loading || !newValue || newValue.length < 5 || newValue === this.selectedValue) {
            return;
          }

          if (this.items.find((a) => a && a.description && a.description === newValue)) {
            return;
          }

          this.loading = true;
          const response = await httpClient.get(this.url, { replace: { input: newValue } });
          this.loading = false;

          this.items = [...response];
        }, 500,
      ),
      value(newValue) {
        this.items = [newValue];
        this.selectedValue = newValue;
      },
    },
    methods: {
      async setValue(newValue) {
        if (this.placeSelected && newValue && newValue.placeId) {
          this.placeSelected(newValue.placeId);
          return;
        }

        const value = newValue && newValue.description ? newValue.description : newValue;
        this.$emit('input', value);
        this.items = [value];
      },
    },
  };
</script>

<style scoped></style>
