var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""},on:{"dragenter":function($event){$event.preventDefault();return _vm.handleDragEnter.apply(null, arguments)},"dragleave":function($event){$event.preventDefault();return _vm.handleDragLeave.apply(null, arguments)},"dragover":function($event){$event.preventDefault();return _vm.handleDragOver.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":12}},[_c('v-expansion-panels',{model:{value:(_vm.panels),callback:function ($$v) {_vm.panels=$$v},expression:"panels"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('h2',[_vm._v("Add Attachment(s)")])]),_c('v-expansion-panel-content',[(_vm.dragging > 0)?_c('div',{staticClass:"drop-target",on:{"drop":function($event){$event.preventDefault();return _vm.handleDrop.apply(null, arguments)}}},[_c('div',[_vm._v("Drop files here to upload")])]):_c('div',[_c('v-select',{attrs:{"items":_vm.validPhotoTypes},model:{value:(_vm.attachmentType),callback:function ($$v) {_vm.attachmentType=$$v},expression:"attachmentType"}}),_c('v-file-input',{attrs:{"accept":_vm.validFileTypes},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1)]),(_vm.panels === 0)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.clearData}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading},on:{"click":_vm.addAttachment}},[_vm._v(" Save ")])],1):_vm._e()],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":12}},[_c('DataGrid',{attrs:{"headers":_vm.headers,"items":_vm.attachments,"id":"work-order-attachments"},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var created = ref.item.created;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(new Date(created).toLocaleString())+" ")])]}},{key:"item.attachmentType",fn:function(ref){
var ref_item = ref.item;
var id = ref_item.id;
var attachmentType = ref_item.attachmentType;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_c('a',{attrs:{"href":_vm.getAttachmentUrl(id),"target":"_blank"}},[_vm._v(_vm._s(_vm.getAttachmentFriendlyName(attachmentType)))])])]}},{key:"item.processed",fn:function(ref){
var processed = ref.item.processed;
return [_c('v-simple-checkbox',{attrs:{"value":processed}})]}},{key:"item.actions",fn:function(){return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }