<template>
  <div>
    <v-card>
      <v-card-title>Criteria</v-card-title>
      <v-card-subtitle>
        All of following rules must be met to perform the above action
      </v-card-subtitle>
      <v-card-text v-for="(rule, index) in rules" :key="index">
        <v-row>
          <v-col :cols="12" :lg="4">
            <v-autocomplete
              label="Work Order Field"
              v-model="rule.a"
              :items="automationFields"
              @input="() => updateExpression(index)"
            />
          </v-col>
          <v-col :cols="12" :lg="4">
            <v-select
              label="Operation"
              v-model="rule.operation"
              :items="availableOperations(index)"
              @input="() => updateExpression(index)"
            />
          </v-col>
          <v-col :cols="12" :lg="3">
            <v-text-field label="Value" v-model="rule.b" @input="() => updateExpression(index)" />
          </v-col>
          <v-col>
            <v-btn v-if="index !== 0" text @click="removeRule(index)"> Remove </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-icon @click="addRule">mdi-plus</v-icon>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  import { v4 as guid } from 'uuid';
  import _map from 'lodash/map';

  import services from '@/@libs/services';

  export default {
    name: 'RulesEngine',
    props: {
      rules: {
        type: [Array, Object],
        required: true,
      },
    },
    computed: {
      automationFields() {
        const map = _map(
          services.enumService.mappedEnums.workOrderAutomationFields,
          (_, key) => key,
        );

        return map;
      },
    },
    methods: {
      availableOperations(index) {
        // eslint-disable-next-line max-len
        const fieldOperations =
          services.enumService.mappedEnums.workOrderAutomationFields[this.rules[index].a];

        return _map(fieldOperations, (fieldOperation) => fieldOperation);
      },
      updateExpression(index) {
        if (!this.rules[index].operation) return;

        this.rules[index].expression = this.rules[index].operation
          .replace('{a}', this.rules[index].a)
          .replace('{b}', this.rules[index].b);
      },
      addRule() {
        this.rules.push({ ruleName: guid() });
      },
      removeRule(index) {
        this.rules.splice(index, 1);
      },
    },
  };
</script>

<style scoped></style>
