<template>
  <v-container fluid>
    <WorkOrderTable
      id="archived-work-orders"
      :loading="loading"
      :work-orders="archivedWorkOrders"
      title="Archived Work Orders"
      :refresh="loadData"
      allow-delete
      allow-un-archive
    />
  </v-container>
</template>

<script>
  import services from '@/@libs/services';
  import WorkOrderTable from '@/App/WorkOrders/@Components/WorkOrderTable/WorkOrderTable.vue';

  export default {
    name: 'WorkOrders',
    components: { WorkOrderTable },
    data() {
      return {
        archivedWorkOrders: [],
        loading: false,
      };
    },
    computed: {
      groupId() {
        return this.$store.state.selectedGroup.id;
      },
    },
    methods: {
      async loadData() {
        this.loading = true;
        const result = await services.workOrderService.listArchivedWorkOrders(this.groupId);
        this.loading = false;

        this.archivedWorkOrders = result;
      },
    },
    created() {
      this.loadData();
    },
  };
</script>
