/* eslint-disable no-console */

import { register } from 'register-service-worker';
import eventBus from '@/@libs/eventBus';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    registrationOptions: { scope: '/' },
    ready() {
      // console.log('App is being served from cache by a service worker.');
    },
    registered() {
      // console.log('Service worker has been registered');
    },
    cached() {
      // console.log('Content has been cached for offline use.');
    },
    updatefound() {
      // console.log('New content is downloading.');
    },
    updated() {
      eventBus.raiseEvent({ name: eventBus.eventTypes.app.updateAvailable, payload: null });
    },
    offline() {
      // console.log('No internet connection found. App is running in offline mode.');
    },
    error() {
      // console.error('Error during service worker registration:', error);
    },
  });
}
