<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <DataGrid
          id="bulletins"
          :custom-grid-settings-fields="customGridSettingsFields"
          :gridSettings.sync="gridSettings"
          :headers="headers"
          :items="tags"
          :show-add="true"
          :title="gridTitle"
          @add="addTag"
          :loading="loading"
        >
          <template v-slot:item.created="{ item: { created } }">
            <span style="white-space: nowrap">
              {{ new Date(created).toLocaleString() }}
            </span>
          </template>
          <template v-slot:item.modified="{ item: { modified } }">
            <span style="white-space: nowrap">
              {{ new Date(modified).toLocaleString() }}
            </span>
          </template>

          <template v-slot:item.deleted="{ item: { deleted } }">
            <span style="white-space: nowrap">
              <v-icon v-if="!deleted">mdi-check</v-icon>
            </span>
          </template>

          <template v-slot:item.color="{ item: { color, textColor, value } }">
            <v-chip :color="color" :text-color="textColor">{{ value }}</v-chip>
          </template>

          <template v-slot:item.actions="{ item }">
            <span style="white-space: nowrap">
              <v-icon @click="showEdit(item)"> mdi-pencil </v-icon>
              <v-icon v-if="!item.deleted" @click="showConfirm(item)"> mdi-delete </v-icon>
              <v-icon v-else @click="showConfirm(item)"> mdi-restore </v-icon>
            </span>
          </template>
        </DataGrid>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card :elevation="0">
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" @click="syncWithOmegaTags" :loading="loading">
              Sync Omega Tags
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <Confirmation
      :confirm="confirmSelectedTag"
      :show="showConfirmDialog"
      :max-width="600"
      :cancel="() => (this.showConfirmDialog = false)"
    >
      Are you sure you want to {{ operation }} Tag {{ selectedTag.value }}?
    </Confirmation>

    <v-dialog
      :value="showDialog"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
      :max-width="600"
    >
      <v-card>
        <v-card-title>{{ dialogTitle }}</v-card-title>
        <v-card-text>
          <ReusableForm :fields="tagFields" :item="editTag" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="showDialog = false">Cancel</v-btn>
          <v-btn text color="primary" @click="saveTag"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import DataGrid from '@/App/@Components/DataGrid';
  import services from '@/@libs/services';
  import Confirmation from '@/App/@Components/Confirmation';
  import ReusableForm from '@/App/@Components/ReusableForm';

  export default {
    name: 'ManageTags',
    components: { ReusableForm, Confirmation, DataGrid },
    data() {
      return {
        gridSettings: null,
        showDeletedTags: false,
        selectedTag: {},
        showConfirmDialog: false,
        showDialog: false,
        editTag: {},
        loading: false,
        operation: null,
        tagFields: [
          {
            id: 'value',
            type: 'text',
            label: 'Value',
            value: 'value',
            cols: 12,
          },
          {
            id: 'color',
            type: 'color',
            label: 'Background Color',
            value: 'color',
            cols: 6,
          },
          {
            id: 'textColor',
            type: 'color',
            label: 'Text Color',
            value: 'textColor',
            cols: 6,
          },
          {
            id: 'preventStatuses',
            type: 'select',
            label: 'Prevents Work Order Statuses',
            value: 'preventStatuses',
            items: services.enumService.arrayEnums.workOrderStatus,
            multiple: true,
            cols: 12,
          },
        ],
        headers: [
          {
            id: 'value',
            text: 'Value',
            value: 'value',
            default: true,
            searchable: true,
            groupable: false,
          },
          {
            id: 'example',
            text: 'Example',
            value: 'color',
            default: true,
            searchable: false,
            groupable: false,
            sortable: false,
            align: 'center',
          },
          {
            id: 'created',
            text: 'Created',
            value: 'created',
            default: false,
            groupable: false,
            searchable: false,
          },
          {
            id: 'modified',
            text: 'Modified',
            value: 'modified',
            default: false,
            groupable: false,
            searchable: false,
          },
          {
            id: 'externalId',
            text: 'External Id',
            value: 'externalId',
            default: false,
            groupable: false,
            searchable: false,
          },
          {
            id: 'deleted',
            text: 'Active',
            value: 'deleted',
            default: false,
            groupable: true,
            searchable: false,
          },
          {
            id: 'actions',
            text: 'Actions',
            value: 'actions',
            sortable: false,
            groupable: false,
            default: true,
            align: 'center',
          },
        ],
        tags: [],
        customGridSettingsFields: [
          {
            id: 'showDeletedTags',
            type: 'check',
            label: 'Show Deleted Tags',
            value: 'showDeletedTags',
            cols: 4,
          },
        ],
      };
    },
    computed: {
      groupId() {
        return this.$route.params.groupId;
      },
      gridTitle() {
        return this.showDeletedTags ? 'All Tags' : 'Active Tags';
      },
      dialogTitle() {
        return this.editTag.id ? 'Edit Tag' : 'Add Tag';
      },
    },
    watch: {
      gridSettings: {
        handler(newValue) {
          this.showDeletedTags = newValue.showDeletedTags || false;
          this.loadData();
        },
        deep: true,
      },
    },
    methods: {
      async loadData() {
        if (!this.gridSettings) return;

        this.loading = true;
        const tags = await services.tagService.getGroupTags(this.groupId, this.showDeletedTags);
        this.loading = false;

        this.tags = tags;
      },
      async confirmSelectedTag() {
        const { id, deleted } = this.selectedTag;

        if (!deleted) {
          await services.tagService.deleteTag(this.groupId, id);
        } else {
          await services.tagService.restoreTag(this.groupId, id);
        }

        await this.loadData();
        this.showConfirmDialog = false;
      },
      showConfirm(selectedTag) {
        this.selectedTag = selectedTag;
        this.showConfirmDialog = true;
        this.operation = selectedTag.deleted ? 'RESTORE' : 'DELETE';
      },
      showEdit(selectedTag) {
        this.editTag = JSON.parse(JSON.stringify(selectedTag));
        this.showDialog = true;
      },
      async saveTag() {
        this.loading = true;

        if (this.editTag.id) {
          await services.tagService.updateTag(this.groupId, this.editTag);
        } else {
          await services.tagService.addTag(this.groupId, this.editTag);
        }

        this.loading = false;

        await this.loadData();
        this.showDialog = false;
      },
      addTag() {
        this.editTag = {};
        this.showDialog = true;
      },
      async syncWithOmegaTags() {
        try {
          this.loading = true;
          await services.tagService.syncWithOmegaTags(this.groupId);
        } finally {
          this.loading = false;
        }

        await this.loadData();
      },
    },
    async created() {
      await this.loadData();
    },
  };
</script>

<style scoped></style>
