<template>
  <v-card class="mx-auto" max-width="400">
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-h5"> Current </v-list-item-title>
        <v-list-item-subtitle>
          {{ weather.weather[0].main }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-card-text>
      <v-row align="center">
        <v-col class="text-h2" cols="7"> {{ Math.round(weather.main.temp) }}&deg;F</v-col>
        <v-col cols="4">
          <v-img
            :src="weather.weather[0].iconUrl"
            :alt="weather.weather[0].icon"
            contain
            :max-width="200"
            :max-height="200"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-weather-windy</v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle
        >{{ Math.round(weather.wind.speed) }} mph @ {{ Math.round(weather.wind.deg) }} &deg;
      </v-list-item-subtitle>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-weather-rainy</v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle>{{ pop * 100 }}% </v-list-item-subtitle>
    </v-list-item>
  </v-card>
</template>

<script>
  export default {
    name: 'CurrentWeatherCard',
    props: {
      weather: {
        type: Object,
        required: true,
      },
      pop: {
        type: Number,
        required: true,
      },
    },
  };
</script>
