import baseService from '@/@libs/services/baseService';
import httpClient from '@/@libs/httpClient';

export default {
  ...baseService,
  async getBulletinBoards(groupId, showDeleted) {
    const response = await httpClient.get(this.apiEndpoints.bulletinBoards.getGroupBulletins, {
      replace: {
        groupId,
        showDeleted,
      },
    });

    return response;
  },
  async postNewBulletin(groupId, bulletin) {
    const response = await httpClient.post(this.apiEndpoints.bulletinBoards.postNew, {
      replace: {
        groupId,
      },
      body: bulletin,
    });

    return response;
  },
  async deleteBulletin(groupId, bulletinId) {
    const response = await httpClient.delete(this.apiEndpoints.bulletinBoards.deleteBulletin, {
      replace: {
        groupId,
        bulletinId,
      },
    });

    return response;
  },
};
