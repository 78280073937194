import baseService from '@/@libs/services/baseService';
import httpClient from '@/@libs/httpClient';
import Store from '@/@libs/store';

export default {
  ...baseService,
  async getCurrentUserGroups() {
    const response = await httpClient.get(this.apiEndpoints.groups.listCurrentUserGroups, {
      replace: {
        userId: Store.state.userInfo.id,
      },
    });

    return response;
  },
  async getGroupUsers(groupId) {
    const response = await httpClient.get(this.apiEndpoints.groups.listGroupUsers, {
      replace: {
        groupId,
      },
    });

    return response;
  },
  async addUserToGroup(groupId, emailAddress, permissions) {
    const response = await httpClient.post(this.apiEndpoints.groups.addUserToGroup, {
      replace: {
        groupId,
        emailAddress,
      },
      payload: permissions,
    });

    return response;
  },
  async updateUserPermissions(groupId, userId, permissions) {
    const response = await httpClient.post(this.apiEndpoints.groups.updatePermissions, {
      replace: {
        groupId,
        userId,
      },
      payload: permissions,
    });

    return response;
  },
  async updateGroupDetails(groupId, groupDetails) {
    const response = await httpClient.put(this.apiEndpoints.groups.updateGroupDetails, {
      replace: {
        groupId,
      },
      payload: groupDetails,
    });

    return response;
  },
};
