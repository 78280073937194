<template>
  <v-tabs v-model="selectedTab" centered>
    <v-tab to="#details"> Details </v-tab>
    <v-tab to="#notes"> Notes </v-tab>
    <v-tab to="#attachments"> Attachments </v-tab>
    <v-tab to="#map"> Map </v-tab>
    <v-tab to="#weather"> Weather </v-tab>
    <v-tab to="#history"> History </v-tab>
    <v-tab-item reverse-transition="fade-transition" transition="fade-transition" value="details">
      <WorkOrderDetails v-if="selectedTab === 'details'" />
    </v-tab-item>
    <v-tab-item reverse-transition="fade-transition" transition="fade-transition" value="notes">
      <WorkOrderNotes v-if="selectedTab === 'notes'" />
    </v-tab-item>
    <v-tab-item
      reverse-transition="fade-transition"
      transition="fade-transition"
      value="attachments"
    >
      <WorkOrderAttachments />
    </v-tab-item>
    <v-tab-item reverse-transition="fade-transition" transition="fade-transition" value="history">
      <WorkOrderHistory v-if="selectedTab === 'history'" />
    </v-tab-item>
    <v-tab-item reverse-transition="fade-transition" transition="fade-transition" value="map">
      <WorkOrderMap v-if="selectedTab === 'map'" />
    </v-tab-item>
    <v-tab-item reverse-transition="fade-transition" transition="fade-transition" value="weather">
      <WorkOrderWeather v-if="selectedTab === 'weather'" />
    </v-tab-item>
  </v-tabs>
</template>

<script>
import WorkOrderDetails from '@/App/WorkOrders/EditWorkOrder/@Components/WorkOrderDetails';
import WorkOrderHistory from '@/App/WorkOrders/EditWorkOrder/@Components/WorkOrderHistory';
import WorkOrderNotes from '@/App/WorkOrders/EditWorkOrder/@Components/WorkOrderNotes';
import WorkOrderAttachments from '@/App/WorkOrders/EditWorkOrder/@Components/WorkOrderAttachments';
import WorkOrderMap from '@/App/WorkOrders/EditWorkOrder/@Components/WorkOrderMap';
import WorkOrderWeather
from '@/App/WorkOrders/EditWorkOrder/@Components/WorkOrderWeather';

export default {
  name: 'EditWorkOrder',
  components: {
    WorkOrderWeather,
    WorkOrderMap,
    WorkOrderAttachments,
    WorkOrderNotes,
    WorkOrderHistory,
    WorkOrderDetails,
  },
  data() {
    return {
      selectedTab: this.$route.hash?.replace('#', '') || 'details',
    };
  },
};
</script>

<style scoped></style>
