<template>
  <v-container
    fluid
    @dragenter.prevent="handleDragEnter"
    @dragleave.prevent="handleDragLeave"
    @dragover.prevent="handleDragOver"
  >
    <v-row>
      <v-col :cols="12">
        <v-expansion-panels v-model="panels">
          <v-expansion-panel>
            <v-expansion-panel-header><h2>Add Attachment(s)</h2></v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="drop-target" v-if="dragging > 0" @drop.prevent="handleDrop">
                <div>Drop files here to upload</div>
              </div>
              <div v-else>
                <v-select :items="validPhotoTypes" v-model="attachmentType" />
                <v-file-input v-model="file" :accept="validFileTypes" />
              </div>
            </v-expansion-panel-content>
            <v-card-actions v-if="panels === 0">
              <v-spacer />
              <v-btn text color="primary" @click="clearData"> Cancel </v-btn>
              <v-btn color="primary" @click="addAttachment" :loading="loading"> Save </v-btn>
            </v-card-actions>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12">
        <DataGrid :headers="headers" :items="attachments" id="work-order-attachments">
          <template v-slot:item.created="{ item: { created } }">
            <span style="white-space: nowrap">
              {{ new Date(created).toLocaleString() }}
            </span>
          </template>

          <template v-slot:item.attachmentType="{ item: { id, attachmentType } }">
            <span style="white-space: nowrap">
              <a :href="getAttachmentUrl(id)" target="_blank">{{
                getAttachmentFriendlyName(attachmentType)
              }}</a>
            </span>
          </template>

          <template v-slot:item.processed="{ item: { processed } }">
            <v-simple-checkbox :value="processed" />
          </template>

          <template v-slot:item.actions="">
            <span style="white-space: nowrap">
              <v-icon> mdi-delete </v-icon>
            </span>
          </template>
        </DataGrid>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import services from '@/@libs/services';
  import DataGrid from '@/App/@Components/DataGrid';
  import apiEndpoints from '@/@enums/apiEndpoints';

  export default {
    name: 'WorkOrderAttachments',
    components: { DataGrid },
    data() {
      return {
        attachmentToken: null,
        panels: null,
        file: null,
        attachments: [],
        attachmentType: 'WindshieldPhoto',
        loading: false,
        dragging: false,
        headers: [
          {
            id: 'attachmentType',
            text: 'Type',
            value: 'attachmentType',
            default: true,
            searchable: true,
            groupable: true,
          },
          {
            id: 'originalFileName',
            text: 'File Name',
            value: 'originalFileName',
            default: false,
            searchable: true,
            groupable: false,
          },
          {
            id: 'created',
            text: 'Created',
            value: 'created',
            default: true,
            searchable: false,
            groupable: false,
          },
          {
            id: 'fileSize',
            text: 'File Size',
            value: 'fileSize',
            default: false,
            searchable: false,
            groupable: false,
          },
          {
            id: 'shaHash',
            text: 'Hash',
            value: 'shaHash',
            default: false,
            searchable: false,
            groupable: false,
          },
          {
            id: 'processed',
            text: 'Processed',
            value: 'processed',
            default: false,
            searchable: false,
            groupable: false,
            align: 'center',
          },
          {
            id: 'actions',
            text: 'Actions',
            value: 'actions',
            sortable: false,
            groupable: false,
            default: true,
            align: 'center',
          },
        ],
      };
    },
    computed: {
      groupId() {
        return this.$route.params.groupId;
      },
      workOrderId() {
        return this.$route.params.workOrderId;
      },
      validPhotoTypes() {
        return services.enumService.arrayEnums.attachmentType.filter(
          (e) => e.validExtensions !== '',
        );
      },
      validFileTypes() {
        return services.enumService.findEnumByValue('attachmentType', this.attachmentType)
          .validExtensions;
      },
    },
    methods: {
      getAttachmentUrl(attachmentId) {
        return apiEndpoints.attachments.downloadAttachment
          .replace('{groupId}', this.groupId)
          .replace('{attachmentId}', attachmentId)
          .replace('{token}', this.attachmentToken);
      },
      async loadData() {
        this.loading = true;
        const [attachments, attachmentToken] = await Promise.all([
          services.workOrderService.getWorkOrderAttachments(this.groupId, this.workOrderId),
          services.userInfoService.getAttachmentToken(this.groupId),
        ]);
        this.loading = false;

        this.attachments = attachments;
        this.attachmentToken = attachmentToken;
      },
      getAttachmentFriendlyName(attachmentType) {
        return services.enumService.findEnumByValue('attachmentType', attachmentType).text;
      },
      clearData() {
        this.file = null;
        this.panels = null;
      },
      async addAttachment() {
        const { workOrderId, groupId, file, attachmentType } = this;

        try {
          this.loading = true;
          await services.workOrderService.addWorkOrderAttachment(
            groupId,
            workOrderId,
            file,
            attachmentType,
          );
        } finally {
          this.loading = false;
          this.file = null;
          this.panels = null;
          await this.loadData();
        }
      },
      handleDragEnter(e) {
        e.preventDefault();
        e.stopPropagation();

        this.dragging += 1;
        this.panels = 0;
      },
      handleDragLeave(e) {
        e.preventDefault();
        e.stopPropagation();

        this.dragging -= 1;
      },
      handleDragOver(e) {
        e.preventDefault();
        e.stopPropagation();
      },
      async handleDrop(e) {
        e.preventDefault();
        e.stopPropagation();

        const { workOrderId, groupId } = this;
        const tasks = [];

        for (let i = 0; i < e.dataTransfer.files.length; i += 1) {
          tasks.push(
            services.workOrderService.addWorkOrderAttachment(
              groupId,
              workOrderId,
              e.dataTransfer.files[i],
              services.enumService.mappedEnums.attachmentType.misc.value,
            ),
          );

          this.file = e.dataTransfer.files[i];
        }

        try {
          this.loading = true;
          await Promise.all(tasks);
        } finally {
          this.loading = false;
          this.panels = null;
          this.dragging = 0;
          this.file = null;
        }

        await this.loadData();
      },
    },
    async created() {
      await this.loadData();
    },
  };
</script>

<style scoped lang="scss">
  .drop-target {
    height: 200px;
    width: 100%;

    div {
      height: 1em;
      text-align: center;
      margin: auto;
    }
  }
</style>
