<template>
  <v-container fluid>
    <DataGrid
      id="bulletins"
      :custom-grid-settings-fields="customGridSettingsFields"
      :headers="headers"
      :items="bulletins"
      :show-add="true"
      title="Active Bulletins"
      :gridSettings.sync="gridSettings"
      @add="showDialog = true"
    >
      <template v-slot:item.created="{ item: { created } }">
        <span style="white-space: nowrap">
          {{ new Date(created).toLocaleString() }}
        </span>
      </template>
      <template v-slot:item.modified="{ item: { modified } }">
        <span style="white-space: nowrap">
          {{ new Date(modified).toLocaleString() }}
        </span>
      </template>

      <template v-slot:item.content>
        <span style="white-space: nowrap"> Content is only searchable </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <span style="white-space: nowrap">
          <v-icon v-if="!item.deleted" @click="deleteBulletin(item.id)"> mdi-delete </v-icon>
          <v-icon v-else> mdi-restore </v-icon>
        </span>
      </template>
    </DataGrid>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.mobile"
      :value="showDialog"
      max-width="600"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title>
          <v-text-field v-model="title" persistent-placeholder placeholder="New Post Title" />
          <v-spacer />
          <v-menu
            ref="menu"
            v-model="showDeleteDate"
            :close-on-content-click="false"
            min-width="auto"
            offset-y
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Auto Delete On?"
                :value="autoDelete"
                append-icon="mdi-calendar"
                clearable
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="autoDelete = null"
              ></v-text-field>
            </template>
            <v-date-picker
              scrollable
              @input="
                (newValue) => {
                  this.autoDelete = newValue;
                  this.showDeleteDate = false;
                }
              "
            >
              <v-spacer></v-spacer>
              <v-btn text @click="showDeleteDate = false"> Cancel </v-btn>
            </v-date-picker>
          </v-menu>
        </v-card-title>
        <v-card-text>
          <RichTextField :content.sync="content" min-height="300px" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="showDialog = !showDialog"> Cancel </v-btn>
          <v-btn color="primary" text @click="addBulletin"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import RichTextField from '@/App/@Components/RichTextField/RichTextField.vue';
  import DataGrid from '@/App/@Components/DataGrid';
  import services from '@/@libs/services';

  export default {
    name: 'ManageBulletins',
    components: {
      DataGrid,
      RichTextField,
    },
    data() {
      return {
        title: '',
        content: '<p>New Post Content</p>',
        showDialog: false,
        showDeleteDate: false,
        autoDelete: null,
        gridSettings: null,
        showDeletedBulletins: false,
        headers: [
          {
            id: 'title',
            text: 'Title',
            value: 'title',
            default: true,
            searchable: true,
            groupable: false,
          },
          {
            id: 'created',
            text: 'Created',
            value: 'created',
            default: true,
            groupable: false,
            searchable: false,
          },
          {
            id: 'modified',
            text: 'Modified',
            value: 'modified',
            default: true,
            groupable: false,
            searchable: false,
          },
          {
            id: 'content',
            text: 'Content',
            value: 'content',
            default: false,
            groupable: false,
            searchable: true,
          },
          {
            id: 'actions',
            text: 'Actions',
            value: 'actions',
            sortable: false,
            groupable: false,
            default: true,
            align: 'center',
          },
        ],
        customGridSettingsFields: [
          {
            id: 'showDeletedBulletins',
            type: 'check',
            label: 'Show Deleted Bulletins',
            value: 'showDeletedBulletins',
            cols: 4,
          },
        ],
        bulletins: [],
      };
    },
    computed: {
      groupId() {
        return this.$route.params.groupId;
      },
    },
    watch: {
      gridSettings: {
        handler(newValue) {
          this.showDeletedBulletins = newValue.showDeletedBulletins || false;
          this.loadData();
        },
        deep: true,
      },
    },
    methods: {
      async loadData() {
        const bulletins = await services.bulletinBoardService.getBulletinBoards(
          this.groupId,
          this.showDeletedBulletins,
        );

        this.bulletins = bulletins;
      },
      async addBulletin() {
        const { title, content, autoDelete } = this;

        await services.bulletinBoardService.postNewBulletin(this.groupId, {
          autoDelete,
          title,
          content,
        });
        this.bulletins.push({
          title: this.title,
          content: this.content,
          created: new Date(),
          modified: new Date(),
        });
        this.showDialog = false;

        await this.loadData();
      },
      async deleteBulletin(bulletinId) {
        await services.bulletinBoardService.deleteBulletin(this.groupId, bulletinId);
        await this.loadData();
      },
    },
    async created() {
      await this.loadData();
    },
  };
</script>
