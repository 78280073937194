<template>
  <div></div>
</template>

<script>
  import services from '@/@libs/services';

  export default {
    name: 'Logout',
    async created() {
      await services.userInfoService.logout();
      await this.$router.push({ path: '/login' });
    },
  };
</script>

<style scoped></style>
