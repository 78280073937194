<template>
  <v-container fluid>
    <v-card class="mx-auto">
      <v-card-title> Photo Settings </v-card-title>
      <v-card-subtitle> Required Photo Settings </v-card-subtitle>

      <v-card-text>
        <ReusableForm :fields="photoFields" :item="photoSettings" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn :disabled="disableSaveButton" color="primary" text @click="updatePhotoSettings">
          Save Changes
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
  import ReusableForm from '@/App/@Components/ReusableForm';
  import services from '@/@libs/services';

  export default {
    name: 'ManagePhotos',
    components: { ReusableForm },
    data() {
      return {
        photoSettings: {},
      };
    },
    computed: {
      groupId() {
        return this.$route.params.groupId;
      },
      photoFields() {
        const photos = services.enumService.arrayEnums.attachmentType.filter(
          (a) => a.fileType === 'Image',
        );

        return photos.map((a) => ({
          id: a.value,
          value: a.value,
          type: 'check',
          label: a.text,
          cols: 3,
        }));
      },
      disableSaveButton() {
        return false;
      },
    },
    methods: {
      async loadData() {
        const groupSettings = await services.groupSettingsService.getGroupSettings(this.groupId);

        this.photoSettings = groupSettings.requiredPhotos.reduce(
          (previousValue, newValue) => {
            // eslint-disable-next-line no-param-reassign
            previousValue[newValue] = true;

            return previousValue;
          },
          {},
        );
      },
      async updatePhotoSettings() {
        const requiredPhotos = [];

        Object.keys(this.photoSettings).forEach((key) => {
          if (this.photoSettings[key]) requiredPhotos.push(key);
        });

        await services.groupSettingsService.updatePhotosSettings(this.groupId, requiredPhotos);
        await this.loadData();
      },
    },
    async created() {
      await this.loadData();
    },
  };
</script>

<style scoped></style>
