import Vue from 'vue';
import VueRouter from 'vue-router';

import Login from '@/App/Login';

import GroupSelection from '@/App/GroupSelection';

import Groups from '@/App/Groups';
import GroupHome from '@/App/Groups/Home';

import store from '@/@libs/store';
import Logout from '@/App/Logout';
import CreateWorkOrder from '@/App/WorkOrders/CreateWorkOrder';
import WorkOrders from '@/App/WorkOrders';
import EditWorkOrder from '@/App/WorkOrders/EditWorkOrder';
import ArchivedWorkOrders from '@/App/WorkOrders/ArchivedWorkOrders';
import Bulletins from '@/App/Bulletins';
import WorkInProgress from '@/App/About/WorkInProgress';
import Bugs from '@/App/About/Bugs';
import OpenWeather from '@/App/Settings/OpenWeather';
import Smtp from '@/App/Settings/Smtp';
import ManageBulletins from '@/App/Bulletins/ManageBulletins';
import ManageTags from '@/App/Settings/Tags';
import WorkOrderAutomation from '@/App/Settings/WorkOrderAutomation';
import BugReport from '@/App/About/BugReport';
import NewRequest from '@/App/About/NewRequest';
import Google from '@/App/Settings/Google';
import OmegaEdi from '@/App/Settings/OmegaEdi';
import ExceptionLogs from '@/App/Logs/ExceptionLogs';
import PerformanceLogs from '@/App/Logs/PerformanceLogs';
import ManageAreas from '@/App/Settings/Areas';
import Avochato from '@/App/Settings/Avochato';
import ManageUsers from '@/App/Settings/Users';
import ManagePhotos from '@/App/Settings/Photos';
import SearchWorkOrders from '@/App/WorkOrders/SearchWorkOrders';
import ManageTemplates from '@/App/Settings/Templates';
import ManageCompanies from '@/App/Settings/Companies';
import MapWorkOrders from '@/App/WorkOrders/MapWorkOrders';
import Profile from '@/App/Settings/Profile';
import GroupDetails from '@/App/Settings/GroupDetails';
import FinishedWork from '@/App/About/FinishedWork';
import Hangfire from '@/App/Logs/Hangfire';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/groups/select',
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      allowAnonymous: true,
      layout: 'empty-layout',
      title: 'Login',
    },
  },
  {
    path: '/logout',
    component: Logout,
  },
  {
    path: '/work-in-progress',
    name: 'Development In Progress',
    component: WorkInProgress,
  },
  {
    path: '/finished-work',
    name: 'Finished Work',
    component: FinishedWork,
  },
  {
    path: '/new-request',
    name: 'Feature Request',
    component: NewRequest,
  },
  {
    path: '/profile',
    name: 'Edit Profile',
    component: Profile,
  },
  {
    path: '/bugs',
    name: 'Bugs',
    component: Bugs,
  },
  {
    path: '/bug-report',
    name: 'Bug Report',
    component: BugReport,
  },
  {
    path: '/groups/select',
    name: 'Group Selection',
    component: GroupSelection,
  },
  {
    path: '/:groupId/',
    component: Groups,
    children: [
      {
        path: '',
        name: 'Group Home',
        component: GroupHome,
      },
      {
        path: 'work-orders/create-work-order',
        name: 'Create Work Order',
        component: CreateWorkOrder,
      },
      {
        path: 'work-orders/automation',
        name: 'Manage Work Order Automation',
        component: WorkOrderAutomation,
      },
      {
        path: 'work-orders',
        name: 'Active Work Orders',
        component: WorkOrders,
      },
      {
        path: 'work-orders/archived',
        name: 'Archived Work Orders',
        component: ArchivedWorkOrders,
      },
      {
        path: 'work-orders/search',
        name: 'Search Work Orders',
        component: SearchWorkOrders,
      },
      {
        path: 'work-orders/map',
        name: 'Map Work Orders',
        component: MapWorkOrders,
      },
      {
        path: 'work-orders/:workOrderId',
        name: 'View Work Order',
        component: EditWorkOrder,
      },
      {
        path: 'bulletins',
        name: 'View Bulletins',
        component: Bulletins,
      },
      {
        path: 'bulletins/manage-bulletins',
        name: 'Manage Bulletins',
        component: ManageBulletins,
      },
      {
        path: 'settings/open-weather',
        name: 'Edit Open Weather Settings',
        component: OpenWeather,
      },
      {
        path: 'settings/smtp',
        name: 'Edit Smtp Settings',
        component: Smtp,
      },
      {
        path: 'settings/google',
        name: 'Edit Google Settings',
        component: Google,
      },
      {
        path: 'settings/group-details',
        name: 'Edit Group Details',
        component: GroupDetails,
      },
      {
        path: 'settings/omega',
        name: 'Edit OmegaEdi Settings',
        component: OmegaEdi,
      },
      {
        path: 'settings/tags',
        name: 'Manage Tags',
        component: ManageTags,
      },
      {
        path: 'settings/templates',
        name: 'Manage Templates',
        component: ManageTemplates,
      },
      {
        path: 'settings/users',
        name: 'Manage Users',
        component: ManageUsers,
      },
      {
        path: 'settings/photos',
        name: 'Manage Photos',
        component: ManagePhotos,
      },
      {
        path: 'settings/areas',
        name: 'Manage Areas',
        component: ManageAreas,
      },
      {
        path: 'settings/companies',
        name: 'Manage Companies',
        component: ManageCompanies,
      },
      {
        path: 'settings/avochato',
        name: 'Manage Avochato',
        component: Avochato,
      },
      {
        path: 'logs/exceptions',
        name: 'Exception Logs',
        component: ExceptionLogs,
      },
      {
        path: 'logs/performance',
        name: 'Performance Logs',
        component: PerformanceLogs,
      },
      {
        path: 'logs/hangfire',
        name: 'Hangfire',
        component: Hangfire,
      },
    ],
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: () => import(/* webpackChunkName: "about" */ '../../App/About'),
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const {
    state: { loggedIn },
  } = store;
  const {
    name: pageName,
    meta: { allowAnonymous, title },
  } = to;

  document.title = title || `iModus - ${pageName || 'Not Found'}`;

  if (!allowAnonymous && !loggedIn) {
    next({
      path: '/login',
      query: { redirect: to.fullPath },
    });
  } else {
    next();
  }
});

export default router;
