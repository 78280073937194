<template>
  <v-container fluid>
    <DataGrid
      :items="users"
      :headers="headers"
      id="manage-users"
      title="Manage Users"
      show-add
      @add="addNewUser"
      :loading="loading"
    >
      <template v-slot:item.actions="{ item }">
        <span style="white-space: nowrap">
          <v-icon @click="showEdit(item)"> mdi-pencil </v-icon>
          &nbsp;
          <v-icon> mdi-delete </v-icon>
        </span>
      </template>
    </DataGrid>

    <v-dialog :value="showDialog" persistent :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-card-title> {{ dialogTitle }} </v-card-title>
        <v-card-text>
          <ReusableForm :fields="userInfoFields" :item="newUser" v-if="showDialog" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="showDialog = false">Cancel</v-btn>
          <v-btn text color="primary" @click="addUser"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import _orderBy from 'lodash/orderBy';

  import DataGrid from '@/App/@Components/DataGrid';
  import services from '@/@libs/services';
  import ReusableForm from '@/App/@Components/ReusableForm';

  export default {
    name: 'ManageUsers',
    components: { ReusableForm, DataGrid },
    data() {
      return {
        loading: false,
        showDialog: false,
        editing: false,
        users: [],
        newUser: {},
        headers: [
          {
            id: 'name',
            text: 'Name',
            value: 'name',
            default: true,
            searchable: true,
            groupable: false,
          },
          {
            id: 'emailAddress',
            text: 'Email',
            value: 'emailAddress',
            default: true,
            searchable: true,
            groupable: false,
          },
          {
            id: 'actions',
            text: 'Actions',
            value: 'actions',
            sortable: false,
            groupable: false,
            default: true,
            align: 'center',
          },
        ],
      };
    },
    computed: {
      groupId() {
        return this.$route.params.groupId;
      },
      dialogTitle() {
        return this.editing ? 'Edit User' : 'Add New User';
      },
      userInfoFields() {
        const permissions = _orderBy(
          services.enumService.arrayEnums.permission.map((a) => ({
            id: a.value,
            type: 'check',
            label: a.text,
            value: a.value,
            colsLg: 3,
            colsMd: 4,
          })),
          'label',
        );

        return [
          {
            id: 'emailAddress',
            type: 'text',
            label: 'Email Address',
            value: 'emailAddress',
            cols: 12,
            readonly: this.editing,
          },
          ...permissions,
        ];
      },
    },
    methods: {
      addNewUser() {
        this.editing = false;
        this.newUser = {};
        this.showDialog = true;
      },
      showEdit(item) {
        const userPermissions = item.permissions.reduce(
          // eslint-disable-next-line no-return-assign,no-param-reassign,no-sequences
          (result, key) => ((result[key] = true), result),
          {},
        );

        this.editing = true;

        this.newUser = { ...item, ...userPermissions };
        this.showDialog = true;
      },
      async loadData() {
        try {
          this.loading = true;
          const users = await services.groupService.getGroupUsers(this.groupId);
          this.users = users;
        } finally {
          this.loading = false;
        }
      },
      async addUser() {
        const permissions = [];

        services.enumService.arrayEnums.permission.forEach((permission) => {
          if (this.newUser[permission.value]) {
            permissions.push(permission.value);
          }
        });

        try {
          this.loading = true;

          if (!this.editing) {
            await services.groupService.addUserToGroup(
              this.groupId,
              this.newUser.emailAddress,
              permissions,
            );
          } else {
            await services.groupService.updateUserPermissions(
              this.groupId,
              this.newUser.id,
              permissions,
            );
          }

          this.showDialog = false;

          this.newUser = {};
          this.editing = false;
          await this.loadData();
        } finally {
          this.loading = false;
        }
      },
    },
    async created() {
      await this.loadData();
    },
  };
</script>

<style scoped></style>
