import config from './config';

const environments = {
  localhost: 'localhost',
  'imodus-staging.herokuapp.com': 'staging',
  'imodus.dev': 'staging',
  'imodus.herokuapp.com': 'production',
  'imodus.app': 'production',
};

// https://cli.vuejs.org/guide/mode-and-env.html#modes
let environment;

// NODE_ENV = test|development|production
if (process.env.NODE_ENV === 'test') {
  environment = 'test';
} else if (typeof window !== 'undefined') {
  environment = environments[window.location.hostname];
}

if (!environment) {
  throw new Error('Failed to set the environment');
}

// eslint-disable-next-line import/no-dynamic-require
let envConfig = require(`./config.${environment}`);
// eslint-disable-next-line no-underscore-dangle
if (envConfig.__esModule) {
  envConfig = envConfig.default;
}

let configLocal = {};

// this code looks weird but is like this because of how webpack handles dynamic requires
try {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line global-require,import/no-unresolved
    configLocal = require('./config.local');
  } else if (process.env.NODE_ENV === 'test') {
    // eslint-disable-next-line import/no-unresolved,global-require
    configLocal = require('./config.local.test');
  }
  // eslint-disable-next-line no-underscore-dangle
  if (configLocal.__esModule) {
    configLocal = configLocal.default;
  }
} catch (error) {
  if (error.code !== 'MODULE_NOT_FOUND') {
    throw error;
  }
}

export default {
  environment,
  ...config,
  ...envConfig,
  ...configLocal,
};
