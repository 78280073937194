<template>
  <div>
    <DataGrid
      :id="id"
      :custom-grid-settings-fields="customGridSettingsFields"
      :gridSettings.sync="gridSettings"
      :headers="headers"
      :items="filteredWorkOrders"
      :loading="loading"
      :refresh="refresh"
      :title="title"
    >
      <template v-slot:item.workOrderNumber="{ item: { id, workOrderNumber } }">
        <span style="white-space: nowrap">
          <router-link :to="{ name: 'View Work Order', params: { workOrderId: id } }">
            {{ workOrderNumber }}
          </router-link>
        </span>
      </template>

      <template v-slot:item.externalId="{ item: { externalId } }">
        <span style="white-space: nowrap">
          <span v-if="externalId">OM{{externalId}}</span>
          <span v-else>&nbsp;</span>
        </span>
      </template>

      <template v-slot:item.created="{ item: { created } }">
        <span style="white-space: nowrap">
          {{ new Date(created).toLocaleString() }}
        </span>
      </template>

      <template v-slot:item.modified="{ item: { modified } }">
        <span style="white-space: nowrap">
          {{ new Date(modified).toLocaleString() }}
        </span>
      </template>

      <template
        v-slot:item.customerInformation.firstName="{
          item: {
            customerInformation: { firstName },
          },
        }"
      >
        <span style="white-space: nowrap">
          {{ notEnteredIfNull(firstName) }}
        </span>
      </template>

      <template
        v-slot:item.customerInformation.lastName="{
          item: {
            customerInformation: { lastName },
          },
        }"
      >
        <span style="white-space: nowrap">
          {{ notEnteredIfNull(lastName) }}
        </span>
      </template>

      <template
        v-slot:item.appointmentInformation.city="{
          item: {
            appointmentInformation: { city },
          },
        }"
      >
        <span style="white-space: nowrap">
          {{ notEnteredIfNull(city) }}
        </span>
      </template>

      <template
        v-slot:item.appointmentInformation.state="{
          item: {
            appointmentInformation: { state },
          },
        }"
      >
        <span style="white-space: nowrap">
          {{ notEnteredIfNull(state) }}
        </span>
      </template>

      <template
        v-slot:item.appointmentInformation.zipCode="{
          item: {
            appointmentInformation: { zipCode },
          },
        }"
      >
        <span style="white-space: nowrap">
          {{ notEnteredIfNull(zipCode) }}
        </span>
      </template>

      <template
        v-slot:item.appointmentInformation.installDate="{
          item: {
            appointmentInformation: { installDate },
          },
        }"
      >
        <span style="white-space: nowrap">
          {{ calendarDate(installDate) }}
        </span>
      </template>

      <template
        v-slot:item.vehicleInformation.year="{
          item: {
            vehicleInformation: { year },
          },
        }"
      >
        <span style="white-space: nowrap">
          {{ notEnteredIfNull(year) }}
        </span>
      </template>

      <template
        v-slot:item.vehicleInformation.make="{
          item: {
            vehicleInformation: { make },
          },
        }"
      >
        <span style="white-space: nowrap">
          {{ notEnteredIfNull(make) }}
        </span>
      </template>

      <template
        v-slot:item.vehicleInformation.model="{
          item: {
            vehicleInformation: { model },
          },
        }"
      >
        <span style="white-space: nowrap">
          {{ notEnteredIfNull(model) }}
        </span>
      </template>

      <template
        v-slot:item.paymentInformation.billTo="{
          item: {
            paymentInformation: { billTo },
          },
        }"
      >
        <span style="white-space: nowrap">
          {{ notEnteredIfNull(billTo) }}
        </span>
      </template>

      <template v-slot:item.missingFields="{ item: { missingFields } }">
        <v-chip
          v-for="(tag, index) in missingFields"
          :key="index"
          class="ma-1"
          color="orange"
          text-color="white"
        >
          {{ getTagText(tag) }}
        </v-chip>
      </template>

      <template v-slot:item.tags="{ item: { tags } }">
        <v-chip
          v-for="(tag, index) in tags"
          :key="index"
          class="ma-1"
          :color="tag.color"
          :text-color="tag.textColor"
        >
          {{ tag.value }}
        </v-chip>
      </template>

      <template v-slot:item.actions="{ item }">
        <span style="white-space: nowrap">
          <v-icon v-if="allowDelete" @click="toggleConfirmDelete(item)"> mdi-delete </v-icon>
          <v-icon v-if="allowArchive" @click="toggleConfirmArchive(item)"> mdi-archive </v-icon>
          <v-icon
            v-if="allowUnArchive && item.archivedOn"
            @click="toggleConfirmUnArchive(item)"
          >
            mdi-arrow-u-left-top
          </v-icon>
          <v-icon v-if="allowReassign" @click="toggleReassign(item)"> mdi-account-edit </v-icon>
        </span>
      </template>
    </DataGrid>
    <Confirmation :confirm="archiveWorkOrder" :max-width="600" :show.sync="showConfirmArchive">
      Are you sure you want to ARCHIVE work order {{ selectedWorkOrder.workOrderNumber }}
    </Confirmation>
    <Confirmation :confirm="unArchiveWorkOrder" :max-width="600" :show.sync="showConfirmUnArchive">
      Are you sure you want to UN-ARCHIVE work order {{ selectedWorkOrder.workOrderNumber }}
    </Confirmation>
    <Confirmation :confirm="deleteWorkOrder" :max-width="600" :show.sync="showConfirmDelete">
      Are you sure you want to DELETE work order {{ selectedWorkOrder.workOrderNumber }}
    </Confirmation>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.mobile"
      max-width="600px"
      persistent
      v-model="showReassign"
    >
      <v-card>
        <v-card-title>
          Reassign Work Order
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            :items="users"
            v-model="selectedWorkOrder.createdBy"
            label="Assigned User"
            item-value="id"
            item-text="name"
            />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showReassign = false;" text>Cancel</v-btn>
          <v-spacer />
          <v-btn @click="reassignWorkOrder" text color="primary">Save Changes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import DataGrid from '@/App/@Components/DataGrid';
  import Confirmation from '@/App/@Components/Confirmation';
  import services from '@/@libs/services';
  import moment from 'moment-timezone';

  export default {
    name: 'WorkOrderTable',
    components: {
      Confirmation,
      DataGrid,
    },
    props: {
      workOrders: {},
      id: {
        type: String,
        required: true,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      remove: {
        type: Function,
      },
      archive: {
        type: Function,
      },
      title: {
        type: String,
        default: 'Work Orders',
      },
      refresh: {
        type: Function,
      },
      allowDelete: {
        type: Boolean,
      },
      allowArchive: {
        type: Boolean,
      },
      allowUnArchive: {
        type: Boolean,
        default: false,
      },
      allowReassign: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        showConfirmArchive: false,
        showConfirmUnArchive: false,
        showConfirmDelete: false,
        showReassign: false,
        selectedWorkOrder: {},
        users: [],
        headers: [
          {
            id: 'workOrderNumber',
            text: 'WO #',
            value: 'workOrderNumber',
            groupable: false,
            default: true,
            searchable: true,
          },
          {
            id: 'externalId',
            text: 'External Id',
            value: 'externalId',
            groupable: false,
            default: false,
            searchable: true,
          },
          {
            id: 'createdBy',
            text: 'Created By',
            value: 'createdByUser.name',
            groupable: true,
            searchable: true,
          },
          {
            id: 'createdOn',
            text: 'Created',
            value: 'created',
            groupable: false,
          },
          {
            id: 'modifiedOn',
            text: 'Modified',
            value: 'modified',
            groupable: false,
          },
          {
            id: 'workOrderStatus',
            text: 'Status',
            value: 'workOrderStatus',
            default: true,
            searchable: true,
          },
          {
            id: 'firstName',
            text: 'First Name',
            value: 'customerInformation.firstName',
            groupable: false,
            default: true,
            searchable: true,
          },
          {
            id: 'lastName',
            text: 'Last Name',
            value: 'customerInformation.lastName',
            groupable: false,
            default: true,
            searchable: true,
          },
          {
            id: 'installDate',
            text: 'Appt. Date',
            value: 'appointmentInformation.installDate',
            groupable: false,
            default: true,
            searchable: false,
          },
          {
            id: 'city',
            text: 'Appt. City',
            value: 'appointmentInformation.city',
            groupable: true,
            default: false,
            searchable: true,
          },
          {
            id: 'state',
            text: 'Appt. State',
            value: 'appointmentInformation.state',
            groupable: true,
            default: false,
            searchable: true,
          },
          {
            id: 'zipCode',
            text: 'Appt. Zip Code',
            value: 'appointmentInformation.zipCode',
            groupable: true,
            default: false,
            searchable: true,
          },
          {
            id: 'paymentType',
            text: 'Payment Type',
            value: 'paymentInformation.paymentType',
            searchable: true,
          },
          {
            id: 'billTo',
            text: 'Bill To',
            value: 'paymentInformation.billTo',
            searchable: true,
          },
          {
            id: 'year',
            text: 'Year',
            value: 'vehicleInformation.year',
            groupable: false,
            default: false,
            searchable: true,
          },
          {
            id: 'make',
            text: 'Make',
            value: 'vehicleInformation.make',
            groupable: false,
            default: true,
            searchable: true,
          },
          {
            id: 'model',
            text: 'Model',
            value: 'vehicleInformation.model',
            groupable: false,
            default: true,
            searchable: true,
          },
          {
            id: 'color',
            text: 'Color',
            value: 'vehicleInformation.color',
            groupable: false,
            searchable: true,
          },
          {
            id: 'missingFields',
            text: 'Missing Fields',
            value: 'missingFields',
            groupable: false,
            searchable: false,
            align: 'justify',
          },
          {
            id: 'tags',
            text: 'Tags',
            value: 'tags',
            default: true,
            groupable: false,
            searchable: true,
            align: 'justify',
          },
          {
            id: 'actions',
            text: 'Actions',
            value: 'actions',
            sortable: false,
            groupable: false,
            default: true,
            align: 'center',
          },
        ],
        gridSettings: null,
        customGridSettingsFields: [
          {
            id: 'hideDraftWorkOrders',
            type: 'check',
            label: 'Hide Draft Work Orders',
            value: 'hideDraftWorkOrders',
            cols: 4,
          },
        ],
        hideDraftWorkOrders: false,
      };
    },
    computed: {
      groupId() {
        return this.$store.state.selectedGroup.id;
      },
      filteredWorkOrders() {
        if (!this.hideDraftWorkOrders) return this.workOrders;

        return this.workOrders.filter((workOrder) => workOrder.workOrderStatus !== 'Draft');
      },
    },
    watch: {
      gridSettings: {
        handler(newValue) {
          this.hideDraftWorkOrders = newValue.hideDraftWorkOrders || false;
        },
        deep: true,
      },
    },
    methods: {
      calendarDate(date) {
        if (!date) return 'Not Entered';

        return moment(date).format('MM/DD/yyyy');
      },
      notEnteredIfNull(value) {
        return value || 'Not Entered';
      },
      toggleConfirmArchive(workOrder) {
        this.selectedWorkOrder = workOrder;
        this.showConfirmArchive = true;
      },
      toggleConfirmUnArchive(workOrder) {
        this.selectedWorkOrder = workOrder;
        this.showConfirmUnArchive = true;
      },
      toggleConfirmDelete(workOrder) {
        this.selectedWorkOrder = workOrder;
        this.showConfirmDelete = true;
      },
      async toggleReassign(workOrder) {
        this.selectedWorkOrder = workOrder;

        if (this.users.length === 0) {
          this.users = await services.groupService.getGroupUsers(this.groupId);
        }

        this.showReassign = true;
      },
      async archiveWorkOrder() {
        await services.workOrderService.archiveWorkOrder(this.groupId, this.selectedWorkOrder.id);
        if (this.refresh) this.refresh();

        this.showConfirmArchive = false;
      },
      async unArchiveWorkOrder() {
        await services.workOrderService.unArchiveWorkOrder(this.groupId, this.selectedWorkOrder.id);
        if (this.refresh) this.refresh();

        this.showConfirmUnArchive = false;
      },
      async deleteWorkOrder() {
        await services.workOrderService.deleteWorkOrder(this.groupId, this.selectedWorkOrder.id);
        if (this.refresh) this.refresh();

        this.showConfirmDelete = false;
      },
      async reassignWorkOrder() {
        await services.workOrderService.reassignWorkOrder(
          this.groupId,
          this.selectedWorkOrder.id,
          this.selectedWorkOrder.createdBy,
        );
        if (this.refresh) this.refresh();

        this.showReassign = false;
      },
      getTagText(tag) {
        const result = tag.replace(/\sinformation/gi, '');

        if (!this.$vuetify.breakpoint.mobile) return result;

        return result
          .replace('Customer - ', '')
          .replace('Appointment - ', '')
          .replace('Vehicle - ', '')
          .replace('Payment - ', '');
      },
    },
  };
</script>
