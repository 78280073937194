<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" :permanent="!this.$vuetify.breakpoint.mobile" app>
      <NavigationMenu />
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon v-if="this.$vuetify.breakpoint.mobile" @click="toggleDrawer" />
      <v-toolbar-title>
        {{ selectedGroup.groupName }}
      </v-toolbar-title>
    </v-app-bar>

    <v-main class="main-container">
      <v-main>
        <slot />
      </v-main>
    </v-main>
  </v-app>
</template>

<script>
  import NavigationMenu from './NavigationMenu';

  export default {
    name: 'Default',
    components: { NavigationMenu },
    data() {
      return {
        drawer: !this.$vuetify.breakpoint.mobile,
      };
    },
    computed: {
      selectedGroup() {
        return this.$store.state.selectedGroup;
      },
    },
    methods: {
      toggleDrawer() {
        if (!this.$vuetify.breakpoint.mobile) {
          this.drawer = true;
          return;
        }

        this.drawer = !this.drawer;
      },
    },
  };
</script>

<style lang="scss">
.main-container {
  padding: 10px !important;
}
</style>
