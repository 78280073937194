import baseService from '@/@libs/services/baseService';
import httpClient from '@/@libs/httpClient';

export default {
  ...baseService,
  async getGroupTags(groupId, showDeleted) {
    const response = await httpClient.get(this.apiEndpoints.tags.getGroupTags, {
      replace: {
        groupId,
        showDeleted,
      },
    });

    return response;
  },
  async deleteTag(groupId, tagId) {
    const response = await httpClient.delete(this.apiEndpoints.tags.deleteTag, {
      replace: {
        groupId,
        tagId,
      },
    });

    return response;
  },
  async restoreTag(groupId, tagId) {
    const response = await httpClient.post(this.apiEndpoints.tags.restoreTag, {
      replace: {
        groupId,
        tagId,
      },
    });

    return response;
  },
  async updateTag(groupId, tag) {
    const { id: tagId } = tag;

    const response = await httpClient.put(this.apiEndpoints.tags.updateTag, {
      replace: {
        groupId,
        tagId,
      },
      body: tag,
    });

    return response;
  },
  async addTag(groupId, tag) {
    const response = await httpClient.post(this.apiEndpoints.tags.addTag, {
      replace: {
        groupId,
      },
      body: tag,
    });

    return response;
  },
  async syncWithOmegaTags(groupId) {
    const response = await httpClient.post(this.apiEndpoints.tags.syncWithOmega, {
      replace: {
        groupId,
      },
    });

    return response;
  },
};
