<template>
  <v-container fluid>
    <v-card v-if="groupSettings" class="mx-auto">
      <v-card-title> Smtp Settings </v-card-title>
      <v-card-subtitle> Email Account Settings </v-card-subtitle>

      <v-card-text>
        <ReusableForm :fields="smtpFields" :item="groupSettings.smtpSettings" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn :disabled="disableSaveButton" color="primary" text @click="updateSmtpSettings">
          Save Changes
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-skeleton-loader v-else type="article" />
  </v-container>
</template>

<script>
  import ReusableForm from '@/App/@Components/ReusableForm';
  import services from '@/@libs/services';

  export default {
    name: 'Smtp',
    components: { ReusableForm },
    data() {
      return {
        loading: false,
        groupSettings: null,
        originalGroupSettings: null,
      };
    },
    computed: {
      groupId() {
        return this.$route.params.groupId;
      },
      disableSaveButton() {
        return (
          JSON.stringify(this.originalGroupSettings.smtpSettings)
          === JSON.stringify(this.groupSettings.smtpSettings)
        );
      },
      smtpFields() {
        return [
          {
            id: 'enabled',
            value: 'enabled',
            type: 'check',
            label: 'Enabled',
            cols: 12,
            disabled:
              this.groupSettings.smtpSettings.host === '' || !this.groupSettings.smtpSettings.host,
          },
          {
            id: 'host',
            value: 'host',
            type: 'text',
            label: 'Host',
            cols: 4,
          },
          {
            id: 'port',
            value: 'port',
            type: 'number',
            label: 'Port',
            cols: 4,
          },
          {
            id: 'useSsl',
            value: 'useSsl',
            type: 'check',
            label: 'Use SSL',
            cols: 4,
          },
          {
            id: 'username',
            value: 'username',
            type: 'text',
            label: 'Username',
            cols: 4,
          },
          {
            id: 'password',
            value: 'password',
            type: 'text',
            label: 'Password',
            cols: 4,
          },
          {
            id: 'fromEmail',
            value: 'fromEmail',
            type: 'text',
            label: 'From',
            cols: 4,
          },
        ];
      },
    },
    watch: {
      groupSettings: {
        handler(newValue, oldValue) {
          if (newValue.smtpSettings.host === '') this.groupSettings.smtpSettings.enabled = false;

          if (newValue !== oldValue) {
            this.originalGroupSettings = JSON.parse(JSON.stringify(newValue));
          }
        },
        deep: true,
      },
    },
    methods: {
      async loadData() {
        const groupSettings = await services.groupSettingsService.getGroupSettings(this.groupId);

        this.groupSettings = groupSettings;
      },
      async updateSmtpSettings() {
        const groupSettings = await services.groupSettingsService.updateSmtpSettings(
          this.groupId,
          this.groupSettings.smtpSettings,
        );

        this.groupSettings = groupSettings;
      },
    },
    async created() {
      await this.loadData();
    },
  };
</script>

<style scoped></style>
