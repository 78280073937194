<template>
  <DataGrid
    :items="items"
    :loading="loading"
    id="exception-logs"
    :headers="headers"
    title="Exception Logs"
  >
    <template v-slot:item.timestamp="{ item: { timestamp } }">
      <span style="white-space: nowrap">
        {{ new Date(timestamp).toLocaleString() }}
      </span>
    </template>
  </DataGrid>
</template>

<script>
  import services from '@/@libs/services';
  import DataGrid from '@/App/@Components/DataGrid/DataGrid.vue';
  import orderBy from 'lodash/orderBy';

  export default {
    name: 'ExceptionLogs',
    components: { DataGrid },
    data() {
      return {
        loading: false,
        items: [],
        headers: [
          {
            id: 'id',
            text: 'Id',
            value: 'id',
            groupable: false,
            default: false,
            searchable: true,
          },
          {
            id: 'timestamp',
            text: 'Created',
            value: 'timestamp',
            groupable: false,
            default: true,
            searchable: true,
          },
          {
            id: 'message',
            text: 'Message',
            value: 'message',
            groupable: true,
            default: true,
            searchable: true,
          },
          {
            id: 'stackTrace',
            text: 'Stack Trace',
            value: 'stackTrace',
            groupable: false,
            default: true,
            searchable: true,
          },
        ],
      };
    },
    methods: {
      async loadData() {
        this.loading = true;

        const logs = await services.exceptionLogService.getExceptionLogs();
        this.items = orderBy(logs, ['created'], 'desc');

        this.loading = false;
      },
    },
    async created() {
      await this.loadData();
    },
  };
</script>

<style scoped></style>
