<template>
  <div>
    <div v-if="loading" class="loader"><v-progress-linear v-if="loading" indeterminate /></div>
    <div v-else>
      <iframe v-if="mapUrl" class="google-map-iframe" :src="mapUrl"></iframe>
      <div v-else>This work order has not been geo-located and therefore cannot display a map</div>
    </div>
  </div>
</template>

<script>
  import services from '@/@libs/services';

  export default {
    name: 'WorkOrderMap',
    data() {
      return {
        mapUrl: '',
        loading: false,
      };
    },
    computed: {
      groupId() {
        return this.$route.params.groupId;
      },
      workOrderId() {
        return this.$route.params.workOrderId;
      },
    },
    methods: {
      async loadData() {
        this.loading = true;
        const workOrder = await services.workOrderService.getWorkOrderDetails(
          this.groupId,
          this.workOrderId,
        );
        this.loading = false;

        this.workOrder = workOrder;
        this.mapUrl = workOrder.appointmentInformation.googleMapUrl && `${this.workOrder.appointmentInformation.googleMapUrl}&output=embed`;
      },
    },
    async created() {
      await this.loadData();
    },
  };
</script>

<style scoped>
.loader{
  margin-top: 10px;
}
.google-map-iframe {
  border: 0;
  box-shadow: 5px 5px 56px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
  height: calc(100vh - 144px);
}
</style>
