var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('DataGrid',{attrs:{"id":"bulletins","custom-grid-settings-fields":_vm.customGridSettingsFields,"headers":_vm.headers,"items":_vm.bulletins,"show-add":true,"title":"Active Bulletins","gridSettings":_vm.gridSettings},on:{"update:gridSettings":function($event){_vm.gridSettings=$event},"update:grid-settings":function($event){_vm.gridSettings=$event},"add":function($event){_vm.showDialog = true}},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var created = ref.item.created;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(new Date(created).toLocaleString())+" ")])]}},{key:"item.modified",fn:function(ref){
var modified = ref.item.modified;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(new Date(modified).toLocaleString())+" ")])]}},{key:"item.content",fn:function(){return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" Content is only searchable ")])]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[(!item.deleted)?_c('v-icon',{on:{"click":function($event){return _vm.deleteBulletin(item.id)}}},[_vm._v(" mdi-delete ")]):_c('v-icon',[_vm._v(" mdi-restore ")])],1)]}}])}),_c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.mobile,"value":_vm.showDialog,"max-width":"600","persistent":"","scrollable":""}},[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"persistent-placeholder":"","placeholder":"New Post Title"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('v-spacer'),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"min-width":"auto","offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Auto Delete On?","value":_vm.autoDelete,"append-icon":"mdi-calendar","clearable":"","readonly":""},on:{"click:clear":function($event){_vm.autoDelete = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.showDeleteDate),callback:function ($$v) {_vm.showDeleteDate=$$v},expression:"showDeleteDate"}},[_c('v-date-picker',{attrs:{"scrollable":""},on:{"input":function (newValue) {
                this$1.autoDelete = newValue;
                this$1.showDeleteDate = false;
              }}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showDeleteDate = false}}},[_vm._v(" Cancel ")])],1)],1)],1),_c('v-card-text',[_c('RichTextField',{attrs:{"content":_vm.content,"min-height":"300px"},on:{"update:content":function($event){_vm.content=$event}}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showDialog = !_vm.showDialog}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.addBulletin}},[_vm._v(" Save ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }