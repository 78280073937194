<template>
<div>Performance Logs</div>
</template>

<script>
  export default {
    name: 'PerformanceLogs',
  };
</script>

<style scoped>

</style>
