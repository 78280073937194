import eventBus from '../eventBus';

let bearerToken = null;

/**
 * Default set of options for all http requests
 * @type {{parseJson: boolean, displayLoading: boolean}}
 */
const defaultOptions = {
  parseJson: true,
  displayLoading: true,
};

/**
 *  Get Bearer Token from localStorage
 * @returns {string}
 */
function getBearerToken() {
  return bearerToken;
}

/**
 * Set the bearer token
 * @param newToken
 */
function setBearerToken(newToken) {
  bearerToken = newToken;
}

/**
 *  Generates headers for http request
 * @returns {Headers}
 */
function getHeaders() {
  const token = getBearerToken();
  const headers = new Headers([['Content-Type', 'application/json']]);

  if (token !== undefined && token !== null) {
    headers.append('Authorization', `Bearer ${token}`);
  }

  return headers;
}

/**
 *  Replace parameters in the url with actual values
 * @param url Required url that contains parameter place holders
 * @param replace Required object that contains parameter values
 * @returns {*} URL with params filled in
 */
function replaceParams(url, replace) {
  let result = url;

  if (replace) {
    Object.entries(replace).forEach((entry) => {
      const [key, value] = entry;
      result = result.replace(`{${key}}`, value);
    });
  }

  return result;
}

/**
 *  Make an httpClient request
 * @param url Required url to make request to
 * @param method Required httpClient action
 * @param options Required options for making httpClient request
 * @returns {Promise<string|any>}
 */
async function makeRequest(url, method, options) {
  const {
    body, payload, replace, displayLoading, parseJson, file,
  } = options;

  if (displayLoading) {
    eventBus.raiseEvent({ name: eventBus.eventTypes.http.loadingStarted, payload: null });
  }

  const response = await fetch(replaceParams(url, replace), {
    headers: getHeaders(),
    method,
    body: file || JSON.stringify(body || payload),
  });

  if (displayLoading) {
    eventBus.raiseEvent({ name: eventBus.eventTypes.http.loadingFinished, payload: null });
  }

  let error;
  let data = {};

  if (response && response.ok) {
    if (parseJson) {
      const value = await response.text();
      data = value ? JSON.parse(value) : value;
    } else {
      data = await response.text();
    }
  } else if (response && (response.status === 401 || response.status === 403)) {
    error = await response.json();
    eventBus.raiseEvent({ name: eventBus.eventTypes.http.authException, payload: error });

    throw new Error(error);
  } else {
    try {
      error = await response.json();
      eventBus.raiseEvent({ name: eventBus.eventTypes.app.error, payload: error });
    } catch {
      /// throw away
      error = { error: { msg: 'Something went wrong' } };
    }

    throw new Error(error);
  }

  return data;
}

/**
 *  Make a http get request
 * @param url Required url to make get request to
 * @param options Optional options to pass in
 * @returns {Promise<string|*>}
 */
async function get(url, options) {
  return makeRequest(url, 'get', { ...defaultOptions, ...options });
}

/**
 * Make a http post request
 * @param url Required url to make post request to
 * @param options optional options to pass in
 * @returns {Promise<string|*>}
 */
async function post(url, options) {
  return makeRequest(url, 'post', { ...defaultOptions, ...options });
}

/**
 * Make a http patch request
 * @param url Required url to make patch to
 * @param options Optional options to pass in
 * @returns {Promise<string|*>}
 */
async function patch(url, options) {
  return makeRequest(url, 'patch', { ...defaultOptions, ...options });
}

/**
 * Make an http delete request
 * @param url Required url to make delete request to
 * @param options Optional options to pass in
 * @returns {Promise<string|*>}
 */
async function del(url, options) {
  return makeRequest(url, 'delete', { ...defaultOptions, ...options });
}

/**
 * Make a http put request
 * @param url Required url to make put request to
 * @param options Optional options to pass in
 * @returns {Promise<string|*>}
 */
async function put(url, options) {
  return makeRequest(url, 'put', { ...defaultOptions, ...options });
}

export default {
  setBearerToken,
  get,
  post,
  patch,
  delete: del,
  put,
};
