<template>
  <v-card>
    <v-card-title>
      {{ title || '' }}
      <v-spacer />
      <v-tooltip bottom v-if="showAdd">
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="header-icon" @click="$emit('add')" v-on="on" v-bind="attrs">
            mdi-plus
          </v-icon>
        </template>
        Add
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="header-icon" @click="toggleShowSettings" v-on="on" v-bind="attrs">
            mdi-table-cog
          </v-icon>
        </template>
        Config
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="header-icon" @click="toggleShowColumns" v-on="on" v-bind="attrs">
            mdi-table-column
          </v-icon>
        </template>
        Columns
      </v-tooltip>
      <v-tooltip bottom v-if="refresh">
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="header-icon" @click="refresh" v-on="on" v-bind="attrs">
            mdi-refresh
          </v-icon>
        </template>
        Refresh
      </v-tooltip>
    </v-card-title>
    <v-card-title>
      <v-combobox
        v-model="search"
        append-icon="mdi-magnify"
        chips
        clearable
        hide-details
        label="Search"
        multiple
        single-line
      ></v-combobox>
    </v-card-title>
    <v-data-table
      :custom-filter="filterItems"
      :headers="filteredHeaders"
      :items="items"
      :loading="loading"
      :mobile-breakpoint="disableMobileView"
      :search="search.join(',')"
      :single-expand="true"
      class="elevation-1"
      show-group-by
      :footer-props="{ 'items-per-page-options': [25, 50, 100, -1] }"
      :group-by="groupBy"
      @update:group-by="updateGroupBy"
    >
      <template v-for="(_, name) in $scopedSlots" v-slot:[name]="data">
        <slot :name="name" v-bind="data"></slot>
      </template>
    </v-data-table>
    <v-dialog
      v-model="showSettings"
      :fullscreen="$vuetify.breakpoint.mobile"
      max-width="600"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title> Grid Settings </v-card-title>
        <v-card-text>
          <ReusableForm :fields="settingsFields" :item="gridSettings" />
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" text @click="resetSettings"> Reset Grid </v-btn>
          <v-spacer />
          <v-btn text @click="toggleShowSettings"> Cancel </v-btn>
          <v-btn color="primary" @click="saveSettings"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showColumns"
      :fullscreen="$vuetify.breakpoint.mobile"
      max-width="600"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title> Grid Columns </v-card-title>
        <v-card-text>
          <v-row>
            <v-col v-for="header in headers" :key="header.id" :cols="6" :md="3">
              <v-checkbox
                v-model="gridSettings[header.id]"
                :label="header.text"
                style="white-space: nowrap"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" text @click="resetSettings"> Reset Grid </v-btn>
          <v-spacer />
          <v-btn text @click="toggleShowColumns"> Cancel </v-btn>
          <v-btn color="primary" @click="saveSettings"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
  import _get from 'lodash/get';
  import services from '@/@libs/services';
  import ReusableForm from '@/App/@Components/ReusableForm';

  export default {
    name: 'DataGrid',
    components: { ReusableForm },
    props: {
      loading: {
        type: Boolean,
      },
      id: {
        type: String,
        required: true,
      },
      title: {
        type: String,
      },
      headers: {
        type: [Array, Object],
        required: true,
      },
      items: {
        type: [Array, Object],
        required: true,
      },
      refresh: {
        type: Function,
      },
      customGridSettingsFields: {
        type: [Array, Object],
        default() {
          return [];
        },
      },
      showAdd: {
        type: Boolean,
      },
    },
    data() {
      return {
        search: [],
        showSettings: false,
        showColumns: false,
        refreshInterval: null,
        gridSettings: {},
        gridSettingsFields: [
          {
            id: 'disableMobileView',
            type: 'check',
            label: 'Disable Mobile View',
            value: 'disableMobileView',
            cols: 4,
          },
          {
            id: 'autoRefresh',
            type: 'check',
            label: 'Auto Refresh',
            value: 'autoRefresh',
            cols: 4,
          },
          {
            id: 'refreshInterval',
            type: 'number',
            label: 'Refresh Interval in minutes',
            value: 'refreshInterval',
            cols: 4,
          },
        ],
      };
    },
    computed: {
      groupId() {
        return this.$route.params.groupId;
      },
      userId() {
        return this.$store.state.userInfo.id;
      },
      disableMobileView() {
        return this.gridSettings?.disableMobileView ? 0 : 600;
      },
      filteredHeaders() {
        if (!this.gridSettings) return this.headers;

        return this.headers.filter((header) => this.gridSettings[header.id]);
      },
      settingsFields() {
        return [...this.gridSettingsFields, ...this.customGridSettingsFields];
      },
      groupBy() {
        return this.gridSettings.groupBy || null;
      },
    },
    methods: {
      async updateGroupBy(newGroupBy) {
        this.gridSettings.groupBy = newGroupBy;
        await this.saveSettings();
      },
      async loadData() {
        const gridSettings = await services.configurationService.getUserConfiguration(
          this.groupId,
          `${this.id}-settings`,
          this.userId,
        );

        this.headers.forEach(
          // eslint-disable-next-line no-return-assign,max-len
          (header) => (gridSettings.value[header.id] = gridSettings.value[header.id] ?? header.default),
        );

        this.customGridSettingsFields.forEach(
          // eslint-disable-next-line no-return-assign,max-len
          (setting) => (gridSettings.value[setting.id] = gridSettings.value[setting.id] ?? setting.default),
        );

        this.gridSettings = gridSettings.value;

        this.$emit('update:gridSettings', this.gridSettings);
      },
      toggleShowSettings() {
        this.showSettings = !this.showSettings;
      },
      toggleShowColumns() {
        this.showColumns = !this.showColumns;
      },
      filterItems(value, search, item) {
        const terms = search.toLowerCase().split(',');

        if (
          value instanceof String
          && terms.length === 1
          && value?.toLowerCase().includes(search.toLowerCase())
        ) {
          return true;
        }

        const values = this.headers
          .filter((header) => header.searchable)
          .map((header) => {
            const tmpValue = _get(item, header.value);

            if (tmpValue instanceof String) return tmpValue;

            if (Array.isArray(tmpValue)) {
              return tmpValue.map((a) => a.value || a.text || a.description).join();
            }

            return tmpValue?.toString();
          })
          .filter((tmpValue) => !!tmpValue);

        let result = true;

        terms.forEach((term) => {
          result = result && values.some((val) => val.toLowerCase().includes(term));
        });

        return result;
      },
      async saveSettings() {
        await services.configurationService.updateUserConfiguration(
          this.groupId,
          `${this.id}-settings`,
          this.userId,
          this.gridSettings,
        );

        this.showSettings = false;
        this.showColumns = false;

        if (this.refreshInterval) clearInterval(this.refreshInterval);

        if (this.gridSettings.autoRefresh) {
          this.refreshInterval = setInterval(
            this.refresh,
            (this.gridSettings.refreshInterval || 10) * 1000 * 60,
          );
        }
      },
      async resetSettings() {
        this.gridSettings = {};
        await this.saveSettings();
        await this.loadData();
      },
    },
    async created() {
      await this.loadData();

      if (this.gridSettings.autoRefresh) {
        this.refreshInterval = setInterval(
          this.refresh,
          (this.gridSettings.refreshInterval || 10) * 1000 * 60,
        );
      }
    },
    destroyed() {
      if (this.refreshInterval) clearInterval(this.refreshInterval);
    },
  };
</script>

<style scoped>
  .header-icon {
    margin-left: 15px;
  }
</style>
