<template>
  <router-view />
</template>

<script>
  export default {
    name: 'Group',
  };
</script>

<style scoped></style>
