<template>
  <v-container fluid>
    <v-card v-if="loaded" class="mx-auto">
      <v-card-title> Group Details </v-card-title>
      <v-card-subtitle> Edit group details </v-card-subtitle>

      <v-card-text>
        <ReusableForm :fields="groupFields" :item="groupDetails" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="disableSaveButton"
          color="primary"
          text
          @click="updateGroupDetails"
          :loading="loading"
        >
          Save Changes
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-skeleton-loader v-else type="article" />
  </v-container>
</template>

<script>
  import ReusableForm from '@/App/@Components/ReusableForm';
  import services from '@/@libs/services';

  export default {
    name: 'GroupDetails',
    components: { ReusableForm },
    data() {
      return {
        loaded: false,
        loading: false,
        groupFields: [
          {
            id: 'groupName',
            value: 'groupName',
            type: 'text',
            label: 'Name',
            cols: 12,
          },
        ],
        groupDetails: {},
      };
    },
    computed: {
      groupId() {
        return this.$route.params.groupId;
      },
      disableSaveButton() {
        return !this.groupDetails.groupName || this.groupDetails.groupName === '';
      },
    },
    methods: {
      async updateGroupDetails() {
        try {
          this.loading = true;
          const group = await services.groupService.updateGroupDetails(
            this.groupId,
            this.groupDetails,
          );
          await this.$store.dispatch('selectGroup', group);
        } finally {
          this.loading = false;
        }
      },
      async loadData() {
        this.groupDetails = JSON.parse(JSON.stringify(this.$store.state.selectedGroup));
        this.loaded = true;
      },
    },
    async created() {
      await this.loadData();
    },
  };
</script>

<style scoped></style>
