import userInfoService from '@/@libs/services/userInfoService';
import groupService from '@/@libs/services/groupService';
import workOrderService from '@/@libs/services/workOrderService';
import configurationService from '@/@libs/services/configurationService';
import entityHistoryService from '@/@libs/services/entityHistoryService';
import groupSettingsService from '@/@libs/services/groupSettingsService';
import bulletinBoardService from '@/@libs/services/bulletinBoardService';
import tagService from '@/@libs/services/tagService';
import workOrderAutomationService from '@/@libs/services/workOrderAutomationService';
import enumService from '@/@libs/services/enumService';
import exceptionLogService from '@/@libs/services/exceptionLogService';
import serviceAreasService from '@/@libs/services/serviceAreasService';
import companyService from '@/@libs/services/companyService';
import hangfireService from '@/@libs/services/hangfireService';

export default {
  userInfoService,
  groupService,
  workOrderService,
  configurationService,
  entityHistoryService,
  groupSettingsService,
  bulletinBoardService,
  tagService,
  workOrderAutomationService,
  enumService,
  exceptionLogService,
  serviceAreasService,
  companyService,
  hangfireService,
};
