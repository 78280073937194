<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <DataGrid
          id="service-areas"
          :headers="headers"
          :items="serviceAreas"
          :show-add="true"
          title="Service Areas"
          :loading="loading"
          @add="addArea"
        >
          <template v-slot:item.created="{ item: { created } }">
            <span style="white-space: nowrap">
              {{ new Date(created).toLocaleString() }}
            </span>
          </template>
          <template v-slot:item.modified="{ item: { modified } }">
            <span style="white-space: nowrap">
              {{ new Date(modified).toLocaleString() }}
            </span>
          </template>
          <template v-slot:item.zipCodes="{ item: { zipCodes } }">
            <v-chip-group column>
              <v-chip v-for="(zipCode, index) in zipCodes" :key="index" class="ma-1">
                {{ zipCode }}
              </v-chip>
            </v-chip-group>
          </template>
          <template v-slot:item.technicianIds="{ item: { technicianIds } }">
            <v-chip-group column>
              <v-chip v-for="(technicianId, index) in technicianIds" :key="index" class="ma-1">
                {{ userNameFromId(technicianId) }}
              </v-chip>
            </v-chip-group>
          </template>

          <template v-slot:item.actions="{ item }">
            <span style="white-space: nowrap">
              <v-icon @click="showEdit(item)"> mdi-pencil </v-icon>
              <v-icon v-if="!item.deleted" @click="showConfirm(item)"> mdi-delete </v-icon>
              <v-icon v-else @click="showConfirm(item)"> mdi-restore </v-icon>
            </span>
          </template>
        </DataGrid>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card :elevation="0">
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" @click="syncWithOmegaAreas" :loading="loading">
              Sync Omega Areas
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      :value="showDialog"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
      :max-width="600"
    >
      <v-card>
        <v-card-title>{{ dialogTitle }}</v-card-title>
        <v-card-text>
          <ReusableForm :fields="areaFields" :item="editArea" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="hideDialog">Cancel</v-btn>
          <v-btn text color="primary" @click="saveArea"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import services from '@/@libs/services';
import DataGrid from '@/App/@Components/DataGrid';
import _orderBy from 'lodash/orderBy';
import ReusableForm from '@/App/@Components/ReusableForm';

export default {
  name: 'ManageAreas',
  components: { ReusableForm, DataGrid },
  data() {
    return {
      showDialog: false,
      loading: false,
      users: [],
      headers: [
        {
          id: 'name',
          text: 'Name',
          value: 'name',
          default: true,
          searchable: true,
          groupable: false,
        },
        {
          id: 'technicians',
          text: 'Technicians',
          value: 'technicianIds',
          default: true,
          searchable: false,
          groupable: false,
        },
        {
          id: 'zipCodes',
          text: 'Zip Codes',
          value: 'zipCodes',
          default: true,
          searchable: true,
          groupable: false,
        },
        {
          id: 'created',
          text: 'Created',
          value: 'created',
          default: false,
          searchable: false,
          groupable: false,
        },
        {
          id: 'modified',
          text: 'Modified',
          value: 'modified',
          default: false,
          searchable: false,
          groupable: false,
        },
        {
          id: 'externalId',
          text: 'External Id',
          value: 'externalId',
          default: false,
          searchable: false,
          groupable: false,
        },
        {
          id: 'actions',
          text: 'Actions',
          value: 'actions',
          sortable: false,
          groupable: false,
          default: true,
          align: 'center',
        },
      ],
      serviceAreas: [],
      editArea: {},
    };
  },
  computed: {
    groupId() {
      return this.$route.params.groupId;
    },
    dialogTitle() {
      return this.editArea.id ? 'Edit Area' : 'Add Area';
    },
    areaFields() {
      return [
        {
          id: 'name',
          type: 'text',
          label: 'Name',
          value: 'name',
          cols: 12,
        },
        {
          id: 'technicianIds',
          type: 'auto-complete',
          label: 'Technicians',
          value: 'technicianIds',
          multiple: true,
          items: this.getTechnicians(),
          cols: 12,
          chips: true,
        },
        {
          id: 'zipCodes',
          type: 'auto-complete',
          label: 'Zip Codes',
          value: 'zipCodes',
          multiple: true,
          items: services.enumService.arrayEnums.zipCode,
          cols: 12,
          chips: true,
        },
      ];
    },
  },
  methods: {
    async syncWithOmegaAreas() {
      try {
        this.loading = true;
        await services.serviceAreasService.syncWithOmega(this.groupId);
        await this.loadData();
      } finally {
        this.loading = false;
      }
    },
    addArea() {
      this.editArea = {};
      this.showDialog = true;
    },
    showEdit(selectedItem) {
      this.editArea = selectedItem;
      this.showDialog = true;
    },
    async saveArea() {
      if (!this.editArea.id) {
        await services.serviceAreasService.addArea(this.groupId, this.editArea);
      } else {
        await services.serviceAreasService.updateArea(
          this.groupId,
          this.editArea.id,
          this.editArea,
        );
      }

      this.showDialog = false;

      await this.loadData();
    },
    async loadData() {
      this.loading = true;
      const [serviceAreas, users] = await Promise.all([
        services.serviceAreasService.listAreas(this.groupId),
        await services.groupService.getGroupUsers(this.groupId),
      ]);
      this.loading = false;

      this.serviceAreas = _orderBy(serviceAreas, 'name');
      this.users = _orderBy(users, 'name');
    },
    async hideDialog() {
      this.showDialog = false;
      await this.loadData();
    },
    getTechnicians() {
      return this.users
        .filter((user) => user.appointmentTimes?.length > 0)
        .map((user) => ({ value: user.id, text: user.name }));
    },
    userNameFromId(userId) {
      return this.users.find((u) => u.id === userId).name;
    },
  },
  async created() {
    await this.loadData();
  },
};
</script>

<style scoped></style>
