var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('DataGrid',{attrs:{"id":"workOrderAutomation","headers":_vm.headers,"items":_vm.workOrderAutomations,"show-add":true,"gridSettings":_vm.gridSettings,"custom-grid-settings-fields":_vm.customGridSettingsFields,"title":_vm.gridTitle,"loading":_vm.loading},on:{"add":_vm.showAdd,"update:gridSettings":function($event){_vm.gridSettings=$event},"update:grid-settings":function($event){_vm.gridSettings=$event}},scopedSlots:_vm._u([{key:"item.successAction",fn:function(ref){
var successAction = ref.item.successAction;
return [_vm._v(" "+_vm._s(_vm.findSuccessAction(successAction))+" ")]}},{key:"item.trigger",fn:function(ref){
var trigger = ref.item.trigger;
return [_vm._v(" "+_vm._s(_vm.findTrigger(trigger))+" ")]}},{key:"item.created",fn:function(ref){
var created = ref.item.created;
return [_vm._v(" "+_vm._s(new Date(created).toLocaleString())+" ")]}},{key:"item.modified",fn:function(ref){
var modified = ref.item.modified;
return [_vm._v(" "+_vm._s(new Date(modified).toLocaleString())+" ")]}},{key:"item.workflow.rules",fn:function(ref){
      var rules = ref.item.workflow.rules;
return _vm._l((rules),function(rule,index){return _c('span',{key:index},[_vm._v(_vm._s(rule.a))])})}},{key:"item.enabled",fn:function(ref){
      var enabled = ref.item.enabled;
return [(enabled)?_c('v-icon',[_vm._v("mdi-checkbox-marked-outline")]):_c('v-icon',[_vm._v("mdi-checkbox-blank-outline")])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_c('v-icon',{on:{"click":function($event){return _vm.showEdit(item)}}},[_vm._v(" mdi-pencil ")]),(!item.deleted)?_c('v-icon',{on:{"click":function($event){return _vm.showConfirm(item)}}},[_vm._v(" mdi-delete ")]):_c('v-icon',{on:{"click":function($event){return _vm.showConfirm(item)}}},[_vm._v(" mdi-restore ")])],1)]}}])}),_c('Confirmation',{attrs:{"confirm":_vm.confirmSelectedAutomation,"show":_vm.showConfirmDialog,"max-width":600,"cancel":function () { return (this$1.showConfirmDialog = false); }}},[_vm._v(" Are you sure you want to "+_vm._s(_vm.operation)+" Work Order Automation "+_vm._s(_vm.selectedAutomation.name)+"? ")]),_c('v-dialog',{attrs:{"value":_vm.showDialog,"persistent":"","fullscreen":_vm.$vuetify.breakpoint.mobile}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.dialogTitle))]),_c('v-card-text',[_c('ReusableForm',{attrs:{"fields":_vm.automationFields,"item":_vm.editAutomation}}),_c('RulesEditor',{attrs:{"rules":_vm.editAutomation.workflow.rules}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.saveAutomation}},[_vm._v(" Save ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }