<template>
  <tiptap-vuetify
    :extensions="extensions"
    :value="content"
    @input="(newValue) => $emit('update:content', newValue)"
    :card-props="{ minHeight }"
    style="overflow-y: auto"
  />
</template>

<script>
// import the component and the necessary extensions
  import {
    Blockquote,
    Bold,
    BulletList,
    HardBreak,
    Heading,
    History,
    HorizontalRule,
    Italic,
    Link,
    ListItem,
    OrderedList,
    Paragraph,
    Strike,
    TiptapVuetify,
    Underline,
  } from 'tiptap-vuetify';

  export default {
    // specify TiptapVuetify component in "components"
    components: { TiptapVuetify },
    props: {
      content: {
        type: String,
        required: true,
      },
      height: {
        type: [String, Number],
        default() {
          return '300px';
        },
      },
      minHeight: {
        type: [String, Number],
        default() {
          return '300px';
        },
      },
    },
    data() {
      return {
        postTitle: '',
        extensions: [
          History,
          Blockquote,
          Link,
          Underline,
          Strike,
          Italic,
          ListItem,
          BulletList,
          OrderedList,
          [
            Heading,
            {
              options: {
                levels: [1, 2, 3],
              },
            },
          ],
          Bold,
          HorizontalRule,
          Paragraph,
          HardBreak,
        ],
      };
    },
  };
</script>
