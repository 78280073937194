import baseService from '@/@libs/services/baseService';
import httpClient from '@/@libs/httpClient';

export default {
  ...baseService,
  async getGroupSettings(groupId) {
    const response = await httpClient.get(this.apiEndpoints.groupSettings.getGroupSettings, {
      replace: {
        groupId,
      },
    });

    return response;
  },
  async updateOpenWeatherSettings(groupId, openWeatherSettings) {
    const response = await httpClient.put(
      this.apiEndpoints.groupSettings.updateOpenWeatherSettings,
      {
        replace: {
          groupId,
        },
        body: openWeatherSettings,
      },
    );

    return response;
  },
  async updateSmtpSettings(groupId, smtpSettings) {
    const response = await httpClient.put(this.apiEndpoints.groupSettings.updateSmtpSettings, {
      replace: {
        groupId,
      },
      body: smtpSettings,
    });

    return response;
  },
  async updateGoogleSettings(groupId, googleSettings) {
    const response = await httpClient.put(this.apiEndpoints.groupSettings.updateGoogleSettings, {
      replace: {
        groupId,
      },
      body: googleSettings,
    });

    return response;
  },
  async updateOmegaEdiSettings(groupId, omegaEdiSettings) {
    const response = await httpClient.put(this.apiEndpoints.groupSettings.updateOmegaEdiSettings, {
      replace: {
        groupId,
      },
      body: omegaEdiSettings,
    });

    return response;
  },
  async updateAvachatoSettings(groupId, avochatoSettings) {
    const response = await httpClient.put(this.apiEndpoints.groupSettings.updateAvochatoSettings, {
      replace: {
        groupId,
      },
      body: avochatoSettings,
    });

    return response;
  },
  async updatePhotosSettings(groupId, requiredPhotos) {
    const response = await httpClient.put(this.apiEndpoints.groupSettings.updateRequiredPhotos, {
      replace: {
        groupId,
      },
      body: requiredPhotos,
    });

    return response;
  },
  async updateTemplate(groupId, templateType, handlebarsTemplate) {
    const response = await httpClient.put(this.apiEndpoints.groupSettings.updateTemplate, {
      replace: {
        groupId,
        templateType,
      },
      body: handlebarsTemplate,
    });

    return response;
  },
  async getTemplateValue(groupId, templateType) {
    const response = await httpClient.get(this.apiEndpoints.groupSettings.getTemplateValue, {
      replace: {
        groupId,
        templateType,
      },
      parseJson: false,
    });

    return response;
  },
  async getGoogleApiKey(groupId) {
    const response = await httpClient.get(this.apiEndpoints.groupSettings.getGoogleApiKey, {
      replace: {
        groupId,
      },
      parseJson: false,
    });

    return response;
  },
};
