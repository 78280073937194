<template>
  <v-container fluid>
    <WorkOrderTable
      id="active-work-orders"
      :loading="loading"
      :work-orders="workOrders"
      title="Active Work Orders"
      :archive="archiveWorkOrder"
      :refresh="loadData"
      allow-archive
      allow-delete
      allow-reassign
    />
  </v-container>
</template>

<script>
  import services from '@/@libs/services';
  import WorkOrderTable from '@/App/WorkOrders/@Components/WorkOrderTable';

  export default {
    name: 'WorkOrders',
    components: { WorkOrderTable },
    data() {
      return {
        workOrders: [],
        users: [],
        loading: false,
      };
    },
    computed: {
      groupId() {
        return this.$store.state.selectedGroup.id;
      },
    },
    methods: {
      async loadData() {
        this.loading = true;
        const activeWorkOrders = await services.workOrderService.listActiveWorkOrders(this.groupId);
        this.loading = false;

        this.workOrders = activeWorkOrders;
      },
      async archiveWorkOrder(workOrder) {
        await services.workOrderService.archiveWorkOrder(this.groupId, workOrder.id);
        await this.loadData();
      },
    },
    created() {
      this.loadData();
    },
  };
</script>
