<template>
  <v-container fluid>
    <v-expansion-panels v-model="panels" v-if="loaded">
      <v-expansion-panel v-for="(template, index) in templateTypes" :key="index">
        <v-expansion-panel-header>
          {{ template.text }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card>
            <v-card-title>
              <v-checkbox v-model="templateView[index]" label="Basic Editor" />
            </v-card-title>
            <v-card-text>
              <div>
                <RichTextField
                  :content.sync="templates[template.value]"
                  v-if="!templateView[index]"
                  min-height="500"
                  height="600"
                />
                <v-textarea v-model="templates[template.value]" v-else rows="29" />
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn color="error" @click="loadData" :loading="loading"> Reset </v-btn>
              <v-spacer />
              <v-btn color="primary" @click="updateTemplate(template.value)" :loading="loading">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
  import services from '@/@libs/services';
  import RichTextField from '@/App/@Components/RichTextField';

  export default {
    name: 'ManageTemplates',
    components: { RichTextField },
    data() {
      return {
        loaded: false,
        loading: false,
        templates: {},
        templateView: [],
        panels: [],
      };
    },
    computed: {
      templateTypes() {
        return services.enumService.arrayEnums.templateType;
      },
      groupId() {
        return this.$route.params.groupId;
      },
    },
    methods: {
      async updateTemplate(templateType) {
        try {
          this.loading = true;
          await services.groupSettingsService.updateTemplate(
            this.groupId,
            templateType,
            this.templates[templateType],
          );
        } finally {
          this.loading = false;
        }
      },
      async loadData() {
        try {
          this.loading = true;
          const { templates } = await services.groupSettingsService.getGroupSettings(this.groupId);

          services.enumService.arrayEnums.templateType.forEach((template) => {
            this.templates[template.value] = '';
          });

          templates.forEach(
            // eslint-disable-next-line no-return-assign
            (template, index) => {
              this.templates[template.name] = template.handlebarsTemplate;
              this.templateView[index] = true;
            },
          );

          this.loaded = true;
        } finally {
          this.loading = false;
        }
      },
    },
    async created() {
      await services.enumService.getEnums();
      await this.loadData();
    },
  };
</script>

<style scoped></style>
