import baseService from '@/@libs/services/baseService';
import httpClient from '@/@libs/httpClient';

export default {
  ...baseService,
  async getHangfireUrl() {
    const result = await httpClient.get(this.apiEndpoints.hangfire.getHangfireUrl, {
      parseJson: false,
    });

    return this.apiEndpoints.hangfire.root + result;
  },
};
