<template>
  <v-dialog :fullscreen="$vuetify.breakpoint.mobile" :value="true" max-width="600px" persistent>
    <v-card>
      <v-card-title> Create New Work Order </v-card-title>
      <v-card-text>
        Clicking Yes will create a new work order and open it for editing. Do you wish to proceed?
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="red" text @click="cancel"> Cancel </v-btn>
        <v-btn color="primary" text @click="createWorkOrder"> Yes </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import services from '@/@libs/services';

  export default {
    name: 'CreateWorkOrder',
    computed: {
      groupId() {
        return this.$store.state.selectedGroup.id;
      },
    },
    methods: {
      cancel() {
        this.$router.push({ path: `/${this.groupId}/work-orders/` });
      },
      async createWorkOrder() {
        const newWorkOrder = await services.workOrderService.postNew(this.groupId);

        await this.$router.push({
          path: `/${this.groupId}/work-orders/${newWorkOrder.id}?edit=true`,
        });
      },
    },
  };
</script>

<style scoped></style>
