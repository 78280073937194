<template>
  <v-container fluid>
    <v-btn
      fab
      bottom
      right
      fixed
      style="top: 75px"
      @click="showMissingFields = true"
      v-if="workOrder && workOrder.missingFields.length > 0"
      color="yellow darken-3"
    >
      <v-icon>mdi-alert</v-icon>
    </v-btn>
    <v-card v-if="workOrder" :elevation="0">
      <v-progress-linear :active="loading" indeterminate />
      <v-expansion-panels v-model="panels" multiple>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h2>Status</h2>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col :cols="9" :lg="11" :md="11">
                <v-select
                  v-model="workOrder.workOrderStatus"
                  :items="workOrderStatuses"
                  @change="saveWorkOrderStatus"
                />
              </v-col>
              <v-col :cols="3" :lg="1" :md="1">
                <v-checkbox
                  v-if="allowOverride"
                  v-model="overrideValidationChecks"
                  label="Override"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="12" :lg="2" :md="2">
                <v-select
                  :items="filteredTags"
                  item-value="id"
                  item-text="value"
                  @input="addTag"
                  label="Add Tag"
                  solo
                  rounded
                  no-data-text="No Remaining Tags"
                  v-model="newTag"
                />
              </v-col>
              <v-col>
                <v-chip-group>
                  <v-chip
                    v-for="tag in workOrder.tags"
                    :key="tag.id"
                    :color="tag.color"
                    :text-color="tag.textColor"
                    close-icon="mdi-close"
                    close
                    @click:close="removeTag(tag.id)"
                  >
                    {{ tag.value }}
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h2>
              Customer Information
              <v-icon :color="customerInformationSaved ? 'green' : 'red'" dense>
                mdi-content-save-check
              </v-icon>
            </h2>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ReusableForm
              :fields="customerInformationFields"
              :item="workOrder.customerInformation"
              @blur="updateCustomerInformation"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h2>
              Payment Information
              <v-icon :color="paymentInformationSaved ? 'green' : 'red'" dense>
                mdi-content-save-check
              </v-icon>
            </h2>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ReusableForm
              :fields="paymentInformationFields"
              :item="workOrder.paymentInformation"
              @blur="updatePaymentInformation"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h2>
              Vehicle Information
              <v-icon :color="vehicleInformationSaved ? 'green' : 'red'" dense>
                mdi-content-save-check
              </v-icon>
            </h2>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ReusableForm
              :fields="vehicleInformationFields"
              :item="workOrder.vehicleInformation"
              @blur="updateVehicleInformation"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h2>
              Service Information
              <v-icon :color="glassInformationSaved ? 'green' : 'red'" dense>
                mdi-content-save-check
              </v-icon>
            </h2>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ReusableForm
              :fields="glassInformationFields"
              :item="workOrder.glassInformation"
              @blur="updateGlassInformation"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h2>
              Appointment Information
              <v-icon :color="appointmentInformationSaved ? 'green' : 'red'" dense>
                mdi-content-save-check
              </v-icon>
            </h2>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ReusableForm
              :fields="appointmentInformationFields"
              :item="workOrder.appointmentInformation"
              @blur="updateAppointmentInformation"
            />

            <v-row>
              <v-btn
                color="primary"
                @click="openTextMessageDialog"
                :disabled="
                  workOrder.appointmentInformation.installDate === null ||
                  workOrder.appointmentInformation.phoneNumber === ''
                "
                text
                >Send appointment reminder</v-btn
              >
              <v-spacer />
              <v-btn
                color="error"
                @click="showCancellationDialog = true"
                :disabled="workOrder.appointmentInformation.installDate === null"
                text
                >Cancel Work Order</v-btn
              >
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h2>Signature</h2>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card :elevation="0">
              <v-card-subtitle><span v-html="signatureText"></span> </v-card-subtitle>
              <v-card-text>
                <VueSignaturePad
                  width="100%"
                  height="300px"
                  ref="signaturePad"
                  v-if="!workOrder.hasSignature"
                  class="signature-outline"
                />
                <img class="signature-outline" v-else :src="workOrderSignature" alt="signature" />
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn text @click="clearSignature" :disabled="workOrder.hasSignature">
                  Reset
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="saveSignature"
                  :disabled="workOrder.hasSignature"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          v-if="workOrder.workOrderStatus === 'Draft' && workOrder.externalId === null"
          @click="publishWorkOrder"
          color="primary"
          :loading="loading"
          :disabled="disablePublishButton"
        >
          Publish Work Order
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-skeleton-loader v-else type="article" />
    <v-dialog
      :fullscreen="$vuetify.breakpoint.mobile"
      :value="true"
      max-width="600px"
      persistent
      v-model="showCancellationDialog"
    >
      <v-card>
        <v-card-title> Cancel Work Order </v-card-title>
        <v-card-text>
          <v-textarea v-model="cancelReason" solo label="Please supply a cancellation reason" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="red" text @click="showCancellationDialog = !showCancellationDialog">
            Cancel
          </v-btn>
          <v-btn color="primary" text @click="cancelWorkOrder"> Yes </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.mobile"
      :value="true"
      max-width="600px"
      persistent
      v-model="showTextMessageDialog"
    >
      <v-card>
        <v-card-title> Text Customer </v-card-title>
        <v-card-text>
          <v-textarea v-model="textMessage" solo label="Enter Text Message To Send" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="red" text @click="showTextMessageDialog = !showTextMessageDialog">
            Cancel
          </v-btn>
          <v-btn color="primary" text @click="sendTextMessage"> Yes </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.mobile"
      :value="true"
      max-width="600px"
      v-model="showMissingFields"
      scrollable
      v-if="workOrder"
    >
      <v-card>
        <v-card-title> Missing Fields </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item v-for="(field, index) in this.workOrder.missingFields" :key="index">
              {{ field }}
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="showMissingFields = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import ReusableForm from '@/App/@Components/ReusableForm';
  import services from '@/@libs/services';
  import apiEndpoints from '@/@enums/apiEndpoints';

  export default {
    name: 'WorkOrderDetails',
    components: { ReusableForm },
    data() {
      return {
        disablePublishButton: false,
        signatureText: '',
        overrideValidationChecks: false,
        showMissingFields: false,
        allUsers: [],
        companies: [],
        newTag: null,
        tags: [],
        textMessage: null,
        showTextMessageDialog: false,
        cancelReason: null,
        showCancellationDialog: false,
        workOrderSignature: null,
        panels: [0, 1, 2, 3, 4, 5, 6],
        workOrder: null,
        originalWorkOrder: null,
        loading: false,
        editFields: [],
        showEditDialog: false,
        editTitle: '',
        editingItem: null,
        vehicleInformationFields: [
          {
            type: 'text',
            label: 'Year',
            value: 'year',
            cols: 4,
          },
          {
            type: 'text',
            label: 'Make',
            value: 'make',
            cols: 4,
          },
          {
            type: 'text',
            label: 'Model',
            value: 'model',
            cols: 4,
          },
          {
            type: 'text',
            label: 'Vin',
            value: 'vin',
            cols: 8,
          },
          {
            type: 'text',
            label: 'Color',
            value: 'color',
            cols: 4,
          },
        ],
        glassInformationFields: [
          {
            type: 'select',
            label: 'Service Type',
            value: 'replaceOrRepair',
            cols: 4,
            items: ['Replace', 'Repair', 'Calibration'],
          },
          {
            type: 'select',
            label: 'Location',
            value: 'glassLocation',
            cols: 4,
            items: [
              { text: 'Front Window', value: 'FrontWindow' },
              { text: 'Rear Window', value: 'RearWindow' },
            ],
          },
          {
            type: 'select',
            label: 'Tint Shade',
            value: 'tintShade',
            cols: 4,
            items: services.enumService.arrayEnums.tintShade,
          },
          {
            type: 'check',
            label: 'Ant',
            value: 'ant',
            cols: 2,
          },
          {
            type: 'check',
            label: 'Cnd',
            value: 'cnd',
            cols: 2,
          },
          {
            type: 'check',
            label: 'Elect',
            value: 'elect',
            cols: 2,
          },
          {
            type: 'check',
            label: 'Htd',
            value: 'htd',
            cols: 2,
          },
          {
            type: 'check',
            label: 'Hud',
            value: 'hud',
            cols: 2,
          },
          {
            type: 'check',
            label: 'ADAS',
            value: 'ldw',
            cols: 2,
          },
          {
            type: 'check',
            label: 'Pano',
            value: 'pano',
            cols: 2,
          },
          {
            type: 'check',
            label: 'Rs',
            value: 'rs',
            cols: 2,
          },
          {
            type: 'check',
            label: 'Srf',
            value: 'srf',
            cols: 2,
          },
          {
            type: 'check',
            label: 'Sun Pass',
            value: 'sunPass',
            cols: 2,
          },
          {
            type: 'check',
            label: 'Third Visor',
            value: 'thirdVisor',
            cols: 2,
          },
          {
            type: 'check',
            label: 'Vin',
            value: 'vin',
            cols: 2,
          },
        ],
      };
    },
    computed: {
      workOrderStatuses() {
        return services.enumService.arrayEnums.workOrderStatus;
      },
      technicians() {
        return this.allUsers
          .filter((a) => a.appointmentTimes.length > 0)
          .map((a) => ({ text: a.name, value: a.id }));
      },
      filteredTags() {
        return this.tags.filter((tag) => !this.workOrder.tags.some((wTag) => wTag.id === tag.id));
      },
      tagIds() {
        return this.workOrder.tags?.map((a) => a.id) ?? [];
      },
      groupId() {
        return this.$route.params.groupId;
      },
      workOrderId() {
        return this.$route.params.workOrderId;
      },
      workOrderSaved() {
        return (
          this.customerInformationSaved &&
          this.vehicleInformationSaved &&
          this.paymentInformationSaved &&
          this.glassInformationSaved &&
          this.appointmentInformationSaved
        );
      },
      appointmentInformationSaved() {
        return (
          JSON.stringify(this.workOrder.appointmentInformation) ===
          JSON.stringify(this.originalWorkOrder.appointmentInformation)
        );
      },
      customerInformationSaved() {
        return (
          JSON.stringify(this.workOrder.customerInformation) ===
          JSON.stringify(this.originalWorkOrder.customerInformation)
        );
      },
      vehicleInformationSaved() {
        return (
          JSON.stringify(this.workOrder.vehicleInformation) ===
          JSON.stringify(this.originalWorkOrder.vehicleInformation)
        );
      },
      paymentInformationSaved() {
        return (
          JSON.stringify(this.workOrder.paymentInformation) ===
          JSON.stringify(this.originalWorkOrder.paymentInformation)
        );
      },
      glassInformationSaved() {
        return (
          JSON.stringify(this.workOrder.glassInformation) ===
          JSON.stringify(this.originalWorkOrder.glassInformation)
        );
      },
      customerInformationFields() {
        return [
          {
            type: 'text',
            label: 'First Name',
            value: 'firstName',
            cols: 6,
          },
          {
            type: 'text',
            label: 'Last Name',
            value: 'lastName',
            cols: 6,
          },
          {
            type: 'text',
            label: 'Email Address',
            value: 'emailAddress',
            cols: 12,
          },
          {
            type: 'masked-text',
            label: 'Phone Number',
            value: 'phoneNumber',
            mask: (value) => `${value?.replace(/(\d{3})(\d{3})(\d{4})/g, '$1-$2-$3') ?? ''}`,
            cols: 9,
            prependIcon: 'mdi-phone',
          },
          {
            type: 'check',
            label: 'Do Not Text',
            value: 'optOutOfTextMessages',
            cols: 3,
          },
          {
            type: 'auto-complete-address',
            label: 'Address 1',
            value: 'streetLine1',
            cols: 12,
            url: apiEndpoints.workOrders.autoCompleteAddress
              .replace('{workOrderId}', this.workOrderId)
              .replace('{groupId}', this.groupId)
              .replace('{session}', `${this.workOrderId}-${this.workOrder.modified}`),
            placeSelected: this.updateCustomerInformationAddress,
            key: this.workOrder.modified,
          },
          {
            type: 'text',
            label: 'Address 2',
            value: 'streetLine2',
            cols: 12,
          },
          {
            type: 'text',
            label: 'City',
            value: 'city',
            cols: 4,
          },
          {
            type: 'text',
            label: 'State',
            value: 'state',
            cols: 4,
          },
          {
            type: 'text',
            label: 'Zip Code',
            value: 'zipCode',
            cols: 4,
          },
          {
            type: 'text',
            label: 'DL Number',
            value: 'driversLicenseNumber',
            cols: 4,
          },
          {
            type: 'text',
            label: 'DL State',
            value: 'driversLicenseState',
            cols: 4,
          },
          {
            type: 'date',
            label: 'D.O.B.',
            value: 'dateOfBirth',
            cols: 4,
          },
        ];
      },
      paymentInformationFields() {
        return [
          {
            type: 'select',
            label: 'Payment Type',
            value: 'paymentType',
            cols: 4,
            items: ['Insurance', 'Cash', 'Credit', 'Net30', 'Warranty'],
          },
          {
            type: 'date',
            label: 'Date of Loss',
            value: 'dateOfLoss',
            cols: 4,
          },
          {
            type: 'combo-box',
            items: this.companies,
            itemText: 'value',
            itemValue: 'value',
            label: 'Bill To',
            value: 'billTo',
            cols: 4,
            disabled:
              this.workOrder.paymentInformation.paymentType === 'Insurance' ||
              this.workOrder.paymentInformation.paymentType === 'Cash',
          },
          this.workOrder.paymentInformation.paymentType === 'Insurance' && {
            type: 'combo-box',
            label: 'Insurance Company',
            value: 'insuranceCompany',
            items: this.companies,
            itemText: 'value',
            itemValue: 'value',
            cols: 6,
          },
          this.workOrder.paymentInformation.paymentType === 'Insurance' && {
            type: 'masked-text',
            label: 'Insurance Phone',
            value: 'insurancePhone',
            cols: 6,
            prependIcon: 'mdi-phone',
            mask: (value) => `${value?.replace(/(\d{3})(\d{3})(\d{4})/g, '$1-$2-$3') ?? ''}`,
          },
          this.workOrder.paymentInformation.paymentType === 'Insurance' && {
            type: 'text',
            label: 'Insurance Policy Number',
            value: 'insurancePolicyNumber',
            cols: 6,
          },
          this.workOrder.paymentInformation.paymentType === 'Insurance' && {
            type: 'text',
            label: 'Insurance Reference Number',
            value: 'insuranceReferenceNumber',
            cols: 6,
          },
          this.workOrder.paymentInformation.paymentType === 'Insurance' && {
            type: 'text',
            label: 'Insurance Agent',
            value: 'insuranceAgent',
            cols: 6,
          },
          this.workOrder.paymentInformation.paymentType === 'Insurance' && {
            type: 'masked-text',
            label: 'Insurance Agent Phone',
            value: 'insuranceAgentPhone',
            cols: 6,
            prependIcon: 'mdi-phone',
            mask: (value) => `${value?.replace(/(\d{3})(\d{3})(\d{4})/g, '$1-$2-$3') ?? ''}`,
          },
          this.workOrder.paymentInformation.paymentType === 'Insurance' && {
            type: 'text',
            label: 'Deductible',
            value: 'deductible',
            cols: 6,
          },
          this.workOrder.paymentInformation.paymentType === 'Insurance' && {
            type: 'check',
            label: 'Coverage Verified',
            value: 'coverageVerified',
            cols: 6,
          },
        ].filter((field) => field.label);
      },
      appointmentInformationFields() {
        return [
          {
            type: 'check',
            label: 'Same as above',
            value: 'copyFromCustomerInformation',
            cols: 12,
          },
          {
            type: 'schedule',
            label: 'Scheduled Date',
            value: 'installDate',
            cols: 6,
            clearable: true,
          },
          {
            type: 'select',
            label: 'Arrival Time',
            value: 'arrivalTime',
            items: ['Afternoon', 'Morning'],
            cols: 6,
          },
          {
            type: 'text',
            label: "Add'l Authorized Signature",
            value: 'additionalAuthorizedSignature',
            cols: 12,
          },
          {
            type: 'masked-text',
            label: 'Phone Number',
            value: 'phoneNumber',
            mask: (value) => `${value?.replace(/(\d{3})(\d{3})(\d{4})/g, '$1-$2-$3') ?? ''}`,
            cols: 9,
            prependIcon: 'mdi-phone',
            disabled: this.workOrder.appointmentInformation.copyFromCustomerInformation,
          },
          {
            type: 'check',
            label: 'Do Not Text',
            value: 'optOutOfTextMessages',
            cols: 3,
            disabled: this.workOrder.appointmentInformation.copyFromCustomerInformation,
          },
          {
            type: 'auto-complete-address',
            label: 'Address',
            value: 'streetLine1',
            cols: 12,
            url: apiEndpoints.workOrders.autoCompleteAddress
              .replace('{workOrderId}', this.workOrderId)
              .replace('{groupId}', this.groupId)
              .replace('{session}', `${this.workOrderId}-${this.workOrder.modified}`),
            placeSelected: this.updateAppointmentInformationAddress,
            key: this.workOrder.modified,
            disabled: this.workOrder.appointmentInformation.copyFromCustomerInformation,
          },
          {
            type: 'text',
            label: 'City',
            value: 'city',
            cols: 4,
            disabled: this.workOrder.appointmentInformation.copyFromCustomerInformation,
          },
          {
            type: 'text',
            label: 'State',
            value: 'state',
            cols: 4,
            disabled: this.workOrder.appointmentInformation.copyFromCustomerInformation,
          },
          {
            type: 'text',
            label: 'Zip Code',
            value: 'zipCode',
            cols: 4,
            disabled: this.workOrder.appointmentInformation.copyFromCustomerInformation,
          },
          this.technicians.length > 0 && {
            type: 'select',
            label: 'Assigned Technician',
            value: 'technicianId',
            items: this.technicians,
            cols: 12,
            clearable: true,
          },
        ];
      },
      allowOverride() {
        return this.hasPermission('OverrideWorkOrderValidations');
      },
    },
    watch: {
      workOrder: {
        handler(newValue) {
          this.originalWorkOrder = JSON.parse(JSON.stringify(newValue));
        },
      },
    },
    methods: {
      async addTag(tagId) {
        try {
          this.loading = true;
          const workOrder = await services.workOrderService.addTag(
            this.groupId,
            this.workOrderId,
            tagId,
          );
          this.workOrder = workOrder;
        } finally {
          this.loading = false;
        }

        this.newTag = null;
      },
      async removeTag(tagId) {
        try {
          this.loading = true;
          await services.workOrderService.removeTag(this.groupId, this.workOrderId, tagId);
          await this.loadData();
        } finally {
          this.loading = false;
        }
      },
      openTextMessageDialog() {
        this.textMessage = `This is a friendly reminder you have a windshield install appointment scheduled for ${new Date(
          this.workOrder.appointmentInformation.installDate,
        ).toLocaleDateString()}`;
        this.showTextMessageDialog = true;
      },
      async sendTextMessage() {
        try {
          this.loading = true;
          await services.workOrderService.sendTextMessage(
            this.groupId,
            this.workOrderId,
            this.textMessage,
          );
          this.showTextMessageDialog = false;
          this.textMessage = null;
        } finally {
          this.loading = false;
          await this.loadData();
        }
      },
      async cancelWorkOrder() {
        try {
          this.loading = true;
          await services.workOrderService.cancelWorkOrder(
            this.groupId,
            this.workOrderId,
            this.cancelReason,
          );
          this.showCancellationDialog = false;
          this.cancelReason = null;
        } finally {
          this.loading = false;
          await this.loadData();
        }
      },
      async saveWorkOrderStatus(newStatus) {
        try {
          this.loading = true;
          await services.workOrderService.setWorkOrderStatus(
            this.groupId,
            this.workOrderId,
            newStatus,
            this.overrideValidationChecks,
          );
          this.overrideValidationChecks = false;
        } finally {
          this.loading = false;
          await this.loadData();
        }
      },
      async publishWorkOrder() {
        try {
          this.loading = true;
          await services.workOrderService.publishWorkOrder(
            this.groupId,
            this.workOrderId,
          );
          this.disablePublishButton = true;
          this.overrideValidationChecks = false;
        } finally {
          await this.loadData();
        }
      },
      clearSignature() {
        this.$refs.signaturePad.clearSignature();
      },
      async saveSignature() {
        const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

        if (isEmpty) return;

        await services.workOrderService.addWorkOrderSignature(this.groupId, this.workOrderId, data);
        await this.loadData();
      },
      async updateCustomerInformationAddress(placeId) {
        const address = await services.workOrderService.getPlaceDetails(
          this.groupId,
          this.workOrderId,
          placeId,
          `${this.workOrderId}-${this.workOrder.modified}`,
        );
        this.workOrder.customerInformation.streetLine1 = address.streetLine1;
        this.workOrder.customerInformation.city = address.city;
        this.workOrder.customerInformation.state = address.state;
        this.workOrder.customerInformation.zipCode = address.zipCode;
        this.workOrder.customerInformation.lat = address.lat;
        this.workOrder.customerInformation.lng = address.lng;
        this.workOrder.customerInformation.googleMapUrl = address.googleMapUrl;
        await this.updateCustomerInformation(true);
      },
      async updateAppointmentInformationAddress(placeId) {
        try {
          const address = await services.workOrderService.getPlaceDetails(
            this.groupId,
            this.workOrderId,
            placeId,
            `${this.workOrderId}-${this.workOrder.modified}`,
          );
          this.workOrder.appointmentInformation.streetLine1 = address.streetLine1;
          this.workOrder.appointmentInformation.city = address.city;
          this.workOrder.appointmentInformation.state = address.state;
          this.workOrder.appointmentInformation.zipCode = address.zipCode;
          this.workOrder.appointmentInformation.lat = address.lat;
          this.workOrder.appointmentInformation.lng = address.lng;
          this.workOrder.appointmentInformation.googleMapUrl = address.googleMapUrl;
          await this.updateAppointmentInformation(true);
        } finally {
          // throw away
        }
      },
      async updateAppointmentInformation(override) {
        const { technicianId } = this.workOrder.appointmentInformation;

        if (this.workOrderSaved && !override) return;

        try {
          this.loading = true;

          let response = await services.workOrderService.updateAppointmentInformation(
            this.groupId,
            this.workOrderId,
            this.workOrder.appointmentInformation,
          );

          this.workOrder = response;

          if (technicianId === this.workOrder.appointmentInformation.technicianId) return;

          if (technicianId) {
            response = await services.workOrderService.updateTechnician(
              this.groupId,
              this.workOrderId,
              technicianId,
            );
          } else {
            response = await services.workOrderService.removeTechnician(
              this.groupId,
              this.workOrderId,
            );
          }

          this.workOrder = response;
        } finally {
          // throw away
          this.loading = false;
        }
      },
      async updateCustomerInformation(override) {
        if (this.workOrderSaved && !override) return;

        this.loading = true;
        const response = await services.workOrderService.updateCustomerInformation(
          this.groupId,
          this.workOrderId,
          this.workOrder.customerInformation,
        );
        this.loading = false;

        this.workOrder = response;
      },
      async updateVehicleInformation() {
        if (this.workOrderSaved) return;

        this.loading = true;
        const response = await services.workOrderService.updateVehicleInformation(
          this.groupId,
          this.workOrderId,
          this.workOrder.vehicleInformation,
        );
        this.loading = false;

        this.workOrder = response;
      },
      async updatePaymentInformation() {
        if (this.workOrderSaved) return;

        this.loading = true;
        const response = await services.workOrderService.updatePaymentInformation(
          this.groupId,
          this.workOrderId,
          this.workOrder.paymentInformation,
        );
        this.loading = false;

        this.workOrder = response;
      },
      async updateGlassInformation() {
        if (this.workOrderSaved) return;

        this.loading = true;
        const response = await services.workOrderService.updateGlassInformation(
          this.groupId,
          this.workOrderId,
          this.workOrder.glassInformation,
        );
        this.loading = false;

        this.workOrder = response;
      },
      hasPermission(permission, requiresRootPermission) {
        const { permissions, groupPermissions } = this.$store.getters;

        const { groupId } = this.$route.params;

        if (permission === undefined) return true;

        return (
          permissions.some((a) => a === 'All') ||
          groupPermissions(groupId).some((a) => a === 'All' && !requiresRootPermission) ||
          permissions.some((a) => a === permission) ||
          groupPermissions(groupId).some((a) => a === permission && !requiresRootPermission)
        );
      },
      async loadData() {
        this.loading = true;

        const [workOrder, signature, tags, allUsers, allCompanies, signatureText] =
          await Promise.all([
            services.workOrderService.getWorkOrderDetails(this.groupId, this.workOrderId),
            services.workOrderService.getWorkOrderSignature(this.groupId, this.workOrderId),
            services.tagService.getGroupTags(this.groupId, false),
            this.hasPermission('ListGroupUsers')
              ? services.groupService.getGroupUsers(this.groupId)
              : [],
            services.companyService.getGroupCompanies(this.groupId),
            services.groupSettingsService.getTemplateValue(this.groupId, 'SignatureLegalText'),
          ]);

        this.workOrder = workOrder;
        this.workOrderSignature = signature;
        this.tags = tags;
        this.allUsers = allUsers;
        this.companies = allCompanies;
        this.signatureText = signatureText;

        this.loading = false;
      },
    },
    async created() {
      await this.loadData();
    },
  };
</script>

<style scoped>
  .signature-outline {
    border: 1px solid black;
    width: 100%;
    height: 300px;
    object-fit: contain;
  }
</style>
