<template>
  <v-timeline :dense="$vuetify.breakpoint.mobile">
    <v-timeline-item v-for="(items, key, index) in groupedHistory" :key="index" fill-dot>
      <v-card>
        <v-card-title>{{ key }}</v-card-title>
        <v-card-text>
          <ul>
            <li v-for="(item, i) in items" :key="i">
              <span v-for="(change, ix) in item.changes" :key="ix">
                {{ change }} - {{ getChangeDate(item) }}
              </span>
            </li>
          </ul>
        </v-card-text>
      </v-card>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
  import _groupBy from 'lodash/groupBy';
  import moment from 'moment-timezone';
  import services from '@/@libs/services';

  export default {
    name: 'WorkOrderHistory',
    data() {
      return {
        history: [],
      };
    },
    computed: {
      groupId() {
        return this.$route.params.groupId;
      },
      workOrderId() {
        return this.$route.params.workOrderId;
      },
      groupedHistory() {
        const groups = _groupBy(this.history, (item) => moment(item.created).calendar());

        return groups;
      },
    },
    methods: {
      async loadData() {
        const history = await services.entityHistoryService.getEntityHistory(
          this.groupId,
          this.workOrderId,
        );

        this.history = history;
      },
      getChangeDate(item) {
        return moment(item.created).format('h:mm.ss a');
      },
    },
    async created() {
      await this.loadData();
    },
  };
</script>

<style scoped></style>
