import baseService from '@/@libs/services/baseService';
import httpClient from '@/@libs/httpClient';

export default {
  ...baseService,
  async getGroupCompanies(groupId) {
    const response = await httpClient.get(this.apiEndpoints.companies.getGroupCompanies, {
      replace: {
        groupId,
      },
    });

    return response;
  },
  async syncWithOmegaCompanies(groupId) {
    const response = await httpClient.post(this.apiEndpoints.companies.syncWithOmega, {
      replace: {
        groupId,
      },
    });

    return response;
  },
  async deleteCompany(groupId, companyId) {
    const response = await httpClient.delete(this.apiEndpoints.companies.deleteCompany, {
      replace: {
        groupId,
        companyId,
      },
    });

    return response;
  },
  async updateCompany(groupId, companyId, companyData) {
    const response = await httpClient.put(this.apiEndpoints.companies.updateCompany, {
      replace: {
        groupId,
        companyId,
      },
      body: companyData,
    });

    return response;
  },
  async addCompany(groupId, companyData) {
    const response = await httpClient.post(this.apiEndpoints.companies.addCompany, {
      replace: {
        groupId,
      },
      body: companyData,
    });

    return response;
  },
};
