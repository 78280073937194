<template>
  <v-app>
    <v-main>
      <slot />
    </v-main>
  </v-app>
</template>

<script>
  export default {
    name: 'Default',
    components: {},
    data() {
      return {
        drawer: false,
      };
    },
  };
</script>

<style lang="scss"></style>
