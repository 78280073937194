<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <DataGrid
          id="bulletins"
          :headers="headers"
          :items="companies"
          :show-add="true"
          title="All Companies"
          @add="addCompany"
          :loading="loading"
        >
          <template v-slot:item.created="{ item: { created } }">
            <span style="white-space: nowrap">
              {{ new Date(created).toLocaleString() }}
            </span>
          </template>
          <template v-slot:item.modified="{ item: { modified } }">
            <span style="white-space: nowrap">
              {{ new Date(modified).toLocaleString() }}
            </span>
          </template>

          <template v-slot:item.actions="{ item }">
            <span style="white-space: nowrap">
              <v-icon @click="showEdit(item)"> mdi-pencil </v-icon>
              <v-icon v-if="!item.deleted" @click="showConfirm(item)"> mdi-delete </v-icon>
              <v-icon v-else @click="showConfirm(item)"> mdi-restore </v-icon>
            </span>
          </template>
        </DataGrid>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card :elevation="0">
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" @click="syncWithOmegaCompanies" :loading="loading">
              Sync Omega Companies
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <Confirmation
      :confirm="confirmSelectedCompany"
      :show="showConfirmDialog"
      :max-width="600"
      :cancel="() => (this.showConfirmDialog = false)"
    >
      Are you sure you want to DELETE Tag {{ selectedCompany.name }}?
    </Confirmation>

    <v-dialog
      :value="showDialog"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
      :max-width="600"
    >
      <v-card>
        <v-card-title>{{ dialogTitle }}</v-card-title>
        <v-card-text>
          <ReusableForm :fields="companyFields" :item="editCompany" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="showDialog = false">Cancel</v-btn>
          <v-btn text color="primary" @click="saveCompany"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import DataGrid from '@/App/@Components/DataGrid';
  import services from '@/@libs/services';
  import Confirmation from '@/App/@Components/Confirmation';
  import ReusableForm from '@/App/@Components/ReusableForm';

  export default {
    name: 'ManageCompanies',
    components: { ReusableForm, Confirmation, DataGrid },
    data() {
      return {
        loading: false,
        selectedCompany: {},
        editCompany: {},
        companies: [],
        showDialog: false,
        showConfirmDialog: false,
        companyFields: [
          {
            id: 'name',
            type: 'text',
            label: 'Name',
            value: 'name',
            cols: 12,
          },
          {
            id: 'phoneNumber',
            type: 'text',
            label: 'Phone Number',
            value: 'phoneNumber',
            cols: 12,
          },
          {
            id: 'externalId',
            type: 'text',
            label: 'External Id',
            value: 'externalId',
            readonly: true,
            cols: 12,
          },
        ],
        headers: [
          {
            id: 'name',
            text: 'Name',
            value: 'name',
            default: true,
            searchable: true,
            groupable: false,
          },
          {
            id: 'phoneNumber',
            text: 'Phone Number',
            value: 'phoneNumber',
            default: true,
            searchable: true,
            groupable: false,
            sortable: false,
          },
          {
            id: 'created',
            text: 'Created',
            value: 'created',
            default: false,
            groupable: false,
            searchable: false,
          },
          {
            id: 'modified',
            text: 'Modified',
            value: 'modified',
            default: false,
            groupable: false,
            searchable: false,
          },
          {
            id: 'externalId',
            text: 'External Id',
            value: 'externalId',
            default: false,
            groupable: false,
            searchable: false,
          },
          {
            id: 'actions',
            text: 'Actions',
            value: 'actions',
            sortable: false,
            groupable: false,
            default: true,
            align: 'center',
          },
        ],
      };
    },
    computed: {
      groupId() {
        return this.$route.params.groupId;
      },
      dialogTitle() {
        return this.editCompany.id ? 'Edit Company' : 'Add Company';
      },
    },
    methods: {
      addCompany() {
        this.editCompany = {};
        this.showDialog = true;
      },
      async saveCompany() {
        const { id } = this.editCompany;

        try {
          this.loading = true;

          if (id) {
            await services.companyService.updateCompany(this.groupId, id, this.editCompany);
          } else {
            await services.companyService.addCompany(this.groupId, this.editCompany);
          }

          this.editCompany = {};
          this.showDialog = false;
        } finally {
          this.loading = false;
        }

        await this.loadData();
      },
      async confirmSelectedCompany() {
        const { id } = this.selectedCompany;

        try {
          this.loading = true;
          await services.companyService.deleteCompany(this.groupId, id);
        } finally {
          this.loading = false;
        }

        await this.loadData();
        this.showConfirmDialog = false;
      },
      showConfirm(selectedCompany) {
        this.selectedCompany = selectedCompany;
        this.showConfirmDialog = true;
      },
      showEdit(selectedCompany) {
        this.editCompany = JSON.parse(JSON.stringify(selectedCompany));
        this.showDialog = true;
      },
      async syncWithOmegaCompanies() {
        try {
          this.loading = true;
          await services.companyService.syncWithOmegaCompanies(this.groupId);
        } finally {
          this.loading = false;
        }

        await this.loadData();
      },
      async loadData() {
        const companies = await services.companyService.getGroupCompanies(this.groupId);

        this.companies = companies;
      },
    },
    async created() {
      await this.loadData();
    },
  };
</script>

<style scoped></style>
