<template>
  <iframe class="mondays-view-frame"
    src="https://forms.monday.com/forms/embed/873a735113fd377dceac2fb66475daa5?r=use1"
  ></iframe>
</template>

<script>
  export default {
    name: 'BugReport',
  };
</script>

<style scoped>
.mondays-view-frame {
  border: 0;
  box-shadow: 5px 5px 56px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
  height: calc(100vh - 94px);
}
</style>
