<template>
  <v-container fluid>
    <v-row>
      <v-col :cols="12">
        <v-expansion-panels v-model="panels">
          <v-expansion-panel>
            <v-expansion-panel-header><h2>Add Note</h2></v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-textarea v-model="newNote" :rows="5" solo />
            </v-expansion-panel-content>
            <v-card-actions v-if="panels === 0">
              <v-spacer />
              <v-btn text color="primary" @click="clearNote"> Cancel </v-btn>
              <v-btn color="primary" @click="saveNote"> Save </v-btn>
            </v-card-actions>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row>
      <v-col :cols="12">
        <v-card>
          <v-card-title>Notes</v-card-title>
          <v-card-text>
            <v-timeline small fill-dot dense>
              <v-timeline-item v-for="note in notes" :key="note.id" small fill-dot>
                <v-card>
                  <v-card-title>{{ calendarDate(note.created) }}</v-card-title>
                  <v-card-text v-html="note.noteText" />
                  <v-card-actions>
                    <v-spacer />
                    <v-icon @click="() => showConfirmDeleteNote(note.id)">mdi-delete</v-icon>
                  </v-card-actions>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <Confirmation :confirm="deleteNote" :max-width="600" :show.sync="showConfirmDelete">
      Are you sure you want to DELETE this note
    </Confirmation>
  </v-container>
</template>

<script>
  import moment from 'moment-timezone';
  import services from '@/@libs/services';
  import Confirmation from '@/App/@Components/Confirmation';

  export default {
    name: 'WorkOrderNotes',
    components: { Confirmation },
    data() {
      return {
        notes: [],
        newNote: 'New Note',
        panels: undefined,
        showConfirmDelete: false,
        selectedNoteId: null,
      };
    },
    computed: {
      groupId() {
        return this.$route.params.groupId;
      },
      workOrderId() {
        return this.$route.params.workOrderId;
      },
    },
    methods: {
      async loadData() {
        const notes = await services.workOrderService.getWorkOrderNotes(
          this.groupId,
          this.workOrderId,
        );

        this.notes = notes;
      },
      clearNote() {
        this.newNote = '<p>New Note</p>';
        this.panels = undefined;
      },
      async saveNote() {
        await services.workOrderService.addWorkOrderNote(
          this.groupId,
          this.workOrderId,
          this.newNote,
        );

        this.clearNote();
        await this.loadData();
      },
      async deleteNote() {
        await services.workOrderService.removeWorkOrderNote(
          this.groupId,
          this.workOrderId,
          this.selectedNoteId,
        );
        this.showConfirmDelete = false;
        await this.loadData();
      },
      calendarDate(date) {
        return moment(date).format('MM/DD/yyyy h:mm a');
      },
      showConfirmDeleteNote(noteId) {
        this.showConfirmDelete = true;
        this.selectedNoteId = noteId;
      },
    },
    async created() {
      await this.loadData();
    },
  };
</script>

<style scoped></style>
