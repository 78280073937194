import baseService from '@/@libs/services/baseService';
import httpClient from '@/@libs/httpClient';

export default {
  ...baseService,
  async getExceptionLogs() {
    const response = await httpClient.get(this.apiEndpoints.exceptionLogs.listExceptionLogs, { });

    return response;
  },
};
