var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('DataGrid',{attrs:{"id":"bulletins","custom-grid-settings-fields":_vm.customGridSettingsFields,"gridSettings":_vm.gridSettings,"headers":_vm.headers,"items":_vm.tags,"show-add":true,"title":_vm.gridTitle,"loading":_vm.loading},on:{"update:gridSettings":function($event){_vm.gridSettings=$event},"update:grid-settings":function($event){_vm.gridSettings=$event},"add":_vm.addTag},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var created = ref.item.created;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(new Date(created).toLocaleString())+" ")])]}},{key:"item.modified",fn:function(ref){
var modified = ref.item.modified;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(new Date(modified).toLocaleString())+" ")])]}},{key:"item.deleted",fn:function(ref){
var deleted = ref.item.deleted;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[(!deleted)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e()],1)]}},{key:"item.color",fn:function(ref){
var ref_item = ref.item;
var color = ref_item.color;
var textColor = ref_item.textColor;
var value = ref_item.value;
return [_c('v-chip',{attrs:{"color":color,"text-color":textColor}},[_vm._v(_vm._s(value))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_c('v-icon',{on:{"click":function($event){return _vm.showEdit(item)}}},[_vm._v(" mdi-pencil ")]),(!item.deleted)?_c('v-icon',{on:{"click":function($event){return _vm.showConfirm(item)}}},[_vm._v(" mdi-delete ")]):_c('v-icon',{on:{"click":function($event){return _vm.showConfirm(item)}}},[_vm._v(" mdi-restore ")])],1)]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"elevation":0}},[_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading},on:{"click":_vm.syncWithOmegaTags}},[_vm._v(" Sync Omega Tags ")])],1)],1)],1)],1),_c('Confirmation',{attrs:{"confirm":_vm.confirmSelectedTag,"show":_vm.showConfirmDialog,"max-width":600,"cancel":function () { return (this$1.showConfirmDialog = false); }}},[_vm._v(" Are you sure you want to "+_vm._s(_vm.operation)+" Tag "+_vm._s(_vm.selectedTag.value)+"? ")]),_c('v-dialog',{attrs:{"value":_vm.showDialog,"persistent":"","fullscreen":_vm.$vuetify.breakpoint.mobile,"max-width":600}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.dialogTitle))]),_c('v-card-text',[_c('ReusableForm',{attrs:{"fields":_vm.tagFields,"item":_vm.editTag}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.saveTag}},[_vm._v(" Save ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }