import config from '../../config';

const rootUrl = config.apiPath;
const hangfireRootUrl = config.hangfirePath;

export default {
  attachments: {
    downloadAttachment: `${rootUrl}/{groupId}/attachments/{attachmentId}?token={token}`,
  },
  userInfos: {
    login: `${rootUrl}/login?rememberMe={rememberMe}`,
    loginWithRefreshToken: `${rootUrl}/refresh?rememberMe={rememberMe}`,
    passwordReset: `${rootUrl}/user-infos/password-reset?emailAddress={emailAddress}`,
    getAttachmentToken: `${rootUrl}/{groupId}/attachment-token`,
    updateProfile: `${rootUrl}/user-infos/{userInfoId}`,
  },
  exceptionLogs: {
    listExceptionLogs: `${rootUrl}/exception-logs`,
  },
  groups: {
    listCurrentUserGroups: `${rootUrl}/groups/for-current-user-id/`,
    listGroupUsers: `${rootUrl}/{groupId}/users`,
    addUserToGroup: `${rootUrl}/{groupId}/users?emailAddress={emailAddress}`,
    updatePermissions: `${rootUrl}/{groupId}/user-infos/{userId}/permissions`,
    updateGroupDetails: `${rootUrl}/{groupId}`,
  },
  workOrders: {
    postNew: `${rootUrl}/{groupId}/work-orders`,
    listActive: `${rootUrl}/{groupId}/work-orders`,
    listArchived: `${rootUrl}/{groupId}/work-orders/archived`,
    getWorkOrderDetails: `${rootUrl}/{groupId}/work-orders/{workOrderId}`,
    getWorkOrderSignature: `${rootUrl}/{groupId}/work-orders/{workOrderId}/signature`,
    updateAppointmentInformation: `${rootUrl}/{groupId}/work-orders/{workOrderId}/appointment-information`,
    updateCustomerInformation: `${rootUrl}/{groupId}/work-orders/{workOrderId}/customer-information`,
    updateVehicleInformation: `${rootUrl}/{groupId}/work-orders/{workOrderId}/vehicle-information`,
    updatePaymentInformation: `${rootUrl}/{groupId}/work-orders/{workOrderId}/payment-information`,
    updateGlassInformation: `${rootUrl}/{groupId}/work-orders/{workOrderId}/glass-information`,
    archiveWorkOrder: `${rootUrl}/{groupId}/work-orders/{workOrderId}/archive`,
    unArchiveWorkOrder: `${rootUrl}/{groupId}/work-orders/{workOrderId}/un-archive`,
    deleteWorkOrder: `${rootUrl}/{groupId}/work-orders/{workOrderId}`,
    reassignWorkOrder: `${rootUrl}/{groupId}/work-orders/{workOrderId}?newUserId={newUserId}`,
    getWorkOrderNotes: `${rootUrl}/{groupId}/work-orders/{workOrderId}/notes`,
    addWorkOrderNote: `${rootUrl}/{groupId}/work-orders/{workOrderId}/notes`,
    removeWorkOrderNote: `${rootUrl}/{groupId}/work-orders/{workOrderId}/notes/{noteId}`,
    addWorkOrderAttachment: `${rootUrl}/{groupId}/work-orders/{workOrderId}/attachments?fileName={fileName}&attachmentType={attachmentType}`,
    addWorkOrderSignature: `${rootUrl}/{groupId}/work-orders/{workOrderId}/signature`,
    getWorkOrderAttachments: `${rootUrl}/{groupId}/work-orders/{workOrderId}/attachments`,
    autoCompleteAddress: `${rootUrl}/{groupId}/work-orders/{workOrderId}/auto-complete-address?input={input}&session={session}`,
    getPlaceDetails: `${rootUrl}/{groupId}/work-orders/{workOrderId}/place-details?placeId={placeId}&session={session}`,
    getWeather: `${rootUrl}/{groupId}/work-orders/{workOrderId}/weather`,
    getWeatherForecast: `${rootUrl}/{groupId}/work-orders/{workOrderId}/weather-forecast`,
    setWorkOrderStatus: `${rootUrl}/{groupId}/work-orders/{workOrderId}/status?newStatus={newStatus}&overrideValidation={overrideValidationChecks}`,
    publishWorkOrder: `${rootUrl}/{groupId}/work-orders/{workOrderId}/publish`,
    cancelWorkOrder: `${rootUrl}/{groupId}/work-orders/{workOrderId}/cancel`,
    sendTextMessage: `${rootUrl}/{groupId}/work-orders/{workOrderId}/send-text-message`,
    searchWorkOrders: `${rootUrl}/{groupId}/work-orders/search`,
    removeTag: `${rootUrl}/{groupId}/work-orders/{workOrderId}/tags/{tagId}`,
    addTag: `${rootUrl}/{groupId}/work-orders/{workOrderId}/tags/{tagId}`,
    updateTechnician: `${rootUrl}/{groupId}/work-orders/{workOrderId}/appointment-information/technician/{technicianId}`,
    removeTechnician: `${rootUrl}/{groupId}/work-orders/{workOrderId}/appointment-information/technician`,
    getAvailableAppointments: `${rootUrl}/{groupId}/work-orders/{workOrderId}/available-appointments?startDate={startDate}&endDate={endDate}`,
  },
  configuration: {
    getUserConfiguration: `${rootUrl}/{groupId}/configurations/{id}/{userId}`,
    updateUserConfiguration: `${rootUrl}/{groupId}/configurations/{id}/{userId}`,
  },
  entitiesHistory: {
    getEntityHistory: `${rootUrl}/{groupId}/entity-histories/{entityId}`,
  },
  groupSettings: {
    getGroupSettings: `${rootUrl}/{groupId}/group-settings/`,
    getGoogleApiKey: `${rootUrl}/{groupId}/group-settings/google`,
    updateOpenWeatherSettings: `${rootUrl}/{groupId}/group-settings/open-weather`,
    updateSmtpSettings: `${rootUrl}/{groupId}/group-settings/smtp`,
    updateGoogleSettings: `${rootUrl}/{groupId}/group-settings/google`,
    updateOmegaEdiSettings: `${rootUrl}/{groupId}/group-settings/omega-edi`,
    updateAvochatoSettings: `${rootUrl}/{groupId}/group-settings/avochato`,
    updateRequiredPhotos: `${rootUrl}/{groupId}/group-settings/photos`,
    updateTemplate: `${rootUrl}/{groupId}/group-settings/templates/{templateType}`,
    getTemplateValue: `${rootUrl}/{groupId}/group-settings/templates/{templateType}`,
  },
  bulletinBoards: {
    getGroupBulletins: `${rootUrl}/{groupId}/bulletin-boards?showDeleted={showDeleted}`,
    postNew: `${rootUrl}/{groupId}/bulletin-boards`,
    deleteBulletin: `${rootUrl}/{groupId}/bulletin-boards/{bulletinId}`,
  },
  tags: {
    getGroupTags: `${rootUrl}/{groupId}/tags?showDeleted={showDeleted}`,
    deleteTag: `${rootUrl}/{groupId}/tags/{tagId}`,
    restoreTag: `${rootUrl}/{groupId}/tags/{tagId}/restore`,
    updateTag: `${rootUrl}/{groupId}/tags/{tagId}`,
    addTag: `${rootUrl}/{groupId}/tags`,
    syncWithOmega: `${rootUrl}/{groupId}/tags/sync-with-omega`,
  },
  companies: {
    getGroupCompanies: `${rootUrl}/{groupId}/companies`,
    syncWithOmega: `${rootUrl}/{groupId}/companies/sync-with-omega`,
    deleteCompany: `${rootUrl}/{groupId}/companies/{companyId}`,
    updateCompany: `${rootUrl}/{groupId}/companies/{companyId}`,
    addCompany: `${rootUrl}/{groupId}/companies`,
  },
  workOrderAutomations: {
    getAutomations: `${rootUrl}/{groupId}/work-order-automations?showDeleted={showDeleted}`,
    deleteAutomation: `${rootUrl}/{groupId}/work-order-automations/{automationId}`,
    restoreAutomation: `${rootUrl}/{groupId}/work-order-automations/{automationId}/restore`,
    updateAutomation: `${rootUrl}/{groupId}/work-order-automations/{automationId}`,
    addAutomation: `${rootUrl}/{groupId}/work-order-automations`,
  },
  enums: {
    getEnums: `${rootUrl}/enums`,
    getWorkOrderAutomationFields: `${rootUrl}/enums/work-order-automation-fields`,
  },
  serviceAreas: {
    addArea: `${rootUrl}/{groupId}/service-areas`,
    updateArea: `${rootUrl}/{groupId}/service-areas/{areaId}`,
    listServiceAreas: `${rootUrl}/{groupId}/service-areas`,
    syncWithOmega: `${rootUrl}/{groupId}/service-areas/sync-with-omega`,
  },
  hangfire: {
    root: `${hangfireRootUrl}`,
    getHangfireUrl: `${rootUrl}/hangfire`,
  },
};
