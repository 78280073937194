<template>
  <v-container fluid>
    <DataGrid
      id="workOrderAutomation"
      :headers="headers"
      :items="workOrderAutomations"
      :show-add="true"
      @add="showAdd"
      :gridSettings.sync="gridSettings"
      :custom-grid-settings-fields="customGridSettingsFields"
      :title="gridTitle"
      :loading="loading"
    >
      <template v-slot:item.successAction="{ item: { successAction } }">
        {{ findSuccessAction(successAction) }}
      </template>

      <template v-slot:item.trigger="{ item: { trigger } }">
        {{ findTrigger(trigger) }}
      </template>

      <template v-slot:item.created="{ item: { created } }">
        {{ new Date(created).toLocaleString() }}
      </template>

      <template v-slot:item.modified="{ item: { modified } }">
        {{ new Date(modified).toLocaleString() }}
      </template>

      <template
        v-slot:item.workflow.rules="{
          item: {
            workflow: { rules },
          },
        }"
      >
        <span v-for="(rule, index) in rules" :key="index">{{ rule.a }}</span>
      </template>

      <template v-slot:item.enabled="{ item: { enabled } }">
        <v-icon v-if="enabled">mdi-checkbox-marked-outline</v-icon>
        <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
      </template>

      <template v-slot:item.actions="{ item }">
        <span style="white-space: nowrap">
          <v-icon @click="showEdit(item)"> mdi-pencil </v-icon>
          <v-icon v-if="!item.deleted" @click="showConfirm(item)"> mdi-delete </v-icon>
          <v-icon v-else @click="showConfirm(item)"> mdi-restore </v-icon>
        </span>
      </template>
    </DataGrid>

    <Confirmation
      :confirm="confirmSelectedAutomation"
      :show="showConfirmDialog"
      :max-width="600"
      :cancel="() => (this.showConfirmDialog = false)"
    >
      Are you sure you want to {{ operation }} Work Order Automation {{ selectedAutomation.name }}?
    </Confirmation>

    <v-dialog :value="showDialog" persistent :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-card-title>{{ dialogTitle }}</v-card-title>
        <v-card-text>
          <ReusableForm :fields="automationFields" :item="editAutomation" />
          <RulesEditor :rules="editAutomation.workflow.rules" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="showDialog = false">Cancel</v-btn>
          <v-btn text color="primary" @click="saveAutomation"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _orderBy from 'lodash/orderBy';

import DataGrid from '@/App/@Components/DataGrid';
import services from '@/@libs/services';
import Confirmation from '@/App/@Components/Confirmation';
import ReusableForm from '@/App/@Components/ReusableForm';
import RulesEditor from '@/App/Settings/WorkOrderAutomation/@Components/RulesEditor';

export default {
  name: 'WorkOrderAutomation',
  components: {
    RulesEditor,
    ReusableForm,
    Confirmation,
    DataGrid,
  },
  data() {
    return {
      showDialog: false,
      loading: false,
      headers: [
        {
          id: 'name',
          text: 'Name',
          value: 'name',
          default: true,
          searchable: true,
          groupable: false,
        },
        {
          id: 'created',
          text: 'Created',
          value: 'created',
          default: false,
          searchable: false,
          groupable: false,
        },
        {
          id: 'modified',
          text: 'Modified',
          value: 'modified',
          default: false,
          searchable: false,
          groupable: false,
        },
        {
          id: 'trigger',
          text: 'Trigger',
          value: 'trigger',
          default: true,
          searchable: true,
          groupable: true,
        },
        {
          id: 'fields',
          text: 'Field(s)',
          value: 'workflow.rules',
          default: false,
          searchable: true,
          groupable: true,
        },
        {
          id: 'successAction',
          text: 'Action',
          value: 'successAction',
          default: true,
          searchable: false,
          groupable: true,
        },
        {
          id: 'enabled',
          text: 'Enabled',
          value: 'enabled',
          default: true,
          searchable: false,
          groupable: true,
        },
        {
          id: 'actions',
          text: 'Actions',
          value: 'actions',
          sortable: false,
          groupable: false,
          default: true,
          align: 'center',
        },
      ],
      workOrderAutomations: [],
      gridSettings: null,
      customGridSettingsFields: [
        {
          id: 'showDeletedAutomations',
          type: 'check',
          label: 'Show Deleted Automations',
          value: 'showDeletedAutomations',
          cols: 4,
        },
      ],
      showDeletedAutomations: false,
      selectedAutomation: {},
      showConfirmDialog: false,
      editAutomation: {
        workflow: {
          rules: [],
        },
      },
      tags: [],
    };
  },
  computed: {
    groupId() {
      return this.$route.params.groupId;
    },
    gridTitle() {
      return this.showDeletedAutomations ? 'All Automations' : 'Active Automations';
    },
    dialogTitle() {
      return this.editAutomation.id ? 'Edit Automation' : 'Create Automation';
    },
    operation() {
      return this.selectedAutomation.deleted ? 'RESTORE' : 'DELETE';
    },
    availableTags() {
      return this.tags.map((tag) => ({ text: tag.value, value: tag.id }));
    },
    automationFields() {
      return [
        {
          id: 'name',
          type: 'text',
          label: 'Name',
          value: 'name',
          cols: 9,
        },
        {
          id: 'enabled',
          type: 'check',
          label: 'Enabled',
          value: 'enabled',
          cols: 3,
        },
        {
          id: 'trigger',
          type: 'select',
          label: 'Trigger',
          value: 'trigger',
          items: services.enumService.arrayEnums.trigger,
          cols: 12,
        },
        {
          id: 'successAction',
          type: 'select',
          label: 'Action',
          value: 'successAction',
          items: services.enumService.arrayEnums.successAction,
          cols: 6,
        },
        (this.showTags || this.showSuccessValueText) && {
          id: 'successValue',
          type: this.showTags ? 'select' : 'text',
          label: this.showTags ? 'Tag' : 'Value',
          value: 'successValue',
          items: this.availableTags,
          cols: 6,
        },
      ];
    },
    showSuccessValueText() {
      return (
        this.editAutomation.successAction
        === services.enumService.mappedEnums.successAction?.sendAgreementEmail?.value
        || this.editAutomation.successAction
        === services.enumService.mappedEnums.successAction?.clone?.value
      );
    },
    showTags() {
      return (
        this.editAutomation.successAction
          === services.enumService.mappedEnums.successAction?.addTag?.value
        || this.editAutomation.successAction
          === services.enumService.mappedEnums.successAction?.removeTag?.value
      );
    },
  },
  watch: {
    gridSettings: {
      handler(newValue) {
        this.showDeletedAutomations = newValue.showDeletedAutomations || false;
        this.loadData();
      },
      deep: true,
    },
  },
  methods: {
    findSuccessAction(value) {
      return services.enumService.findEnumByValue('successAction', value).text;
    },
    findTrigger(value) {
      return services.enumService.findEnumByValue('trigger', value).text;
    },
    async loadData() {
      if (!this.gridSettings) return;

      this.loading = true;

      const [workOrderAutomations, tags] = await Promise.all([
        services.workOrderAutomationService.getAutomations(
          this.groupId,
          this.showDeletedAutomations,
        ),
        services.tagService.getGroupTags(this.groupId, false),
      ]);

      this.loading = false;

      this.tags = _orderBy(tags, ['value']);
      this.workOrderAutomations = _orderBy(workOrderAutomations, ['name']);
    },
    async saveAutomation() {
      const automationToSave = JSON.parse(JSON.stringify(this.editAutomation));

      if (this.editAutomation.id) {
        await services.workOrderAutomationService.updateAutomation(this.groupId, automationToSave);
      } else {
        await services.workOrderAutomationService.addAutomation(this.groupId, automationToSave);
      }

      this.showDialog = false;
      await this.loadData();
    },
    showEdit(selectedAutomation) {
      const editAutomation = JSON.parse(JSON.stringify(selectedAutomation));

      this.editAutomation = editAutomation;
      this.showDialog = true;
    },
    showAdd() {
      this.editAutomation = {
        successAction: 'AddTag',
        enabled: true,
        workflow: {
          workflowName: 'workflow',
          rules: [
            {
              ruleName: '0',
              expression: '',
            },
          ],
        },
        trigger: 'Updated',
      };
      this.showDialog = true;
    },
    showConfirm(selectedAutomation) {
      this.selectedAutomation = selectedAutomation;
      this.showConfirmDialog = true;
    },
    async confirmSelectedAutomation() {
      const { deleted, id } = this.selectedAutomation;

      if (!deleted) {
        await services.workOrderAutomationService.deleteAutomation(this.groupId, id);
      } else {
        await services.workOrderAutomationService.restoreAutomation(this.groupId, id);
      }

      await this.loadData();
      this.showConfirmDialog = false;
    },
  },
  async created() {
    await this.loadData();
  },
};
</script>

<style scoped></style>
