<template>
  <v-container fluid>
    <v-card v-if="groupSettings" class="mx-auto">
      <v-card-title> Google Settings </v-card-title>
      <v-card-subtitle> Api Key Settings </v-card-subtitle>

      <v-card-text>
        <ReusableForm :fields="googleFields" :item="groupSettings.googleSettings" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn :disabled="disableSaveButton" color="primary" text @click="updateGoogleSettings">
          Save Changes
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-skeleton-loader v-else type="article" />
  </v-container>
</template>

<script>
  import ReusableForm from '@/App/@Components/ReusableForm';
  import services from '@/@libs/services';

  export default {
    name: 'Google',
    components: { ReusableForm },
    data() {
      return {
        loading: false,
        groupSettings: null,
        originalGroupSettings: null,
      };
    },
    computed: {
      groupId() {
        return this.$route.params.groupId;
      },
      disableSaveButton() {
        return (
          JSON.stringify(this.originalGroupSettings.googleSettings)
          === JSON.stringify(this.groupSettings.googleSettings)
        );
      },
      googleFields() {
        return [
          {
            id: 'enabled',
            value: 'enabled',
            type: 'check',
            label: 'Enabled',
            cols: 12,
            disabled:
              this.groupSettings.googleSettings.apiKey === '' || !this.groupSettings.googleSettings.apiKey,
          },
          {
            id: 'apiKey',
            value: 'apiKey',
            type: 'text',
            label: 'Api Key',
            cols: 12,
          },
        ];
      },
    },
    watch: {
      groupSettings: {
        handler(newValue, oldValue) {
          if (newValue.googleSettings.apiKey === '') this.groupSettings.googleSettings.enabled = false;

          if (newValue !== oldValue) {
            this.originalGroupSettings = JSON.parse(JSON.stringify(newValue));
          }
        },
        deep: true,
      },
    },
    methods: {
      async loadData() {
        const groupSettings = await services.groupSettingsService.getGroupSettings(this.groupId);

        this.groupSettings = groupSettings;
      },
      async updateGoogleSettings() {
        const groupSettings = await services.groupSettingsService.updateGoogleSettings(
          this.groupId,
          this.groupSettings.googleSettings,
        );

        this.groupSettings = groupSettings;
      },
    },
    async created() {
      await this.loadData();
    },
  };
</script>

<style scoped></style>
