<template>
  <v-container fluid>
    <v-card v-if="groupSettings" class="mx-auto">
      <v-card-title> Avochato Settings </v-card-title>
      <v-card-subtitle> Account Settings </v-card-subtitle>

      <v-card-text>
        <ReusableForm :fields="avochatoFields" :item="groupSettings.avochatoSettings" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn :disabled="disableSaveButton" color="primary" text @click="updateAvochatoSettings">
          Save Changes
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-skeleton-loader v-else type="article" />
  </v-container>
</template>

<script>
  import ReusableForm from '@/App/@Components/ReusableForm';
  import services from '@/@libs/services';

  export default {
    name: 'Avochato',
    components: { ReusableForm },
    data() {
      return {
        loading: false,
        groupSettings: null,
        originalGroupSettings: null,
      };
    },
    computed: {
      groupId() {
        return this.$route.params.groupId;
      },
      disableSaveButton() {
        return (
          JSON.stringify(this.originalGroupSettings.avochatoSettings)
          === JSON.stringify(this.groupSettings.avochatoSettings)
        );
      },
      avochatoFields() {
        return [
          {
            id: 'enabled',
            value: 'enabled',
            type: 'check',
            label: 'Enabled',
            cols: 12,
            disabled:
              this.groupSettings.avochatoSettings.authId === ''
              || !this.groupSettings.avochatoSettings.authId,
          },
          {
            id: 'authId',
            value: 'authId',
            type: 'text',
            label: 'Auth Id',
            cols: 12,
          },
          {
            id: 'authSecret',
            value: 'authSecret',
            type: 'text',
            label: 'Auth Secret',
            cols: 12,
          },
        ];
      },
    },
    watch: {
      groupSettings: {
        handler(newValue, oldValue) {
          if (
            newValue.avochatoSettings.authId === ''
            || newValue.avochatoSettings.authSecret === ''
          ) {
            this.groupSettings.avochatoSettings.enabled = false;
          }

          if (newValue !== oldValue) {
            this.originalGroupSettings = JSON.parse(JSON.stringify(newValue));
          }
        },
        deep: true,
      },
    },
    methods: {
      async loadData() {
        const groupSettings = await services.groupSettingsService.getGroupSettings(this.groupId);

        this.groupSettings = groupSettings;
      },
      async updateAvochatoSettings() {
        const groupSettings = await services.groupSettingsService.updateAvachatoSettings(
          this.groupId,
          this.groupSettings.avochatoSettings,
        );

        this.groupSettings = groupSettings;
      },
    },
    async created() {
      await this.loadData();
    },
  };
</script>

<style scoped></style>
