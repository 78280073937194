<template>
  <v-dialog
    v-model="show"
    persistent
    :max-width="maxWidth"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        <slot />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="handleCancel" v-if="cancel">
          {{ cancelText }}
        </v-btn>
        <v-btn text @click="confirm" :color="confirmColor">
          {{ confirmText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'Confirmation',
    props: {
      confirmColor: {
        type: String,
        default: 'red',
      },
      show: {
        type: Boolean,
        required: true,
      },
      title: {
        type: String,
        default: 'Confirm',
      },
      maxWidth: {
        type: Number,
      },
      cancelText: {
        type: String,
        default: 'Cancel',
      },
      confirmText: {
        type: String,
        default: 'Ok',
      },
      cancel: {
        type: Function,
      },
      confirm: {
        type: Function,
        required: true,
      },
    },
    methods: {
      handleCancel() {
        if (this.cancel) this.cancel();
        this.$emit('update:show', !this.show);
      },
    },
  };
</script>

<style scoped></style>
