import baseService from '@/@libs/services/baseService';
import httpClient from '@/@libs/httpClient';

export default {
  ...baseService,
  async getEntityHistory(groupId, entityId) {
    const result = await httpClient.get(this.apiEndpoints.entitiesHistory.getEntityHistory, {
      replace: {
        groupId,
        entityId,
      },
    });

    return result;
  },
};
