const listeners = new Map();

const eventTypes = {
  navigation: {
    succeeded: 'navigation-succeeded',
  },
  http: {
    loadingStarted: 'loading-started',
    loadingFinished: 'loading-finished',
    authException: 'auth-exception',
    exception: 'exception',
  },
  app: {
    error: 'error',
    loginSuccess: 'login-success',
    loginFail: 'login-fail',
    refreshLogin: 'refresh-login',
    updateAvailable: 'update-available',
  },
};

/**
 * Bind an event listener
 * @param name Required name of event to listen for
 * @param callback Required callback to execute on event
 */
function on({ name, callback }) {
  listeners.set(callback, name);
}

/**
 * Remove event handler binding
 * @param callback Required callback to unregister
 */
function off(callback) {
  listeners.delete(callback);
}

/**
 * Raise event by name and execute all event handlers
 * @param name Required name of event to raise
 * @param payload Optional payload to pass to all callback methods
 */
function raiseEvent({ name, payload }) {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.log('raising event', { name, payload });
  }

  listeners.forEach((key, callback) => {
    if (key === name) {
      if (typeof callback === 'function') {
        try {
          callback(payload);
        } catch {
          // throw away exception
        }
      }
    }
  });
}

export default {
  on,
  off,
  raiseEvent,
  eventTypes,
};
