import baseService from '@/@libs/services/baseService';
import httpClient from '@/@libs/httpClient';

export default {
  ...baseService,
  async listAreas(groupId) {
    const result = await httpClient.get(this.apiEndpoints.serviceAreas.listServiceAreas, {
      replace: {
        groupId,
      },
    });

    return result;
  },
  async addArea(groupId, areaDetails) {
    const result = await httpClient.post(this.apiEndpoints.serviceAreas.addArea, {
      replace: {
        groupId,
      },
      body: areaDetails,
    });

    return result;
  },
  async updateArea(groupId, areaId, areaDetails) {
    const result = await httpClient.put(this.apiEndpoints.serviceAreas.updateArea, {
      replace: {
        groupId,
        areaId,
      },
      body: areaDetails,
    });

    return result;
  },
  async syncWithOmega(groupId) {
    const result = await httpClient.post(this.apiEndpoints.serviceAreas.syncWithOmega, {
      replace: {
        groupId,
      },
    });

    return result;
  },
};
