import httpClient from '@/@libs/httpClient';
import baseService from '@/@libs/services/baseService';
import store from '@/@libs/store';

export default {
  ...baseService,
  async login(emailAddress, password, rememberMe) {
    const response = await httpClient.post(this.apiEndpoints.userInfos.login, {
      replace: {
        rememberMe,
      },
      body: {
        emailAddress,
        password,
      },
    });

    httpClient.setBearerToken(response.authToken);
    return response;
  },
  async loginWithRefreshToken(refreshToken, rememberMe) {
    const response = await httpClient.post(this.apiEndpoints.userInfos.loginWithRefreshToken, {
      replace: {
        rememberMe,
      },
      body: refreshToken,
    });

    httpClient.setBearerToken(response.authToken);
    return response;
  },
  async logout() {
    await store.dispatch('logout');
    localStorage.removeItem('refreshToken');
  },
  async resetPassword(emailAddress) {
    const response = await httpClient.post(this.apiEndpoints.userInfos.passwordReset, {
      replace: {
        emailAddress,
      },
      parseJson: false,
    });

    return response;
  },
  async getAttachmentToken(groupId) {
    const response = await httpClient.get(this.apiEndpoints.userInfos.getAttachmentToken, {
      replace: {
        groupId,
      },
      parseJson: false,
    });

    return response;
  },
  async updateProfile(userInfoId, profile) {
    const response = await httpClient.put(this.apiEndpoints.userInfos.updateProfile, {
      replace: {
        userInfoId,
      },
      body: profile,
    });

    return response;
  },
};
