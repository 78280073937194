<template>
  <v-text-field
    :value="maskedValue"
    :label="label"
    :readonly="readonly"
    @focusin="hasFocus = true"
    @focusout="hasFocus = false"
    @blur="$emit('blur')"
    @input="setValue"
    :append-icon="appendIcon"
    :prepend-icon="prependIcon"
    :disabled="disabled"
  />
</template>

<script>
  export default {
    name: 'MaskedTextField',
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        required: true,
      },
      readonly: {
        type: Boolean,
      },
      value: {
        type: String,
      },
      mask: {
        type: Function,
        required: true,
      },
      prependIcon: {
        type: String,
      },
      appendIcon: {
        type: String,
      },
    },
    data() {
      return {
        hasFocus: false,
      };
    },
    computed: {
      maskedValue() {
        return this.hasFocus ? this.value : this.mask(this.value);
      },
    },
    methods: {
      setValue(newValue) {
        this.$emit('input', newValue);
      },
    },
  };
</script>

<style scoped></style>
