<template>
  <div>
    <iframe class="mondays-view-frame" :src="hangfireUrl" v-if="loaded"></iframe>
  </div>
</template>

<script>
  import services from '@/@libs/services';

  export default {
    name: 'Hangfire',
    data() {
      return {
        loaded: false,
        hangfireUrl: '',
      };
    },
    methods: {
      async loadData() {
        const hangFireUrl = await services.hangfireService.getHangfireUrl();

        this.hangfireUrl = hangFireUrl;
        this.loaded = true;
      },
    },
    async created() {
      await this.loadData();
    },
  };
</script>

<style scoped>
  .mondays-view-frame {
    border: 0;
    box-shadow: 5px 5px 56px 0 rgba(0, 0, 0, 0.25);
    width: 100%;
    height: calc(100vh - 94px);
  }
</style>
