import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

function defaultState() {
  return {
    selectedGroup: JSON.parse(localStorage.getItem('selectedGroup') || '{}'),
    loggedIn: false,
    userInfo: null,
    searchResults: {
      workOrders: [],
    },
  };
}

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

export default new Vuex.Store({
  state: {
    ...defaultState(),
  },
  mutations: {
    login(state, { userInfo }) {
      state.userInfo = userInfo;
      state.loggedIn = true;
    },
    logout() {
      const newState = { ...defaultState() };

      Object.entries(newState).forEach((entry) => {
        const [key, value] = entry;
        this.state[key] = value;
      });

      localStorage.clear();
    },
    selectGroup(state, { group }) {
      state.selectedGroup = group;
      localStorage.setItem('selectedGroup', JSON.stringify(group));
    },
    setSearchedWorkOrders(state, { workOrders }) {
      state.searchResults.workOrders = workOrders;
    },
  },
  actions: {
    login({ commit }, userInfo) {
      commit('login', userInfo);
    },
    logout({ commit }) {
      commit('logout');
    },
    selectGroup({ commit }, group) {
      commit('selectGroup', { group });
    },
    setSearchedWorkOrders({ commit }, workOrders) {
      commit('setSearchedWorkOrders', { workOrders });
    },
  },
  getters: {
    authToken: (state) => (state.userInfo ? parseJwt(state.userInfo.authToken) : null),
    permissions: (_, getters) => {
      if (!getters.authToken) return [];

      const { permissions } = getters.authToken;

      return Array.isArray(permissions) ? permissions : [permissions];
    },
    groupPermissions: (_, getters) => (groupId) => {
      if (!getters.authToken) return [];
      const permissions = getters.authToken[groupId];

      return Array.isArray(permissions) ? permissions : [permissions];
    },
    userGroups: (_, getters) => {
      if (!getters.authToken) return [];
      const { groupIds } = getters.authToken;

      return Array.isArray(groupIds) ? groupIds : [groupIds];
    },
  },
  modules: {},
});
