<template>
  <v-card class="mx-auto" max-width="400">
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-h5">
          {{ calendarDate(selectedForecast.dt) }}
        </v-list-item-title>
        <v-list-item-subtitle> {{ selectedForecast.weather[0].main }} </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-card-text>
      <v-row align="center">
        <v-col class="text-h2" cols="7">{{ Math.round(selectedForecast.temp.max) }}&deg;F</v-col>
        <v-col cols="4">
          <v-img
            :src="selectedForecast.weather[0].iconUrl"
            :alt="selectedForecast.weather[0].icon"
            contain
            :max-width="200"
            :max-height="200"
          />
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col class="text-h2" cols="7">{{ Math.round(selectedForecast.temp.min) }}&deg;F</v-col>
      </v-row>
    </v-card-text>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-weather-windy</v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle
        >{{ selectedForecast.wind_speed }} mph @ {{ selectedForecast.wind_deg }} &deg;
      </v-list-item-subtitle>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-weather-rainy</v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle>{{ Math.round(selectedForecast.pop * 100) }}% </v-list-item-subtitle>
    </v-list-item>
    <v-card-actions>
      <v-slider
        :max="forecast.length - 1"
        :step="1"
        ticks="always"
        :tick-size="4"
        :tick-labels="tickLabels"
        v-model="selectedIndex"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
  import moment from 'moment-timezone';

  export default {
    name: 'WeatherForecastCard',
    props: {
      forecast: {
        type: [Array, Object],
        required: true,
      },
    },
    data() {
      return {
        selectedIndex: 0,
      };
    },
    computed: {
      tickLabels() {
        return this.forecast.map((weather) => moment(weather.dt * 1000).format('ddd'));
      },
      selectedForecast() {
        return this.forecast[this.selectedIndex];
      },
    },
    methods: {
      calendarDate(dt) {
        return moment(dt * 1000).format('dddd MM/DD/yyyy');
      },
    },
  };
</script>
