import baseService from '@/@libs/services/baseService';
import httpClient from '@/@libs/httpClient';

import _find from 'lodash/find';
import _map from 'lodash/map';
import _orderBy from 'lodash/orderBy';

export default {
  ...baseService,
  loaded: false,
  mappedEnums: {
    successAction: {},
    trigger: {},
    binaryOperation: {},
    workOrderAutomationFields: {},
    attachmentType: {},
    workOrderStatus: {},
    templateType: {},
    tintShade: {},
  },
  arrayEnums: {
    attachmentType: [],
    successAction: [],
    trigger: [],
    binaryOperation: [],
    workOrderStatus: [],
    templateType: [],
    tintShade: [],
  },
  async getEnums() {
    if (this.loaded) return this.mappedEnums;

    const [enums, workOrderAutomationFields] = await Promise.all([
      httpClient.get(this.apiEndpoints.enums.getEnums, {}),
      httpClient.get(this.apiEndpoints.enums.getWorkOrderAutomationFields, {}),
    ]);

    this.mappedEnums = { ...enums, workOrderAutomationFields };

    Object.keys(enums).forEach(
      // eslint-disable-next-line no-return-assign
      (key) => (this.arrayEnums[key] = _orderBy(
        _map(enums[key], (val) => val),
        'text',
      )),
    );

    this.loaded = true;

    return this.mappedEnums;
  },
  findEnumByValue(name, value) {
    return _find(this.mappedEnums[name] || {}, (a) => a.value === value) || {};
  },
};
