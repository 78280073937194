<template>
  <v-container fluid>
    <v-progress-linear v-if="loading" indeterminate />

    <v-expansion-panels v-model="panels" multiple>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h2>Criteria</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card class="mx-auto">
            <v-card-title> Search Criteria </v-card-title>
            <v-card-subtitle> Customer Information </v-card-subtitle>

            <v-card-text>
              <ReusableForm :fields="workOrderFields" :item="formValues.wi" />
              <ReusableForm :fields="customerFields" :item="formValues.ci" />
              <ReusableForm :fields="paymentFields" :item="formValues.pi" />
              <ReusableForm :fields="vehicleFields" :item="formValues.vi" />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="primary" @click="loadSearchResults"> Search </v-btn>
            </v-card-actions>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h2>Results</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <WorkOrderTable
            :work-orders="workOrders"
            :loading="loading"
            title="Work Orders"
            id="work-order-search"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
  import ReusableForm from '@/App/@Components/ReusableForm';
  import services from '@/@libs/services';
  import WorkOrderTable from '@/App/WorkOrders/@Components/WorkOrderTable';

  export default {
    name: 'SearchWorkOrders',
    components: { WorkOrderTable, ReusableForm },
    data() {
      return {
        panels: [0],
        loading: false,
        formValues: {
          ci: {},
          pi: {},
          vi: {},
          wi: {},
        },
        workOrderFields: [
          {
            type: 'text',
            label: 'Work Order Number',
            value: 'WorkOrderNumber',
            cols: 3,
          },
        ],
        customerFields: [
          {
            type: 'text',
            label: 'First Name',
            value: 'FirstName',
            cols: 6,
          },
          {
            type: 'text',
            label: 'Last Name',
            value: 'LastName',
            cols: 6,
          },
          {
            type: 'text',
            label: 'Phone Number',
            value: 'PhoneNumber',
            cols: 12,
          },
          {
            type: 'text',
            label: 'Address Line 1',
            value: 'StreetLine1',
            cols: 12,
          },
          {
            type: 'text',
            label: 'Address Line 2',
            value: 'StreetLine2',
            cols: 12,
          },
          {
            type: 'text',
            label: 'DL Number',
            value: 'DriversLicenseNumber',
            cols: 12,
          },
        ],
        paymentFields: [
          {
            type: 'text',
            label: 'Insurance Policy Number',
            value: 'InsurancePolicyNumber',
            cols: 12,
          },
        ],
        vehicleFields: [
          {
            type: 'text',
            label: 'Vin',
            value: 'Vin',
            cols: 12,
          },
        ],
      };
    },
    computed: {
      groupId() {
        return this.$store.state.selectedGroup.id;
      },
      workOrders() {
        return this.$store.state.searchResults.workOrders;
      },
    },
    methods: {
      async loadSearchResults() {
        this.loading = true;

        try {
          const workOrders = await services.workOrderService.searchWorkOrders(
            this.groupId,
            this.formValues,
          );

          await this.$store.dispatch('setSearchedWorkOrders', workOrders);
          this.panels = [1];
        } finally {
          this.loading = false;
        }
      },
    },
    created() {
      if (this.workOrders.length > 0) this.panels = [1];
    },
  };
</script>

<style scoped></style>
