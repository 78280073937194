<template>
  <v-container fluid>
    <v-progress-linear indeterminate v-if="!loaded" />
    <v-card v-else class="mx-auto">
      <v-card-title> Edit Profile </v-card-title>
      <v-card-subtitle> Change password </v-card-subtitle>

      <v-card-text>
        <ReusableForm :fields="profileFields" :item="userInfo" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="disableSaveButton"
          color="primary"
          text
          @click="updateProfile"
          :loading="loading"
        >
          Save Changes
        </v-btn>
      </v-card-actions>
    </v-card>

    <Confirmation
      title="Success"
      :confirm="()=> this.showConfirmation = false"
      :show="showConfirmation"
      :max-width="600"
      confirm-color="primary"
    >
      Your password has been changed successfully
    </Confirmation>
  </v-container>
</template>

<script>
  import ReusableForm from '@/App/@Components/ReusableForm';
  import services from '@/@libs/services';
  import Confirmation from '@/App/@Components/Confirmation';

  export default {
    name: 'Profile',
    components: { Confirmation, ReusableForm },
    data() {
      return {
        showConfirmation: false,
        loaded: false,
        loading: false,
        profileFields: [
          {
            id: 'name',
            value: 'name',
            type: 'text',
            label: 'Name',
            cols: 12,
          },
          {
            id: 'emailAddress',
            value: 'emailAddress',
            type: 'text',
            label: 'Email Address (cannot be changed)',
            readonly: true,
            cols: 12,
          },
          {
            id: 'password',
            value: 'password',
            type: 'text',
            label: 'Password',
            cols: 12,
            textType: 'password',
          },
          {
            id: 'confirmPassword',
            value: 'confirmPassword',
            type: 'text',
            label: 'Confirm Password',
            cols: 12,
            textType: 'password',
          },
        ],
        userInfo: {
          password: null,
          confirmPassword: null,
        },
      };
    },
    computed: {
      disableSaveButton() {
        return this.userInfo.password !== this.userInfo.confirmPassword;
      },
    },
    methods: {
      async updateProfile() {
        try {
          this.loading = true;
          await services.userInfoService.updateProfile(this.userInfo.id, this.userInfo);
          this.showConfirmation = true;
          this.loaded = true;
        } finally {
          this.loading = false;
        }
      },
      async loadData() {
        this.loaded = true;
      },
    },
    async created() {
      await this.loadData();
      this.userInfo = JSON.parse(JSON.stringify(this.$store.state.userInfo));
    },
  };
</script>

<style scoped></style>
