<template>
  <iframe
    class="mondays-view-frame"
    src="https://view.monday.com/embed/1888763354-97f92c86ab0782061ba1b019f26276c9?r=use1"
  />
</template>

<script>
  export default {
    name: 'WorkInProgress',
  };
</script>

<style scoped lang="scss">
.mondays-view-frame {
  border: 0;
  box-shadow: 5px 5px 56px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
  height: calc(100vh - 94px);
}
</style>
