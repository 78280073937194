var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('DataGrid',{attrs:{"id":"service-areas","headers":_vm.headers,"items":_vm.serviceAreas,"show-add":true,"title":"Service Areas","loading":_vm.loading},on:{"add":_vm.addArea},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var created = ref.item.created;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(new Date(created).toLocaleString())+" ")])]}},{key:"item.modified",fn:function(ref){
var modified = ref.item.modified;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(new Date(modified).toLocaleString())+" ")])]}},{key:"item.zipCodes",fn:function(ref){
var zipCodes = ref.item.zipCodes;
return [_c('v-chip-group',{attrs:{"column":""}},_vm._l((zipCodes),function(zipCode,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[_vm._v(" "+_vm._s(zipCode)+" ")])}),1)]}},{key:"item.technicianIds",fn:function(ref){
var technicianIds = ref.item.technicianIds;
return [_c('v-chip-group',{attrs:{"column":""}},_vm._l((technicianIds),function(technicianId,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[_vm._v(" "+_vm._s(_vm.userNameFromId(technicianId))+" ")])}),1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_c('v-icon',{on:{"click":function($event){return _vm.showEdit(item)}}},[_vm._v(" mdi-pencil ")]),(!item.deleted)?_c('v-icon',{on:{"click":function($event){return _vm.showConfirm(item)}}},[_vm._v(" mdi-delete ")]):_c('v-icon',{on:{"click":function($event){return _vm.showConfirm(item)}}},[_vm._v(" mdi-restore ")])],1)]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"elevation":0}},[_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading},on:{"click":_vm.syncWithOmegaAreas}},[_vm._v(" Sync Omega Areas ")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"value":_vm.showDialog,"persistent":"","fullscreen":_vm.$vuetify.breakpoint.mobile,"max-width":600}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.dialogTitle))]),_c('v-card-text',[_c('ReusableForm',{attrs:{"fields":_vm.areaFields,"item":_vm.editArea}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.hideDialog}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.saveArea}},[_vm._v(" Save ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }