import baseService from '@/@libs/services/baseService';
import httpClient from '@/@libs/httpClient';
import apiEndpoints from '@/@enums/apiEndpoints';

export default {
  ...baseService,
  async postNew(groupId) {
    const result = await httpClient.post(this.apiEndpoints.workOrders.postNew, {
      replace: {
        groupId,
      },
      body: {},
    });

    return result;
  },
  async listActiveWorkOrders(groupId) {
    const result = await httpClient.get(this.apiEndpoints.workOrders.listActive, {
      replace: {
        groupId,
      },
    });

    return result;
  },
  async listArchivedWorkOrders(groupId) {
    const result = await httpClient.get(this.apiEndpoints.workOrders.listArchived, {
      replace: {
        groupId,
      },
    });

    return result;
  },
  async getWorkOrderDetails(groupId, workOrderId) {
    const result = await httpClient.get(this.apiEndpoints.workOrders.getWorkOrderDetails, {
      replace: {
        groupId,
        workOrderId,
      },
    });

    return result;
  },
  async updateCustomerInformation(groupId, workOrderId, customerInformation) {
    const result = await httpClient.put(this.apiEndpoints.workOrders.updateCustomerInformation, {
      replace: {
        groupId,
        workOrderId,
      },
      body: customerInformation,
    });

    return result;
  },
  async updateAppointmentInformation(groupId, workOrderId, appointmentInformation) {
    const result = await httpClient.put(this.apiEndpoints.workOrders.updateAppointmentInformation, {
      replace: {
        groupId,
        workOrderId,
      },
      body: appointmentInformation,
    });

    return result;
  },
  async updateVehicleInformation(groupId, workOrderId, vehicleInformation) {
    const result = await httpClient.put(this.apiEndpoints.workOrders.updateVehicleInformation, {
      replace: {
        groupId,
        workOrderId,
      },
      body: vehicleInformation,
    });

    return result;
  },
  async updatePaymentInformation(groupId, workOrderId, paymentInformation) {
    const result = await httpClient.put(this.apiEndpoints.workOrders.updatePaymentInformation, {
      replace: {
        groupId,
        workOrderId,
      },
      body: paymentInformation,
    });

    return result;
  },
  async updateGlassInformation(groupId, workOrderId, glassInformation) {
    const result = await httpClient.put(this.apiEndpoints.workOrders.updateGlassInformation, {
      replace: {
        groupId,
        workOrderId,
      },
      body: glassInformation,
    });

    return result;
  },
  async archiveWorkOrder(groupId, workOrderId) {
    const result = await httpClient.post(this.apiEndpoints.workOrders.archiveWorkOrder, {
      replace: {
        groupId,
        workOrderId,
      },
    });

    return result;
  },
  async unArchiveWorkOrder(groupId, workOrderId) {
    const result = await httpClient.post(this.apiEndpoints.workOrders.unArchiveWorkOrder, {
      replace: {
        groupId,
        workOrderId,
      },
    });

    return result;
  },
  async deleteWorkOrder(groupId, workOrderId) {
    const result = await httpClient.delete(this.apiEndpoints.workOrders.deleteWorkOrder, {
      replace: {
        groupId,
        workOrderId,
      },
    });

    return result;
  },
  async reassignWorkOrder(groupId, workOrderId, newUserId) {
    const result = await httpClient.post(this.apiEndpoints.workOrders.reassignWorkOrder, {
      replace: {
        groupId,
        workOrderId,
        newUserId,
      },
    });

    return result;
  },
  async getWorkOrderNotes(groupId, workOrderId) {
    const result = await httpClient.get(this.apiEndpoints.workOrders.getWorkOrderNotes, {
      replace: {
        groupId,
        workOrderId,
      },
    });

    return result;
  },
  async addWorkOrderNote(groupId, workOrderId, noteText) {
    const result = await httpClient.post(this.apiEndpoints.workOrders.addWorkOrderNote, {
      replace: {
        groupId,
        workOrderId,
      },
      body: noteText,
    });

    return result;
  },
  async removeWorkOrderNote(groupId, workOrderId, noteId) {
    const result = await httpClient.delete(this.apiEndpoints.workOrders.removeWorkOrderNote, {
      replace: {
        groupId,
        workOrderId,
        noteId,
      },
    });

    return result;
  },
  async addWorkOrderAttachment(groupId, workOrderId, file, attachmentType) {
    const result = await httpClient.post(apiEndpoints.workOrders.addWorkOrderAttachment, {
      replace: {
        groupId,
        workOrderId,
        fileName: file.name,
        attachmentType,
      },
      file,
    });

    return result;
  },
  async getWorkOrderAttachments(groupId, workOrderId) {
    const result = await httpClient.get(this.apiEndpoints.workOrders.getWorkOrderAttachments, {
      replace: {
        groupId,
        workOrderId,
      },
    });

    return result;
  },
  async getPlaceDetails(groupId, workOrderId, placeId, session) {
    const result = await httpClient.get(this.apiEndpoints.workOrders.getPlaceDetails, {
      replace: {
        groupId,
        workOrderId,
        placeId,
        session,
      },
    });

    return result;
  },
  async getWorkOrderCurrentWeather(groupId, workOrderId) {
    const result = await httpClient.get(this.apiEndpoints.workOrders.getWeather, {
      replace: {
        groupId,
        workOrderId,
      },
    });

    return result;
  },
  async getWorkOrderWeatherForecast(groupId, workOrderId) {
    const result = await httpClient.get(this.apiEndpoints.workOrders.getWeatherForecast, {
      replace: {
        groupId,
        workOrderId,
      },
    });

    return result;
  },
  async addWorkOrderSignature(groupId, workOrderId, signatureData) {
    const result = await httpClient.post(apiEndpoints.workOrders.addWorkOrderSignature, {
      replace: {
        groupId,
        workOrderId,
      },
      body: signatureData,
    });

    return result;
  },
  async getWorkOrderSignature(groupId, workOrderId) {
    const result = await httpClient.get(this.apiEndpoints.workOrders.getWorkOrderSignature, {
      replace: {
        groupId,
        workOrderId,
      },
    });

    return result;
  },
  async setWorkOrderStatus(groupId, workOrderId, newStatus, overrideValidationChecks) {
    const result = await httpClient.put(this.apiEndpoints.workOrders.setWorkOrderStatus, {
      replace: {
        groupId,
        workOrderId,
        newStatus,
        overrideValidationChecks,
      },
    });

    return result;
  },
  async publishWorkOrder(groupId, workOrderId) {
    const result = await httpClient.put(this.apiEndpoints.workOrders.publishWorkOrder, {
      replace: {
        groupId,
        workOrderId,
      },
    });

    return result;
  },
  async cancelWorkOrder(groupId, workOrderId, reason) {
    const result = await httpClient.post(this.apiEndpoints.workOrders.cancelWorkOrder, {
      replace: {
        groupId,
        workOrderId,
      },
      payload: {
        reason,
      },
    });

    return result;
  },
  async sendTextMessage(groupId, workOrderId, textMessage) {
    const result = await httpClient.post(this.apiEndpoints.workOrders.sendTextMessage, {
      replace: {
        groupId,
        workOrderId,
      },
      payload: textMessage,
    });

    return result;
  },
  async searchWorkOrders(groupId, searchFields) {
    const result = await httpClient.post(this.apiEndpoints.workOrders.searchWorkOrders, {
      replace: {
        groupId,
      },
      payload: searchFields,
    });

    return result;
  },
  async removeTag(groupId, workOrderId, tagId) {
    const result = await httpClient.delete(this.apiEndpoints.workOrders.removeTag, {
      replace: {
        groupId,
        workOrderId,
        tagId,
      },
    });

    return result;
  },
  async addTag(groupId, workOrderId, tagId) {
    const result = await httpClient.put(this.apiEndpoints.workOrders.addTag, {
      replace: {
        groupId,
        workOrderId,
        tagId,
      },
    });

    return result;
  },
  async updateTechnician(groupId, workOrderId, technicianId) {
    const result = await httpClient.put(this.apiEndpoints.workOrders.updateTechnician, {
      replace: {
        groupId,
        workOrderId,
        technicianId,
      },
    });

    return result;
  },
  async removeTechnician(groupId, workOrderId) {
    const result = await httpClient.delete(this.apiEndpoints.workOrders.removeTechnician, {
      replace: {
        groupId,
        workOrderId,
      },
    });

    return result;
  },
  async getAvailableAppointments(groupId, workOrderId, startDate, endDate) {
    const result = await httpClient.get(this.apiEndpoints.workOrders.getAvailableAppointments, {
      replace: {
        groupId,
        workOrderId,
        startDate,
        endDate,
      },
    });

    return result;
  },
};
