<template>
  <hello-world />
</template>

<script>
  import HelloWorld from '../../../@components/HelloWorld.vue';

  export default {
    name: 'Home',

    components: {
      HelloWorld,
    },
  };
</script>
