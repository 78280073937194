import baseService from '@/@libs/services/baseService';
import httpClient from '@/@libs/httpClient';

export default {
  ...baseService,
  async getUserConfiguration(groupId, id, userId) {
    const result = await httpClient.get(this.apiEndpoints.configuration.getUserConfiguration, {
      replace: {
        groupId,
        id,
        userId,
      },
    });

    return result;
  },
  async updateUserConfiguration(groupId, id, userId, configuration) {
    const result = await httpClient.put(this.apiEndpoints.configuration.updateUserConfiguration, {
      replace: {
        groupId,
        id,
        userId,
      },
      body: { value: configuration },
    });

    return result;
  },
};
