<template>
  <v-card>
    <v-card-title> Select a group to continue </v-card-title>
    <v-card-text>
      <v-progress-linear v-if="loading" indeterminate />
      <v-simple-table disable-filtering disable-pagination disable-sort fixed-header>
        <template>
          <tbody>
            <tr v-for="group in groups" :key="group.id">
              <td @click="redirect(group)">
                {{ group.groupName }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
  import services from '@/@libs/services';

  export default {
    name: 'Selection',
    data() {
      return {
        groups: [],
        headers: [
          {
            text: 'Group Name',
            value: 'groupName',
          },
        ],
        loading: false,
      };
    },
    methods: {
      async redirect(group) {
        await this.$store.dispatch('selectGroup', group);
        await this.$router.push({ path: `/${group.id}/work-orders` });
      },
      async loadData() {
        this.loading = true;
        const userGroups = await services.groupService.getCurrentUserGroups();
        this.loading = false;

        this.groups = userGroups;
      },
    },
    async created() {
      const { disableRedirect } = this.$route.query;

      await this.loadData();

      if (disableRedirect) return;

      if (this.groups.length === 1) {
        await this.redirect(this.groups[0]);
      }
    },
  };
</script>

<style scoped></style>
