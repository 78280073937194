<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header> Filter Date Range </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row justify="space-around">
                <v-col align="center">
                  <v-card :elevation="0">
                    <v-card-text>
                      <v-date-picker v-model="startDate"></v-date-picker>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col align="center">
                  <v-card :elevation="0">
                    <v-card-text>
                      <v-date-picker v-model="endDate"></v-date-picker>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card :min-width="600" :min-height="600">
          <v-card-title> Work Order Map </v-card-title>
          <v-card-text>
            <div id="googleMap" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { MarkerClusterer } from '@googlemaps/markerclusterer';
  import { Loader } from '@googlemaps/js-api-loader';
  import services from '@/@libs/services';
  import moment from 'moment-timezone';

  export default {
    name: 'MapWorkOrders',
    data() {
      return {
        map: null,
        markers: [],
        markerClusterer: null,
        startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        infoWindow: null,
        apiKey: null,
        workOrders: [],
      };
    },
    computed: {
      groupId() {
        return this.$route.params.groupId;
      },
      momentStartDate() {
        return moment(this.startDate);
      },
      momentEndDate() {
        return moment(this.endDate);
      },
      filteredWorkOrders() {
        const result = this.workOrders.filter(
          (workOrder) => workOrder.appointmentInformation.installDate !== null &&
            workOrder.appointmentInformation.lat !== null &&
            workOrder.appointmentInformation.lng !== null &&
            moment.utc(workOrder.appointmentInformation.installDate) >= this.momentStartDate &&
            moment.utc(workOrder.appointmentInformation.installDate) <= this.momentEndDate,
        );

        return result;
      },
    },
    watch: {
      startDate() {
        if (moment(this.endDate).isBefore(this.momentStartDate)) {
          this.endDate = this.startDate;
        }

        this.loadMarkers();
      },
      endDate() {
        if (moment(this.startDate).isAfter(this.momentEndDate)) {
          this.startDate = this.endDate;
        }

        this.loadMarkers();
      },
    },
    methods: {
      async loadData() {
        const workOrders = await services.workOrderService.listActiveWorkOrders(this.groupId);

        this.workOrders = workOrders;
      },
      loadMarkers() {
        this.markers.forEach((marker) => {
          marker.setMap(null);
        });

        const markers = this.filteredWorkOrders.map((workOrder) => {
          const label = `Work Order Number: ${workOrder.workOrderNumber.toString()}`;
          const position = {
            lat: workOrder.appointmentInformation.lat,
            lng: workOrder.appointmentInformation.lng,
          };

          // eslint-disable-next-line no-undef
          const marker = new google.maps.Marker({
            position,
          });

          marker.addListener('click', () => {
            this.infoWindow.setContent(label);
            this.infoWindow.open(this.map, marker);
          });
          return marker;
        });

        this.markers = markers;

        if (this.markerClusterer) {
          this.markerClusterer.clearMarkers();
        }

        // eslint-disable-next-line
        this.markerClusterer = new MarkerClusterer({ markers, map: this.map });
      },
    },
    async mounted() {
      const apiKey = await services.groupSettingsService.getGoogleApiKey(this.groupId);

      const loader = new Loader({
        apiKey,
        version: 'weekly',
      });

      await loader.load();

      // eslint-disable-next-line no-undef
      this.map = new google.maps.Map(document.getElementById('googleMap'), {
        zoom: 7.75,
        center: { lng: -84.2598926, lat: 28.2731751 },
      });

      // eslint-disable-next-line no-undef
      this.infoWindow = new google.maps.InfoWindow({
        content: '',
        disableAutoPan: true,
      });

      await this.loadData();
      this.loadMarkers();
    },
  };
</script>

<style>
  #googleMap {
    background-color: black;
    height: calc(100vh - 260px) !important;
    width: 100% !important;
  }
</style>
