<template>
  <v-container fluid>
    <div v-if="loading">
      <v-progress-linear intermediate />
    </div>
    <div v-if="weather">
      <v-row no-gutters>
        <v-col :cols="12" :md="6" :lg="6">
          <CurrentWeatherCard :weather="weather" :pop="weatherForecast.daily[0].pop" />
        </v-col>
        <v-col :cols="12" :md="6" :lg="6">
          <WeatherForecastCard :forecast="weatherForecast.daily" />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
  import services from '@/@libs/services';
  import CurrentWeatherCard from '@/App/WorkOrders/EditWorkOrder/@Components/WorkOrderWeather/@components/CurrentWeatherCard';
  import WeatherForecastCard from '@/App/WorkOrders/EditWorkOrder/@Components/WorkOrderWeather/@components/WeatherForecastCard';

  export default {
    name: 'WorkOrderWeather',
    components: { WeatherForecastCard, CurrentWeatherCard },
    data() {
      return {
        loading: false,
        weather: null,
        weatherForecast: null,
      };
    },
    computed: {
      groupId() {
        return this.$route.params.groupId;
      },
      workOrderId() {
        return this.$route.params.workOrderId;
      },
    },
    methods: {
      async loadData() {
        const promises = [
          services.workOrderService.getWorkOrderCurrentWeather(this.groupId, this.workOrderId),
          services.workOrderService.getWorkOrderWeatherForecast(this.groupId, this.workOrderId),
        ];
        this.loading = true;
        const [currentWeather, weatherForecast] = await Promise.all(promises);
        this.loading = false;

        this.weather = currentWeather;
        this.weatherForecast = weatherForecast;
      },
    },
    async created() {
      await this.loadData();
    },
  };
</script>
